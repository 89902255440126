import React, { Component } from 'react'
//import video from './video.mp4';
import watermark from './watermark.png'

const range = Array.from({ length: 1280 }, (_, i) => i)
const video = 'https://www.youtube.com/watch?v=FLESHMJ-bI0'
var canvas

class SampleVideo extends Component {
  constructor() {
    super()
    this.state = {
      select: 0,
      live: false,
      positionX: 0,
      positionY: 0,
      checked: false,
    }
  }

  componentDidMount() {
    const context = this.canvas1.getContext('2d')
    context.drawImage(this.video, 0, 0, 720, 1280)
    context.drawImage(this.watermark, this.state.select, this.state.select)

    if (this.state.live) {
      this.setState({ image: canvas.toDataURL() })
    }
  }

  render() {
    return (
      <html>
        <body>
          {/*<!-- 1. The <iframe> (and video player) will replace this <div> tag. -->*/}
          <div id="player"></div>
        </body>
      </html>
    )
  }
}

export default SampleVideo
