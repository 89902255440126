import {
  Box,
  Flex,
  HStack,
  Image,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  TagLabel,
  Text,
  VStack,
} from '@chakra-ui/react'
import moment from 'moment'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import mailunread from 'src/asset/images/mailunread.svg'
import readNotification from 'src/asset/images/readNotification.svg'
import { isMobile } from 'src/component/CourseDetail/Practice/Util'
import { GetNotifications, UpdateUserNotification } from 'src/redux/actions'
const NotificationTargetGroupList = [
  { key: 'all_Members', value: 'All Members' },
  { key: 'hindi_Students_Only', value: 'Hindi Students Only' },
  { key: 'free_Students_Only', value: 'Free Students Only' },
  { key: 'paid_Students_Only', value: 'Paid Students Only' },
  { key: 'beginners', value: 'Beginners' },
  { key: 'intermediate', value: 'Intermediate' },
  { key: 'advanced', value: 'Advanced' },
  { key: 'fluent', value: 'Fluent' },
]

const Notification = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { notifications, loading, auth } = useSelector(
    store => store.mainReducer
  )
  const onBackClick = () => {
    history.push('/dashboard')
  }
  const tabStyle = {
    border: '1px solid #707070',
    borderRadius: '25px',
    marginRight: isMobile ? 5 : 15,
    width: isMobile ? 115 : 'auto',
    fontSize: isMobile ? 12 : 'inherit',
  }
  const selectedStyle = {
    bg: 'transparent linear-gradient(180deg, #2469F5 0%, #12357B 100%) 0% 0% no-repeat padding-box',
    color: '#fff',
    border: 'none',
  }
  useEffect(() => {
    dispatch(GetNotifications())
  }, [])
  const notificationTagGroupObj = {
    beginners: 'Beginners Level',
    intermediate: 'Intermediate Level',
    advanced: 'Advanced Level',
    fluent: 'Fluent Level',
  }
  const setReadNotification = item => {
    item.userId = auth._id
    item.isReaded = true
    item.notificationId = item.id
    item.status = true
    dispatch(UpdateUserNotification(item))
  }
  const returnNotificationList = filter => {
    return notifications
      .filter(item => item.category === filter)
      .map(item => {
        let tagBGColor =
          item.tag === 'intermediate'
            ? '#FD9900 0% 0% no-repeat padding-box'
            : item.tag === 'advanced'
            ? '#2469F5 0% 0% no-repeat padding-box'
            : item.tag === 'fluent'
            ? '#9A4D03 0% 0% no-repeat padding-box'
            : '#339733 0% 0% no-repeat padding-box'
        return (
          <>
            <HStack
              w={'100%'}
              p="4"
              // bg={!item.isReaded && '#f6f6f6'}
              alignItems={'center'}
              justifyContent={'flex-start'}
            >
              {!item.isReaded && (
                <Image
                  width={'35px'}
                  color="blue"
                  src={mailunread}
                  onClick={() => {
                    setReadNotification(item)
                  }}
                  style={{ cursor: 'pointer', paddingRight: '10px' }}
                />
              )}
              {item.isReaded && (
                <Image
                  width={'35px'}
                  color="blue"
                  src={readNotification}
                  style={{ cursor: 'pointer', paddingRight: '10px' }}
                />
              )}
              {item.tag && (
                <Tag
                  w={'170px'}
                  h={'34px'}
                  bg={tagBGColor}
                  borderRadius="full"
                  textColor={'#fff'}
                  justifyContent={'center'}
                  fontSize={'md'}
                >
                  <TagLabel>{notificationTagGroupObj[item.tag]}</TagLabel>
                </Tag>
              )}
              <Text
                fontSize="13px"
                color="#000"
                opacity={'.5'}
                pl={'2'}
                whiteSpace="nowrap"
              >
                {moment(item.updatedAt).format('DD MMM YYYY')}
              </Text>
            </HStack>
            <VStack alignItems={'flex-start'}>
              <Text
                fontSize="16px"
                fontWeight={'600'}
                pl={'3'}
                whiteSpace="initial"
              >
                {item.title}
              </Text>
              {item.isReaded && (
                <Text fontSize="14px" whiteSpace="initial" pb={'2'} pl={'3'}>
                  {item.message}
                </Text>
              )}
            </VStack>
          </>
        )
      })
  }
  const unReadNotificaion = notifications.filter(item => !item.isReaded).length
  return (
    <>
      <Flex></Flex>
      <Flex w="95%" mx="auto" gap="2" paddingTop={'10px'}>
        <Box>
          <button
            onClick={onBackClick}
            mt={'4'}
            style={{
              fontSize: '15px',
              color: '#646464',
              marginTop: '4px',
            }}
            // disabled={disableLeftArrow}
          >
            &lt; Back to Learn
          </button>
          <Text fontSize="24px" fontWeight={'600'} whiteSpace="nowrap">
            {'Notifications'}
          </Text>
          <Text fontSize="10px" whiteSpace="initial" color="#000" opacity={'.5'}>
            Please click on email icon to view notification, the number of
            unread notifications currently is {unReadNotificaion}
          </Text>
        </Box>
      </Flex>
      <Flex w="95%" mx="auto" gap="2" paddingTop={'10px'}>
        {!loading && !notifications.length && (
          <Text fontSize="24px" fontWeight={'600'} whiteSpace="nowrap">
            There is no new notification on your box
          </Text>
        )}
        {notifications.length > 0 && (
          <Tabs variant="enclosed">
            <TabList borderBottom={'none'}>
              <Tab
                _selected={selectedStyle}
                style={tabStyle}
                _focus={{ border: 'none' }}
              >
                
                {isMobile ? 'Content' : 'Content Update'}
              </Tab>
              <Tab
                style={tabStyle}
                _selected={selectedStyle}
                _focus={{ border: 'none' }}
              >
                {isMobile ? 'Technical' : 'Technical Updates'}
              </Tab>
              <Tab
                _selected={selectedStyle}
                style={tabStyle}
                _focus={{ border: 'none' }}
              >
                Official News
              </Tab>
            </TabList>
            <TabPanels style={{ border: 'none' }}>
              <TabPanel>
                <p>{returnNotificationList('contentUpdate')}</p>
              </TabPanel>
              <TabPanel>
                <p>{returnNotificationList('technicalUpdate')}</p>
              </TabPanel>
              <TabPanel>
                <p>{returnNotificationList('officialNews')}</p>
              </TabPanel>
            </TabPanels>
          </Tabs>
        )}
      </Flex>
    </>
  )
}

export default Notification
