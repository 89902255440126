import API from 'src/util/api'
import useSWR, { useSWRConfig } from 'swr'

import { useUser } from './useUser'

const subscriptionFetcher = customerId => url =>
  API.get(`${url}?customerId=${customerId}`)

export default function useSubscription(courseId) {
  const { user } = useUser()
  const { cache } = useSWRConfig()

  const subscriptionData = cache.get(`get-subscription`)

  const { data, error } = useSWR(
    user?.stripeCustomerId && !subscriptionData ? 'get-subscription' : null,
    subscriptionFetcher(user?.stripeCustomerId)
  )

  if (user && !user.stripeCustomerId) {
    return {
      isLoading: false,
      isSubscriptionActive: user.enrollments?.length,
      isOldSubscription: true,
      error: null,
    }
  }

  async function manageSubscrption() {
    if (!user?.stripeCustomerId) {
      return
    }

    const data = {
      customerId: user.stripeCustomerId,
    }
    const res = await API.post('create-billing-session', data)
    window.location.href = res.url
  }

  const customer = data || subscriptionData

  const subscription = customer?.subscriptions?.data?.[0]

  return {
    isLoading: !customer && !error,
    isSubscriptionActive: subscription?.status === 'active',
    isOldSubscription: false,
    error,
    subscription,
    manageSubscrption,
  }
}
