import React from 'react'
import logo from '../Images/logo2x.png'
import { Link } from 'react-router-dom'

export default class HeaderRegister extends React.Component {
  render() {
    return (
      <div>
        <header className="header-fixed">
          <div className="header-limiter">
            <div className="container">
              <h1>
                <Link to="/">
                  <img className="img-logo" src={logo} alt="logo" />
                </Link>
              </h1>
              {/* <nav>
                  <Link className="login-btn" to="/login">Login</Link>
                  <Link className="register-btn" to="/register">Register</Link>	
                </nav> */}
            </div>
          </div>
        </header>
      </div>
    )
  }
}
