import { Box } from '@chakra-ui/react'

const ReadingWithText = ({ exercise, disabled }) => {
  const { content } = exercise
  const blockName = 'reading-text-container';
  
  const description = { __html: content }
  return <Box border={'2px solid gray'} className={blockName} textAlign="center" dangerouslySetInnerHTML={description} />
}

export default ReadingWithText;
