import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Aboutus from 'src/component/Aboutus'
import ccavenuemobile from 'src/component/ccavenuemobile'
import Contact from 'src/component/Contact'
import Footer from 'src/component/Footer'
import LoginHaveAccount from 'src/component/LoginHaveAccount'
import Mycources from 'src/component/Mycourses'
import PayPalCancel from 'src/component/PayPalCancel'
import paypalmobile from 'src/component/paypalmobile'
import PayPalSuccess from 'src/component/PayPalSuccess'
import Prices from 'src/component/Prices'
import Privacypolicy from 'src/component/Privacypolicy'
import Profile from 'src/component/Profile'
import Profilebookatutor from 'src/component/Profilebookatutor'
import RegisterUserPlan from 'src/component/RegisterUserPlan'
import ResetPassword from 'src/component/ResetPassword'
import SampleVideo from 'src/component/Sample'
import Subscription from 'src/component/Subscription'
import Subscriptions from 'src/component/Subscriptions'
import Success from 'src/component/Success'
import Termsconditions from 'src/component/Termsconditions'
import TestingAjax from 'src/component/TestingAjax'
import YoutubeVideo from 'src/component/YoutubeVideo'

import MainContainer from './component/CourseDetail/MainContainer'
import HomePage from './component/homePage/Homepage'
import LoginSignupTabs from './component/LoginSignupTabs'
import UserValidation from './component/UserValidation'
import Analytics from './Container/Analytics'
import CourseDetails from './Container/CourseDetails'
// import ResetPassword from './pages/ResetPassword'
import MyCourses from './Container/MyCourses'
import Notification from './Container/Notification'
import PaymentFailed from './Container/PaymentFailed'
import PaymentSuccess from './Container/PaymentSuccess'
import Practice from './Container/Practice'
import Pricing from './Container/Pricing'
import UserProfile from './Container/UserProfile'

class App extends React.Component {
  render() {
    return (
      <Switch>
        {/* <Route path="/home" exact={true} component={HomePage}></Route> */}
        <Route path="/" exact={true} component={LoginSignupTabs} />
        <Route path="/login" exact={true} component={LoginSignupTabs} />
        <Route
          path="/login/change-password/:resetTokenPassword"
          exact={true}
          component={ResetPassword}
        />
        <Route
          path="/login/:haveAccount"
          exact={true}
          component={LoginHaveAccount}
        />
        <Route path="/register" exact={true} component={LoginSignupTabs} />
        <Route path="/aboutus" exact={true} component={Aboutus} />
        <Route path="/contact" exact={true} component={Contact} />
        <Route path="/profile" exact={true} component={Profile} />
        <Route path="/mycoursesold" exact={true} component={Mycources} />
        <Route path="/dashboard" exact={true} component={MyCourses} />
        <Route
          path="/user/validate-user"
          exact={true}
          component={UserValidation}
        />
        <Route path="/notification" exact={true} component={Notification} />
        <Route path="/userprofile" exact={true} component={UserProfile} />
        <Route path="/analytics" exact={true} component={Analytics} />
        <Route
          path="/coursedetails/:courseId"
          exact={true}
          component={CourseDetails}
        />
        <Route path="/privacypolicy" exact={true} component={Privacypolicy} />
        <Route path="/footer" exact={true} component={Footer} />
        <Route path="/prices" exact={true} component={Prices} />
        <Route
          path="/termsconditions"
          exact={true}
          component={Termsconditions}
        />
        <Route
          path="/introduction/course/:courseId/chapter/:chapterId/unit/:unitId/lesson/:lessonId"
          exact={true}
          component={MainContainer}
        />
        <Route
          path="/course/:courseId/chapter/:chapterId/unit/:unitId/lesson/:lessonId/practice"
          exact={true}
          component={Practice}
        />
        <Route
          path="/introduction/course/:courseId/chapter/:chapterId/unit/:unitId"
          exact={true}
          component={MainContainer}
        />
        <Route path="/sample-video" exact={true} component={SampleVideo} />
        <Route
          path="/profilebookatutor"
          exact={true}
          component={Profilebookatutor}
        />
        <Route
          path="/reset-password/:encrypteddata"
          exact={true}
          component={ResetPassword}
        />
        {/* <Route path="/subscription/:plan_name" exact={true} component={ Subscription } /> */}
        <Route path="/subscription" exact={true} component={Subscription} />
        <Route path="/subscriptions" exact={true} component={Subscriptions} />
        {<Route path="/youtube/:id" exact={true} component={YoutubeVideo} />}

        <Route
          path="/register/:product_id/:premiumprice_id/:expirytime_id"
          exact={true}
          component={RegisterUserPlan}
        />
        <Route
          path="/redirecturl/:status/:tId/:token"
          exact={true}
          component={Success}
        />
        <Route path="/success_payment" exact={true} component={PayPalSuccess} />
        <Route path="/cancel_payment" exact={true} component={PayPalCancel} />
        <Route
          path="/ccavenuemobile/:transactionid/:token/:user/:product_id/:plan_id/:expired_time"
          exact={true}
          component={ccavenuemobile}
        />
        <Route
          path="/paypalmobile/:transactionid/:token/:user/:product_id/:plan_id/:expired_time"
          exact={true}
          component={paypalmobile}
        />

        <Route path="/ajax" exact component={TestingAjax} />
        <Route path="/pricing" exact component={Pricing} />
        <Route path="/payment-success" exact component={PaymentSuccess} />
        <Route path="/payment-failed" exact component={PaymentFailed} />
      </Switch>
    )
  }
}
export default App
