import React from 'react'

class YoutubeVideo extends React.Component {
  constructor() {
    super()
    this.state = {}
  }
  render() {
    return (
      <div className="video-container1">
        <div
          style={{
            zIndex: 999,
            height: '48%',
            position: 'absolute',
            width: '100%',
            top: '0',
          }}
        ></div>
        <div
          style={{
            zIndex: 999,
            height: '40%',
            position: 'absolute',
            width: '100%',
            bottom: '8%',
          }}
        ></div>
        <iframe
          title="thisIframe"
          id="thisIframe"
          width="560"
          height="315"
          src={`https://www.youtube.com/embed/${this.props.match.params.id}?autoplay=1&rel=0&enablejsapi=1&playsinline=1&showInfo=0&controls=1&fullscreen=1`}
          frameborder={'0'}
          allowfullscreen={'true'}
        ></iframe>
      </div>
    )
  }
}

export default YoutubeVideo
