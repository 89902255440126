import React from 'react'
import Header from './Header'
import Footer from './Footer'
import imageshomepage1 from '../Images/imageshomepage1.png'
import imageshomepage2 from '../Images/imageshomepage2.png'
import imageshomepage3 from '../Images/imageshomepage3.png'
import recommendation from '../Images/recommendation-tiffanyip.JPG'
import '../fonts/fonts/font-awesome-4.7.0/css/font-awesome.min.css'
import ReactPlayer from 'react-player'
import Ccutil from './ccavutil'
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from 'react-html-parser'

import { Link, Redirect } from 'react-router-dom'
import * as myConstClass from './constants'
const api_Url = myConstClass.api
const qs = require('querystring')

//const api_Url = 'http://35.244.3.174:4000';
class Home extends React.Component {
  constructor() {
    super()
    this.state = {
      testimoniallist: [],
      displaytext: 'hide_block',
      parmanenttestimoniallist: '',
      arr: [],
      indexValue: 0,
      formdata: '',
      transactionid: '',
      product_id: '',
      plan_id: '',
      expired_time: '',
      token: '',
    }
  }

  componentWillMount() {
    this.setState({
      transactionid: this.props.match.params.transactionid,
      product_id: this.props.match.params.product_id,
      plan_id: this.props.match.params.plan_id,
      expired_time: this.props.match.params.expired_time,
      token: this.props.match.params.token,
      user: this.props.match.params.user,
    })

    var order_id = Math.floor(Math.random() * 90000) + 100000
    var merchant_id = '179083'
    var currency = 'INR'
    var amount = '1.00'
    var redirect_url = 'https%3A%2F%2Fapp.langistan.com%2Fredirecturl%2F'
    var cancel_url = 'https%3A%2F%2Fapp.langistan.com%2Fcancel%2F'
    var language = 'EN'

    var integration_type = 'iframe_normal'
    var customer_identifier = '123'
    var body =
      'merchant_id=' +
      179083 +
      '&order_id=' +
      order_id +
      '&merchant_param1=' +
      this.props.match.params.token +
      '&merchant_param2=' +
      this.props.match.params.product_id +
      '&merchant_param3=' +
      this.props.match.params.plan_id +
      '&merchant_param4=' +
      this.props.match.params.expired_time +
      '&merchant_param5=' +
      this.props.match.params.transactionid +
      '&currency=' +
      'INR' +
      '&amount=' +
      '1.00' +
      '&redirect_url=' +
      redirect_url +
      '&cancel_url=' +
      cancel_url +
      '&language=EN&billing_name=' +
      'this.state.user.name' +
      '&billing_address=Dummy&billing_city=Dummy&billing_state=Dummy&billing_zip=400054&billing_country=' +
      'this.state.user.country' +
      '&billing_tel=9876543210&billing_email=' +
      'this.state.user.email' +
      '&delivery_name=' +
      'this.state.user.name' +
      '&delivery_address=Vile+Parle&delivery_city=Mumbai&delivery_state=Maharashtra&delivery_zip=400038&delivery_country=' +
      'this.state.user.country' +
      '&delivery_tel=0123456789' +
      '&integration_type=iframe_normal&promo_code=&customer_identifier=2322'
    body =
      'merchant_id=179083&order_id=' +
      order_id +
      '&currency=INR&amount=1.00&redirect_url=https%3A%2F%2Fapp.langistan.com%2Fredirecturl%2F&cancel_url=https%3A%2F%2Fapp.langistan.com%2Fcancel%2F&language=EN&billing_name=Peter&billing_address=Santacruz&billing_city=Mumbai&billing_state=MH&billing_zip=400054&billing_country=India&billing_tel=9876543210&billing_email=testing%40domain.com&delivery_name=Sam&delivery_address=Vile+Parle&delivery_city=Mumbai&delivery_state=Maharashtra&delivery_zip=400038&delivery_country=India&delivery_tel=0123456789&merchant_param1=' +
      this.props.match.params.token +
      '&merchant_param2=' +
      this.props.match.params.product_id +
      '&merchant_param3=' +
      this.props.match.params.plan_id +
      '&merchant_param4=' +
      this.props.match.params.expired_time +
      '&merchant_param5=' +
      this.props.match.params.transactionid +
      '&integration_type=iframe_normal&promo_code=&customer_identifier='

    var workingKey = 'B87653E92020793D098372D7D0D1C987' //Put in the 32-Bit key shared by CCAvenues.
    var accessCode = 'AVPS88GJ14AJ99SPJA' //Put in the access code shared by CCAvenues.
    var encRequest = ''
    var formbody = ''

    encRequest = Ccutil.encryptfinal(body, workingKey)

    var POST = qs.parse(body)
    formbody =
      '<html><head><title>Sub-merchant checkout page</title><script src="http://ajax.googleapis.com/ajax/libs/jquery/1.10.2/jquery.min.js"></script></head><body><center><!-- width required mininmum 482px --><iframe  width="482" height="800" scrolling="No" frameborder="0"  id="paymentFrame" src="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction&merchant_id=' +
      POST.merchant_id +
      '&encRequest=' +
      encRequest +
      '&access_code=' +
      accessCode +
      '"></iframe></center><script type="text/javascript">$(document).ready(function(){$("iframe#paymentFrame").load(function() {window.addEventListener("message", function(e) {$("#paymentFrame").css("height",e.data["newHeight"]+"px"); }, false);}); });</script></body></html>'
    this.setState({ isRedirect: true, formdata: formbody })
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div>
        <div style={{ borderWidth: 1, borderColor: 'red' }}>
          {ReactHtmlParser(this.state.formdata)}
        </div>
      </div>
    )
  }
}

export default Home
