import { combineReducers } from 'redux'
import {
  GET_COURSE_LEFT_MENU,
  HIDE_SPINNER,
  REPORT_ISSUE_FAIL,
  REPORT_ISSUE_SUCCESSFUL,
  SET_COURSE_LEFT_MENU,
  SET_COURSES,
  SET_EXERCISE_DETAILS,
  SET_FEEDBACKS,
  SET_LESSON_EXERCISES,
  SET_LESSON_EXERCISES_PROGRESS,
  SET_LESSON_VIDEO,
  SET_LESSON_VOCABULARIES,
  SET_NOTIFICATIONS,
  SET_SELECTED_LANGUAGE,
  SET_SELECTED_LANGUAGE_ID,
  SET_SELECTED_LESSON_LEFT_MENU,
  SET_UNIT_WISE_PROGRESS_COUNT,
  SET_USER_PROGRESS,
  SHOW_SPINNER,
  SIGN_IN_SUCCESSFUL,
  SIGN_OUT_SUCCESSFUL,
  USER_USER_PASSWORD_SUCCESSFUL,
} from 'src/redux/actionTypes'
import { errorReducer } from 'src/redux/errorReducer'
import { sortChapterAndLessons, sortedByOrder } from 'src/util/helpers'
import LocalStorageService from 'src/util/localStorageService'

const initialState = {
  auth: LocalStorageService.getAuthInfo(),
  courses: [],
  unitWiseProgressCount: [],
  exerciseDetails: [],
  userProgress: [],
  courseLeftMenu: {},
  loadingCourse: false,
  lessonExercisesProgress: null,
  lessonVideo: {},
  lessonExercises: [],
  lessonVocabularies: [],
  notifications: [],
  loading: false,
  selectedLanguage: null,
  selectedLanguageId: null
}

const mainReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_SPINNER:
      return {
        ...state,
        loading: true,
      }
    case HIDE_SPINNER:
      return {
        ...state,
        loading: false,
      }
    case SIGN_IN_SUCCESSFUL:
      return {
        ...state,
        auth: action.payload,
      }
    case SIGN_OUT_SUCCESSFUL:
      return {
        ...state,
        auth: null,
      }
    case SET_COURSES:
      return {
        ...state,
        courses: action.payload,
      }
    case SET_UNIT_WISE_PROGRESS_COUNT:
      return {
        ...state,
        unitWiseProgressCount: sortedByOrder(action.payload),
      }
    case SET_EXERCISE_DETAILS:
      return {
        ...state,
        exerciseDetails: action.payload,
      }
    case SET_USER_PROGRESS:
      return {
        ...state,
        userProgress: action.payload,
      }
    case SET_LESSON_EXERCISES_PROGRESS:
      return {
        ...state,
        lessonExercisesProgress: action.payload,
      }
    case GET_COURSE_LEFT_MENU:
      return {
        ...state,
        loadingCourse: true,
      }
    case SET_COURSE_LEFT_MENU:
      return {
        ...state,
        loadingCourse: false,
        courseLeftMenu: {
          ...action.payload,
          chapters: sortChapterAndLessons(action.payload.chapters),
        },
      }
    case SET_LESSON_EXERCISES:
      return {
        ...state,
        lessonExercises: action.payload,
      }
    case SET_LESSON_VIDEO:
      return {
        ...state,
        lessonVideo: action.payload,
      }
    case SET_LESSON_VOCABULARIES:
      return {
        ...state,
        lessonVocabularies: action.payload,
      }
    case REPORT_ISSUE_SUCCESSFUL:
      return {
        ...state,
        reportIssueSuccess: true,
      }
    case REPORT_ISSUE_FAIL:
      return {
        ...state,
        reportIssueFail: true,
      }
    case SET_SELECTED_LESSON_LEFT_MENU:
      return {
        ...state,
        selectedLesson: action.payload,
      }
    case SET_SELECTED_LANGUAGE:
      return {
        ...state,
        selectedLanguage: action.payload,
      }
    case SET_SELECTED_LANGUAGE_ID:
      return {
        ...state,
        selectedLanguageId: action.payload,
      }
    case SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      }
    case SET_FEEDBACKS:
      return {
        ...state,
        usersFeedbacks: action.payload,
      }
    case USER_USER_PASSWORD_SUCCESSFUL:
      return {
        ...state,
        passwordUpdated: action.payload,
      }
    default:
      return state
  }
}

export default combineReducers({ mainReducer, errorReducer })
