var crypto = require('crypto')
exports.encrypt = function (plainText, workingKey) {
  const ENCRYPTION_KEY = workingKey // Must be 256 bits (32 characters)

  const IV_LENGTH = 16 // For AES, this is always 16

  let iv = crypto.randomBytes(IV_LENGTH)
  let cipher = crypto.createCipheriv(
    'aes-256-cbc',
    Buffer.from(ENCRYPTION_KEY),
    iv
  )
  //  let encrypted = cipher.update(plainText);

  //  encrypted = Buffer.concat([encrypted, cipher.final()]);
  //  return encrypted;
  var encoded = cipher.update(plainText, 'utf8', 'hex')

  // encoded += cipher.final('hex');
  encoded += cipher

  return encoded
}
exports.encryptfinal = function (plainText, workingKey) {
  var m = crypto.createHash('md5')

  m.update(workingKey)

  var key = m.digest().slice(0, 16)

  const IV_LENGTH = 16 // For AES, this is always 16

  //let iv = crypto.randomBytes(IV_LENGTH);

  var iv = '\x00\x01\x02\x03\x04\x05\x06\x07\x08\x09\x0a\x0b\x0c\x0d\x0e\x0f'
  var cipher = crypto.createCipheriv('aes-128-cbc', key, iv)

  var encoded = cipher.update(plainText, 'utf8', 'hex')

  encoded += cipher.final('hex')

  return encoded
}

exports.encryptt = function (plainText, workingKey) {
  var m = crypto.createHash('md5')
  m.update(workingKey)
  var key = m.digest('binary')
  var iv = '\x00\x01\x02\x03\x04\x05\x06\x07\x08\x09\x0a\x0b\x0c\x0d\x0e\x0f'
  var cipher = crypto.createCipheriv('aes-128-cbc', key, iv)
  var encoded = cipher.update(plainText, 'utf8', 'hex')
  encoded += cipher.final('hex')
  return encoded
}

exports.decrypt = function (encText, workingKey) {
  var m = crypto.createHash('md5')
  m.update(workingKey)
  var key = m.digest().slice(0, 16)
  var iv = '\x00\x01\x02\x03\x04\x05\x06\x07\x08\x09\x0a\x0b\x0c\x0d\x0e\x0f'
  var decipher = crypto.createDecipheriv('aes-128-cbc', key, iv)
  var decoded = decipher.update(encText, 'hex', 'utf8')
  decoded += decipher.final('utf8')
  return decoded
}
