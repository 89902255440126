import create from 'zustand'
import API from 'src/util/api'
import Config from 'src/util/config'
import localStorageService from 'src/util/localStorageService'

export const useLoginStore = create((set, get) => ({
  isLoading: false,
  authInfo: localStorageService.getAuthInfo() || undefined,
  error: undefined,
  signIn: async loginInfo => {
    set(() => ({ isLoading: true, error: undefined }))
    try {
      const authInfo = await API.post(Config.API_URI + 'signIn', loginInfo)
      localStorageService.setAuthInfo(authInfo)
      set(() => ({ authInfo }))
      return authInfo
    } catch (error) {
      set(() => ({ error }))
    } finally {
      set(() => ({ isLoading: false }))
    }
  },
  isAdmin: () => {
    return get().authInfo?.role === 'ADMIN'
  },
}))
