import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'

export default function usePracticeUtil() {
  const { selectedLesson, courseLeftMenu } = useSelector(
    state => state.mainReducer
  )
  const history = useHistory()
  const params = useParams()

  function moveToNextLesson() {
    const lessonData = selectedLesson

    if (lessonData?.nextLesson) {
      const nextLesson = lessonData.nextLesson
      const url = `/introduction/course/${params.courseId}/chapter/${params.chapterId}/unit/${params.unitId}/lesson/${nextLesson.id}`
      history.push(url)
    } else {
      // checking for next chapter
      const nextChapterId = courseLeftMenu.chapters.findIndex(
        item => item.chapterId === lessonData.chapterId
      )
      if (nextChapterId !== -1) {
        let nextChapter = courseLeftMenu.chapters[nextChapterId + 1]
        if (nextChapter && nextChapter.lessons) {
          const nextLesson = nextChapter.lessons[0] //moving the first item of next chapter
          const url = `/introduction/course/${params.courseId}/chapter/${params.chapterId}/unit/${params.unitId}/lesson/${nextLesson.id}`
          history.push(url)
        } else {
          history.push('/dashboard')
        }
      } else {
        history.push('/dashboard')
      }
    }
  }

  return {
    moveToNextLesson,
  }
}
