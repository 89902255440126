import '../fonts/fonts/font-awesome-4.7.0/css/font-awesome.min.css'
import '../fonts/work-sans-cufonfonts-webfont/style.css'

import React from 'react'
import { Link } from 'react-router-dom'

import logo from '../Images/logo_black.png'

class Footer extends React.Component {
  render() {
    return (
      <div>
        <footer className="footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-2 m-b-10">
                <img className="img-logo" src={logo} alt="for logo" />
              </div>
              <div className="col-lg-7 col-md-8 m-b-10">
                <ul>
                  <li>
                    <Link to="/aboutus">About</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                  <li>
                    <Link to="/subscription">Prices</Link>
                  </li>
                  {/* <li><Link to="/bookatutor">Book a Tutor</Link></li> */}
                </ul>
              </div>
              <div className="col-lg-3 col-md-4">
                <div className="social">
                  <ul>
                    <li>
                      <a
                        href="https://www.facebook.com/Cocoma Academy/"
                        target="_blank" rel="noreferrer"
                      >
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="">
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/langistan/"
                        target="_blank" rel="noreferrer"
                      >
                        <i className="fa fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href=" https://youtube.com/c/anilmahato"
                        target="_blank"
                      >
                        <i className="fa fa-youtube-play"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="terms">
                  <ul>
                    <li>
                      <p>© 2018 Cocoma Academy. All rights reserved.</p>
                    </li>
                    <li>
                      <Link to="/privacypolicy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/termsconditions">Terms & Conditions</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    )
  }
}

export default Footer
