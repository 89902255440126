import { Box, Button, Flex, Stack, Text, VStack } from '@chakra-ui/react'
import React, { useState } from 'react'
import useSubscription from 'src/hooks/useSubscription'
import { useUser } from 'src/hooks/useUser'
import API from 'src/util/api'
import useSWR from 'swr'

const fetcher = url => API.get(url)

export default function Pricing() {
  const [isCreatingCheckoutSession, setIsCreatingCheckoutSession] =
    useState(false)
  const { data, error } = useSWR('plans', fetcher, {})
  const { user } = useUser()
  const { isSubscriptionActive, isOldSubscription, manageSubscrption } = useSubscription()

  if (error) {
    return <Text>Can not load plans</Text>
  }

  if (!data && !error) return <Text>Loading...</Text>

  function getPlanName(plan) {
    if (plan.recurring) {
      return `${plan.recurring.interval_count} ${plan.recurring.interval}`
    }
    return 'N/A'
  }

  function getFormattedPrice(plan) {
    const currencyFromStripe = plan.currency
    const amountFromStripe = plan.unit_amount / 100

    const userFriendlyAmount = new Intl.NumberFormat(
      navigator.language || 'en-US',
      {
        style: 'currency',
        currency: currencyFromStripe,
      }
    ).format(amountFromStripe)

    return userFriendlyAmount
  }

  async function createSubscrption(priceId) {
    setIsCreatingCheckoutSession(true)
    const data = {
      email: user.email,
      customerId: user.stripeCustomerId,
      priceId,
      origin: window.location.origin,
    }
    const res = await API.post('create-checkout-session', data)
    // Save customer id (res.customerId) in table
    window.location.href = res.url
  }

  if (isSubscriptionActive) {
    return (
      <Stack spacing="4" width="full" alignItems={'center'} mt="20">
        <Text fontWeight={'bold'} fontSize="2xl">
          You have active sbscription
        </Text>
        {!isOldSubscription && (
          <Button variant="primary" width="unset" onClick={manageSubscrption}>
            Manage Subscription
          </Button>
        )}
      </Stack>
    )
  }

  return (
    <Stack justifyContent="center">
      <Flex
        direction={['column', 'column', 'row']}
        backgroundColor="#F4F4FF"
        justifyContent="center"
        paddingY="5"
        gap={2}
        mt="20"
      >
        {data.map(plan => {
          return (
            <Box
              key={plan.id}
              bgColor="white"
              boxShadow="0px 3px 6px #00000029"
              padding="15px"
            >
              <VStack>
                <Text fontSize="28px" fontWeight="semibold">
                  {getPlanName(plan)}
                </Text>
                <Text fontSize="28px" fontWeight="semibold" color="#2469F5">
                  {getFormattedPrice(plan)}
                </Text>
                <Text fontSize="14px" fontWeight="semibold">
                  Full access of complete library
                </Text>
                <Stack
                  fontSize="12px"
                  fontWeight="medium"
                  mt="8px"
                  spacing="4px"
                  width={'full'}
                >
                  <Text>27+ course</Text>
                  <Text>1000+ video lessons</Text>
                  <Text>3,000+ Exercises</Text>
                  <Text>Access to all features</Text>
                  <Text>Weekly new content updates</Text>
                </Stack>
                <Button
                  variant="outline"
                  mx="auto"
                  onClick={() => createSubscrption(plan.id)}
                  isLoading={isCreatingCheckoutSession}
                >
                  Get Started
                </Button>
              </VStack>
            </Box>
          )
        })}
      </Flex>
    </Stack>
  )
}
