import React from 'react'

import HeaderRegister from './HeaderRegister'
import Footer from './Footer'
import banner from '../Images/singin-bg.png'
import upload_icon from '../Images/upload_icon.png'
import { Link } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import swal from 'sweetalert'
import { Redirect } from 'react-router-dom'
import * as $ from 'jquery'

import logo from '../Images/logo2x.png'
import { Button, Modal } from 'react-bootstrap'
import * as myConstClass from './constants'
import notificationicon from '../Images/notification.png'

const api_Url = myConstClass.api
const baseUrl = 'http://35.244.3.174:4000'

class PayPalCancel extends React.Component {
  constructor() {
    super()
    this.state = {
      notificationList: [],
      notificationCount: '',
      showNotificatonbox: '',
      isLoading: true,
      productName: '',
      levelName: '',
      notificatinType: '',
      type: '',
      language_id: '',
      chapter_id: '',
      level_id: '',
      lesson_id: '',
      AlllanguageList: [],
      languageList: [],
      lessonType: '',
      credit: 0,
      levelList: [],
    }
  }

  componentWillMount() {
    //this.dataSource();
    let token = window.sessionStorage.getItem('token')
    if (token) {
      this.getNotificationCount()
      setTimeout(this.getproductwithpremiumofuser, 1200)
      setTimeout(this.getLanguageList, 2000)
      setTimeout(this.getLevelList, 2000)
      // return <Redirect to="/dashboard" />
      // return <Link to="/dashboard" />
      // window.history.push('/dashboard');
      // window.history.forward();

      // window.history.go(1);
      // this.props.context.history.push('/dashboard');
    } else {
      this.props.history.push('/')
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside)
    //this.getNotification()

    $(document).ready(function () {
      var _ = this

      var opcionesnav = $('.navoption').length
      var clickhamb = 0

      $('#hamburger').click(function () {
        clickhamb = 1
        var header = $('#myTopnav')
        if (header[0].classList.length === 1) {
          header.addClass('responsive')
          $('header').height((opcionesnav + 1) * 70)
          $('.navlist a:not(.icon)').css('display', 'block')
          setTimeout(function () {
            $('.navlist a:not(.icon)').css('transform', 'translateX(0px)')
          }, 50)
        } else {
          $('.navlist a:not(.icon)').css('transform', 'translateX(600px)')
          header.height(70)
          setTimeout(function () {
            header.removeClass('responsive')
            header.height(70)
            $('.navlist a:not(.icon)').css('display', 'none')
          }, 50)
        }
      })

      $(window).on('resize', function () {
        if ($(window).width() > 600 && clickhamb == 1) {
          $('#myTopnav').height(70)
          $('.navlist a:not(.icon)').css('display', 'block')
          setTimeout(function () {
            $('.navlist a:not(.icon)').css('transform', 'translateX(0px)')
          }, 50)
        }
      })

      // $("#cursor0").click( e =>

      // this.setState({ notificationCount: 0 })
      // );

      // $('#cursor0').click(e =>{
      //
      // ChangeTime();
      // // this.setState({notificationCount:0})

      // // TOGGLE (SHOW OR HIDE) NOTIFICATION WINDOW.
      // $('#notifications').fadeToggle('fast', 'linear', function () {

      // if ($('#notifications').is(':hidden')) {
      //

      // $('#noti_Button').css('background-color', '#2E467C');
      // }
      // // CHANGE BACKGROUND COLOR OF THE BUTTON.

      // else $('#noti_Button').css('background-color', '#FFF');
      // });

      // $('#noti_Counter').fadeOut('slow'); // HIDE THE COUNTER.

      // return false;
      // });
      // $(document).click(function () {
      //

      // $('#notifications').hide();

      // // CHECK IF NOTIFICATION COUNTER IS HIDDEN.
      // if ($('#noti_Counter').is(':hidden')) {
      // // CHANGE BACKGROUND COLOR OF THE BUTTON.
      // $('#noti_Button').css('background-color', '#2E467C');
      // }
      // });
    })

    // this.ChangeTime()
  }

  Logout = () => {
    sessionStorage.clear()
    // localStorage.clear();
    // Cookies.remove('email');
    // Cookies.remove('password');
    let isLogout = true
  }

  // GETTING PRODUCT LIST FOR NOTIFICATION

  getproductwithpremiumofuser = e => {
    fetch(api_Url + '/product/getproductwithpremiumofuser', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + window.sessionStorage.getItem('token'),
      },
    })
      .then(response => {
        return response.json()
      })
      .then(response => {
        var mainlang = []
        var pdata = response.product
        pdata.map(productData => {
          if (productData) {
            if (productData.result) {
              productData.result.map(resultData => {
                if (resultData) {
                  if (
                    resultData.user_id == window.sessionStorage.getItem('id')
                  ) {
                    if (mainlang.indexOf(productData) > -1) {
                    } else {
                      mainlang.push(productData)
                    }
                  }
                }
              })
            }
          }
        })
        if (mainlang.length > 0) {
          this.setState({
            languageList: mainlang,
          })
        } else {
        }
      })
  }

  // GET  ALL LANGUAGE FOR THE NOTIFICATION REDIRECTION
  getLanguageList = () => {
    //
    fetch(api_Url + `/product/getproductlist`)
      .then(response => response.json())

      .then(data => {
        //
        this.setState({
          AlllanguageList: data.product,
        })
        //(data,"All lang")
      })
  }

  // get Level List
  getLevelList = e => {
    // ;
    fetch(api_Url + '/language/listlevel')
      .then(response => response.json())
      .then(data => {
        //
        if (data.status == 200) {
          this.setState({
            levelList: data.level,
          })
        } else {
          this.setState({ levelList: [] })
        }

        //(data, "level")
      })
  }

  // GET NOTIFICAION COUNT
  getNotificationCount() {
    fetch(api_Url + '/get-notifications-count', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + window.sessionStorage.getItem('token'),
      },
    })
      .then(response => response.json())

      .then(response => {
        if (response.status === 200) {
          //(response)
          this.setState(
            {
              notificationCount: response.notification,
              credit: response.credit,
              call: true,
            },
            () => {}
          )
        } else {
        }
      })
  }
  // GET NOTIFICAION DATA
  getNotification = e => {
    fetch(api_Url + '/get-notifications-data', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + window.sessionStorage.getItem('token'),
      },
    })
      .then(response => response.json())

      .then(response => {
        //(response, 'notficia')

        if (response.status === 200) {
          this.setState(
            {
              notificationList: response.notification,
              showNotificatonbox: !this.state.showNotificatonbox,
              called: 'yes',
            },
            () => {
              this.componentDidMount()
            }
          )
          this.ChangeTime()
        }
      })
  }
  ChangeTime() {
    fetch(api_Url + '/change-time', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + window.sessionStorage.getItem('token'),
      },
    })
      .then(response => response.json())

      .then(response => {
        //(response)
        this.setState({ notificationCount: response.notification })
        this.setState({ notificationCount: '0' })
      })
  }

  // RETURN LANGUAGE ID
  getPremiunPriceidForNotification1 = languageId => {
    var premiumpriceId = []

    var promise = new Promise((resolve, reject) => {
      this.state.languageList.map(resultData => {
        if (resultData) {
          var product = resultData.product

          if (resultData.result[0]) {
            var rr = resultData.result[0]
            if (rr.product_id === languageId) {
              premiumpriceId = rr.premiumprice_id
            }
          }
        }

        resolve(premiumpriceId)
      })
    })
    return promise
  }

  // MANAGE USER NOTIFICAION
  redirectToPage = (e, data) => {
    const type = 'notification'
    if (
      data.notificationtype === 'language' ||
      data.notificationtype === 'package'
    ) {
      var notificatinType = data.notificationtype
      var languageId = data.language_id
      const product = this.state.AlllanguageList.find(l => l._id === languageId)
      const productName = product.product
      this.setState({
        language_id: languageId,
        productName: productName,
        notificatinType: notificatinType,
      })
    } else {
      const levelId = data.level_id
      const lessonType = data.lessontype
      const level = this.state.levelList.find(l => l._id === levelId)
      const levelName = level.level
      const chapterId = data.chapter_id
      const lessonId = data.lesson_id
      var notificatinType = data.notificationtype
      var languageId = data.language_id
      const product = this.state.languageList.find(l => l._id === languageId)
      const productName = product.product
      this.setState({
        language_id: languageId,
        lessonType: lessonType,
        productName: productName,
        type: type,
        level_id: levelId,
        levelName: levelName,
        chapter_id: chapterId,
        lesson_id: lessonId,
        notificatinType: notificatinType,
      })
    }
    this.getPremiunPriceidForNotification1(languageId).then(value => {
      var premiumpriceId = value
      if (this.state.notificatinType === 'chapter') {
        this.setState({ premiumpriceId: premiumpriceId })
        // return <Redirect to={`/introduction/${this.state.level_id}/${this.state.levelName}/${premiumpriceId}/${this.state.language_id}/${this.state.productName}/${this.state.chapter_id}/${this.state.type}`}/>
      }
    })
  }

  render() {
    if (
      this.state.lessonType === 'otherlevel' ||
      this.state.notificatinType == 'hourchapter'
    ) {
      if (this.state.notificatinType == 'hourchapter') {
        return (
          <Redirect
            to={`/introduction/${this.state.level_id}/${this.state.levelName}/${this.state.premiumprice_id}/${this.state.language_id}/${this.state.productName}/${this.state.chapter_id}/${this.state.type}`}
          />
        )
      } else if (this.state.notificatinType == 'lesson') {
        return (
          <Redirect
            to={`/introduction/${this.state.level_id}/${this.state.levelName}/${this.state.premiumprice_id}/${this.state.language_id}/${this.state.productName}/${this.state.chapter_id}/${this.state.lesson_id}/${this.state.type}`}
          />
        )
      } else if (this.state.notificatinType == 'language') {
        return <Redirect to={`/user-subscription-details`} />
      }
    } else {
      if (this.state.notificatinType == 'entertainmentchapter') {
        return (
          <Redirect
            to={`/entertainment/${this.state.level_id}/${this.state.levelName}/${this.state.premiumprice_id}/${this.state.language_id}/${this.state.productName}/${this.state.chapter_id}/${this.state.type}`}
          />
        )
      } else if (this.state.notificatinType == 'lesson') {
        return (
          <Redirect
            to={`/entertainment/${this.state.level_id}/${this.state.levelName}/${this.state.premiumprice_id}/${this.state.language_id}/${this.state.productName}/${this.state.chapter_id}/${this.state.lesson_id}/${this.state.type}`}
          />
        )
      } else if (this.state.notificatinType == 'language') {
        return <Redirect to={`/subscriptions`} />
      } else if (this.state.notificatinType == 'level') {
        return <Redirect to={`/dashboard`} />
      } else if (this.state.notificatinType == 'package') {
        return <Redirect to={`/user-subscription-details`} />
      }
    }
    // if(this.state.isRedirect){
    //     return <Redirect  to="/login" />
    //   }
    return (
      <div>
        <header className="topnav" id="myTopnav">
          <Link to="/">
            <img className="logo" src={logo} />
          </Link>

          <div className="navlist" id="navlist">
            <Link className="cursor0">
              <i class="fa fa-bookmark"></i>
              <span className="button__badge">{this.state.credit}</span>
            </Link>
            <Link
              onClick={this.getNotification}
              className="cursor0"
              to="/cancel_payment"
            >
              <i className="fa fa-bell-o"></i>
              <span className="button__badge">
                {this.state.notificationCount}
              </span>
              {this.state.showNotificatonbox && (
                <div id="notifications">
                  <h3>Notifications</h3>
                  <div
                    className={
                      this.state.notificationList.length > 0
                        ? ' notification-data'
                        : ''
                    }
                  >
                    {this.state.notificationList.length > 0 ? (
                      this.state.notificationList.map(notification => (
                        <div
                          onClick={e => this.redirectToPage(e, notification)}
                          className="main-nf-data"
                        >
                          <img
                            src={
                              api_Url +
                              '/UserProfile/' +
                              notification.user_id.profile_pic
                            }
                          />
                          {notification.role === 'Admin' ? (
                            <p>
                              {''}
                              {notification.notificationdata}
                            </p>
                          ) : (
                            <p>
                              {' '}
                              {notification.user_id.name} {''}
                              {notification.notificationdata}
                            </p>
                          )}
                          <span></span>
                        </div>
                      ))
                    ) : (
                      <div className="notification-none-data">
                        <img src={notificationicon} />
                        <p style={{ color: '#444444' }}>
                          You don't have notification
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Link>

            <Link className="navoption" to="/dashboard">
              My Courses<hr className="hr"></hr>
            </Link>
            <Link className="navoption" to="/profile">
              Profile{' '}
            </Link>
            <Link className="navoption" to="/profilebookatutor">
              Book a Tutor
            </Link>
            <Link className="navoption" to="/user-subscription-details">
              Subscription
            </Link>
            <Link className="navoption" to="/login" onClick={this.Logout}>
              Sign Out
            </Link>
            <a href="javascript:void(0);" className="icon" id="hamburger">
              <span></span>
              <span></span>
              <span></span>
            </a>
          </div>
        </header>
        <section className="profile-section success_bg m-t-50 p-t-100 p-b-200">
          <div className="success_register">
            <h2>Your transaction has been cancelled !</h2>
            <Link to="/user-subscription-details">
              <button className="cancel-trns">Go to subscription page</button>
            </Link>
          </div>
        </section>
      </div>
    )
  }
}

export default PayPalCancel
