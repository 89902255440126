import React from 'react'
import logo from '../Images/logo2x.png'
import { Link } from 'react-router-dom'
import * as myConstClass from './constants'
const api_Url = myConstClass.api

export default class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentWillMount() {
    // this.getNotificationCount();
  }

  getNotificationCount() {
    fetch(api_Url + '/get-notifications-count', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + window.sessionStorage.getItem('token'),
      },
    })
      .then(response => response.json())

      .then(response => {})
  }

  render() {
    return (
      <div>
        <header className="header-fixed">
          <div className="header-limiter">
            <div className="container">
              <h1>
                <Link to="/">
                  <img className="img-logo" src={logo} alt="logo" />
                </Link>
              </h1>
              <nav>
                <Link className="login-btn" to={`/login`}>
                  Login
                </Link>
                <Link className="register-btn" to="/register">
                  Register
                </Link>
              </nav>
            </div>
          </div>
        </header>
      </div>
    )
  }
}
