import 'react-datepicker/dist/react-datepicker.css'
import '../fonts/fonts/font-awesome-4.7.0/css/font-awesome.min.css'
import '../fonts/work-sans-cufonfonts-webfont/style.css'

import * as $ from 'jquery'
import moment from 'moment'
import React from 'react'
import Avatar from 'react-avatar-edit'
import { Modal } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { Link, Redirect } from 'react-router-dom'
import swal from 'sweetalert'

// import { traverseFast } from '@babel/types'; get-notifications-data
import coin__icon from '../Images/coin-icon.png'
import logo from '../Images/logo2x.png'
import notificationicon from '../Images/notification.png'
import upload_icon from '../Images/upload_icon.png'
import user from '../Images/user.png'
import * as myConstClass from './constants'

const api_Url = myConstClass.api

//const api_Url = 'api_Url'; {notification.user_id?<img src={api_Url + "/UserProfile/" + notification.user_id.profile_pic} />:<img src={user} />}

class Profile extends React.Component {
  constructor() {
    super()
    this.state = {
      selected: 'profile',
      staticImg: true,
      file: null,
      country: '',
      date: '',
      name: '',
      surname: '',
      email: '',
      dob: '',
      profile_pic: '',
      profileImg: '',
      country_id: '',
      register_date: '',
      type: '',
      displaytext: 'hide_block',
      nameErrorMessage: '',
      surnameErrorMessage: '',
      emailErrorMessage: '',
      passwordErrorMessage: '',
      birthdayErrorMessage: '',
      countryErrorMessage: '',
      c_passwordErrorMessage: '',
      old_passwordErrorMessage: '',
      imgErrorMessage: '',
      isProfileUpdated: false,
      old_password: '',
      new_password: '',
      c_password: '',
      notificationCheckboxteaching1: true,
      notificationCheckboxteaching2: false,
      notificationCheckboxcomment1: true,
      notificationCheckboxcomment2: false,
      commentnotificationstatus: false,
      teachernotificationstatus: false,
      notificationmessage: '',
      userSubscriptionDetails: [],
      showUpgradesection: false,
      showImageCorp: false,
      preview: '',
      dynamicImg: false,
      previousPreview: '',
      notificationCount: '',
      notificationList: [],
      showNotificatonbox: false,
      dob1: '',
      dateChange: false,
      certificateList: [],
      isLoading: true,
      productName: '',
      levelName: '',
      notificatinType: '',
      type: '',
      language_id: '',
      chapter_id: '',
      level_id: '',
      lesson_id: '',
      AlllanguageList: [],
      languageList: [],
      lessonType: '',
      levelList: [],
      credit: 0,
      countryErrorClass: 'm-t-5 select_box',
    }
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  datehandleChange = date => {
    this.setState(
      {
        date: date,
        dateChange: true,
        dob: moment(date).format('YYYY-MM-DD'),
      },
      () => {}
    )
  }

  componentWillMount() {
    window.scrollTo(0, 0)
    var token = window.sessionStorage.getItem('token')
    if (token == null) {
      return this.props.history.push('/')
    }
    this.getuserSubscriptionsList()
    this.getNotificaonsetting()
    this.getNotificationCount()
    setTimeout(this.getCertificateList, 1500)
    setTimeout(this.getproductwithpremiumofuser, 1200)
    setTimeout(this.getLanguageList, 2000)
    setTimeout(this.getLevelList, 2000)

    fetch(api_Url + '/selectuserdata', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + window.sessionStorage.getItem('token'),
      },
    })
      .then(response => {
        return response.json()
      })
      .then(response => {
        if (response.status == 200) {
          this.setState({
            name:
              response.user.name === null || response.user.name === undefined
                ? ''
                : response.user.name,
            surname:
              response.user.surname == null ||
              response.user.surname == undefined
                ? ''
                : response.user.surname,
            email: response.user.email,
            dob:
              response.user.dob == null
                ? ''
                : moment(response.user.dob).format('DD-MM-YYYY'),
            dob1:
              response.user.dob == null
                ? ''
                : moment(response.user.dob).format('YYYY-MM-DD'),
            profile_pic: response.user.profile_pic,
            profileImg: response.user.profile_pic,
            country_id:
              response.user.country_id == null
                ? ''
                : response.user.country_id._id,
            register_date: moment(response.user.created_at).format(
              'MMMM DD, YYYY'
            ),
          })
        } else {
          this.setState({
            name: '',
            surname: '',
            email: '',
            dob: '',
            dob1: '',
            profile_pic: '',
            profileImg: '',
            country_id: '',
            register_date: '',
          })
        }

        //(response, "response")
        this.setState({ isLoading: false })
      })

    fetch(api_Url + '/country/list')
      .then(response => response.json())
      .then(data => {
        this.setState({
          country: data.country,
        })
      })
  }

  // get user certificate list
  getCertificateList = e => {
    this.setState({ isLoading: true })

    fetch(api_Url + '/get-certificate-list', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + window.sessionStorage.getItem('token'),
      },
    })
      .then(data => data.json())
      .then(data => {
        //(data,'Certificate')
        if (data.status === 200) {
          if (data.certificatelist.length > 0) {
            this.setState({
              certificateList: data.certificatelist,
            })
          } else {
            this.setState({ certificateList: [] })
          }
          this.setState({ isLoading: false })
        }
      })
  }
  getNotificationCount() {
    fetch(api_Url + '/get-notifications-count', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + window.sessionStorage.getItem('token'),
      },
    })
      .then(response => response.json())

      .then(response => {
        if (response.status === 200) {
          //(response)
          this.setState(
            {
              notificationCount: response.notification,
              credit: response.credit,
              call: true,
            },
            () => {}
          )
        } else {
        }
      })
  }

  getNotification = e => {
    this.setState({ isloading1: true })
    this.setState({ showNotificatonbox: !this.state.showNotificatonbox })

    fetch(api_Url + '/get-notifications-data', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + window.sessionStorage.getItem('token'),
      },
    })
      .then(response => response.json())

      .then(response => {
        //(response, 'notficia')

        if (response.status === 200) {
          this.setState(
            {
              isloading1: false,
              notificationList: response.notification,
              //showNotificatonbox: !this.state.showNotificatonbox,
              //called: "yes",
            },
            () => {
              this.componentDidMount()
            }
          )
          this.ChangeTime()
        }
      })
  }
  ChangeTime() {
    fetch(api_Url + '/change-time', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + window.sessionStorage.getItem('token'),
      },
    })
      .then(response => response.json())

      .then(response => {
        //(response)
        this.setState({ notificationCount: response.notification })
        this.setState({ notificationCount: '0' })
      })
  }

  // getUser subscriptionList
  getuserSubscriptionsList() {
    fetch(api_Url + '/getusersubscripedlist', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + window.sessionStorage.getItem('token'),
      },
    })
      .then(response => {
        return response.json()
      })
      .then(response => {
        if (response.usersubscribtion.length > 0) {
          this.setState({
            userSubscriptionDetails: response.usersubscribtion,
            showUpgradesection: response.usersubscribtion.status,
          })
          //(response)
        } else {
          this.setState({
            userSubscriptionDetails: [],
            showUpgradesection: false,
          })
        }
        //(response, "subscription")
      })
  }

  // get user notification setting
  getNotificaonsetting() {
    fetch(api_Url + '/getnotificationdata', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + window.sessionStorage.getItem('token'),
      },
    })
      .then(response => {
        return response.json()
      })
      .then(response => {
        if (response.user.teachernotificationstatus) {
          if (response.user.teachernotificationstatus === true) {
            this.setState({
              teachernotificationstatus:
                response.user.teachernotificationstatus,
              notificationCheckboxteaching1: false,
              notificationCheckboxteaching2: true,
            })
          } else if (response.user.teachernotificationstatus === false) {
            this.setState({
              teachernotificationstatus:
                response.user.teachernotificationstatus,
              notificationCheckboxteaching1: true,
              notificationCheckboxteaching2: false,
            })
          }
        }
        if (response.user.commentnotificationstatus) {
          if (response.user.commentnotificationstatus === true) {
            this.setState({
              commentnotificationstatus:
                response.user.commentnotificationstatus,
              notificationCheckboxcomment1: false,
              notificationCheckboxcomment2: true,
            })
          } else if (response.user.commentnotificationstatus === false) {
            this.setState({
              teachernotificationstatus:
                response.user.teachernotificationstatus,
              notificationCheckboxcomment1: true,
              notificationCheckboxcomment2: false,
            })
          }
        } else if (response.status === 422) {
          this.setState({})
        }

        //(response)
        this.setState({})
        //(response, "response")
      })
  }

  componentDidMount() {
    //$(".navlist a:not(.icon)").css("display", "none");
    window.scrollTo(0, 0)
    // document.addEventListener('click', this.handleClickOutside);
    $(document).ready(function () {
      var opcionesnav = $('.navoption').length
      var clickhamb = 0

      $('#hamburger').click(function () {
        clickhamb = 1
        var header = $('#myTopnav')
        if (header[0].classList.length === 1) {
          header.addClass('responsive')
          $('header').height((opcionesnav + 1) * 70)
          $('.navlist a:not(.icon)').css('display', 'block')
          setTimeout(function () {
            $('.navlist a:not(.icon)').css('transform', 'translateX(0px)')
          }, 50)
        } else {
          $('.navlist a:not(.icon)').css('transform', 'translateX(600px)')
          header.height(70)
          setTimeout(function () {
            header.removeClass('responsive')
            header.height(70)
            $('.navlist a:not(.icon)').css('display', 'none')
          }, 50)
        }
      })

      $(window).on('resize', function () {
        // //(clickhamb);
        if ($(window).width() > 600 && clickhamb === 1) {
          // //(clickhamb + "     " + $(window).width());
          $('#myTopnav').height(70)
          $('.navlist a:not(.icon)').css('display', 'block')
          setTimeout(function () {
            $('.navlist a:not(.icon)').css('transform', 'translateX(0px)')
          }, 50)
        }
      })
    })
  }

  handleClickOutside(event) {
    //

    $(document).click(e => {
      //()
      //
      $('#notifications').hide()
    })
    // //(this.state.showNotificatonbox)
    //  this.setState({showNotificatonbox:false})

    // if(this.state.called ==='yes' && this.state.showNotificatonbox){
    //     this.setState({showNotificatonbox:false})

    // }
  }
  onCrop = preview => {
    //  //(preview)
    this.setState({
      preview,
      // staticImg: false,
      //dynamicImg: true,
      // showImageCorp:false
    })
  }
  onClose = e => {
    this.setState({ preview: '' })
  }

  imageChange = e => {
    this.setState({
      showImageCorp: true,
      type: '',
      profile_pic: '',
      imgErrorMessage: '',
      preview: '',
    })
  }

  CloseImageCrop = e => {
    this.setState({ showImageCorp: false })
    if (this.state.preview === '') {
      this.setState({
        profile_pic: this.state.profileImg,
        isProfileUpdated: false,
      })
      if (this.state.dynamicImg === true) {
        this.setState({ file: this.state.previousPreview })
      }
    } else {
      this.setState({
        profile_pic: this.state.profileImg,
        isProfileUpdated: false,
      })
    }
  }
  saveImage = e => {
    if (this.state.preview === '' && this.state.type === '') {
      this.setState({
        staticImg: true,
        dynamicImg: false,
        showImageCorp: false,
        profile_pic: this.state.profileImg,

        isProfileUpdated: false,
      })
      alert('No Changes Found')
      if (this.state.dynamicImg === true) {
        this.setState({
          file: this.state.previousPreview,
          dynamicImg: true,
          staticImg: false,
          isProfileUpdated: true,
          type: this.state.imageType,
          profile_pic: this.state.file,
        })
      }
    } else {
      this.setState({
        file: this.state.preview,
        profile_pic: this.state.preview,
        type: this.state.imageType,
        previousPreview: this.state.preview,
        displaytext: 'hide_block',
        imgErrorMessage: '',
        staticImg: false,
        dynamicImg: true,
        showImageCorp: false,
        isProfileUpdated: true,
      })
    }
  }

  onBeforeFileLoad = elem => {
    if (elem.target.files.length > 0 && elem.target.files[0]) {
      var validExtensions = ['jpg', 'png', 'PNG', 'JPG', 'jpeg']
      var fileName = elem.target.files[0].name
      var fileNameExt = fileName.substr(fileName.lastIndexOf('.') + 1)
      if ($.inArray(fileNameExt, validExtensions) == -1) {
        elem.target.value = ''

        this.setState({
          // staticImg: true,
          //  dynamicImg:false,
          isValidextension: false,
          displaytext: 'show_block',
          imgErrorMessage: 'Please select Image in ' + 'PNG,' + '  ' + 'JPG',
        })
      } else {
        var fileName = elem.target.files[0].name
        var fileNameExt = fileName.substr(fileName.lastIndexOf('.') + 1)
        var base64 = new FileReader()
        // base64.readAsDataURL(e.target.files[0]);
        this.setState({
          //profile_pic: base64.result,
          imageType: fileNameExt,
        })
        //         base64.onload = () => {

        //   }

        // this.ChangeImage(e)
      }
    }
  }

  // Manage user notification setting
  checkbox1 = data => {
    if (data == 'teaching') {
      this.setState({
        notificationCheckboxteaching1: false,
        notificationCheckboxteaching2: true,
        teachernotificationstatus: true,
      })
    } else {
      this.setState({
        notificationCheckboxcomment1: false,
        notificationCheckboxcomment2: true,
        commentnotificationstatus: true,
      })
    }
  }

  checkbox2 = data => {
    if (data == 'teaching') {
      this.setState({
        notificationCheckboxteaching1: true,
        notificationCheckboxteaching2: false,
        teachernotificationstatus: false,
      })
    } else {
      this.setState({
        notificationCheckboxcomment1: true,
        notificationCheckboxcomment2: false,
        commentnotificationstatus: false,
      })
    }
  }
  // Manage user notification setting end//

  //Send user notificaiton setting
  submitUserNotificationsetting = e => {
    e.preventDefault()

    var data = {
      teachernotificationstatus:
        this.state.teachernotificationstatus.toString(),
      commentnotificationstatus:
        this.state.commentnotificationstatus.toString(),
    }
    fetch(api_Url + '/updatenotificationdata', {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + window.sessionStorage.getItem('token'),
      },
    })
      .then(response => {
        return response.json()
      })
      .then(response => {
        if (response.status === 200) {
          this.setState({
            notificationmessage: 'Notifications setting successfully saved',
            displaytext: 'show_block',
          })

          setTimeout(
            function () {
              this.setState({
                notificationmessage: '',
                displaytext: 'hide_block',
              })
            }.bind(this),
            3000
          )
        } else if (response.status === 422) {
          this.setState({
            notificationmessage: response.error,
            displaytext: 'show_block',
          })
        }
      })

    //(data)
  }
  hideMessage() {
    this.setState({ notificationmessage: '', displaytext: 'hide_block' })
  }

  imageChanddge = e => {
    e.preventDefault()
    if (e.target.files.length > 0 && e.target.files[0]) {
      var validExtensions = ['jpg', 'png', 'PNG', 'JPG', 'jpeg']
      var fileName = e.target.files[0].name

      var fileNameExt = fileName.substr(fileName.lastIndexOf('.') + 1)
      if ($.inArray(fileNameExt, validExtensions) == -1) {
        this.setState({
          staticImg: true,
          dynamicImg: false,
          isValidextension: false,
          displaytext: 'show_block',

          imgErrorMessage: 'Please select Image in ' + 'PNG,' + '  ' + 'JPG',
        })
        //(this.state.profile_pic)
      } else {
        let finame = e.target.files[0].name
        var file_name_array = finame.split('.')
        var file_extension = file_name_array[file_name_array.length - 1]

        let file = e.target.files[0]
        let base_64 = new FileReader()
        base_64.readAsDataURL(file)
        base_64.onloadend = () => {
          this.setState(
            {
              profile_pic: base_64.result,
              type: file_extension,

              isProfileUpdated: true,
            },
            () => {
              //  //(this.state.profile_pic)
            }
          )
        }

        this.setState({
          file: URL.createObjectURL(e.target.files[0]),
          staticImg: false,
          dynamicImg: true,
          imgErrorMessage: '',
        })
      }
    }

    //     //(base_64.result)
    //   //(file_extension)
    //      fetch(api_Url+'/uploadprofilepicture',{
    //        method: 'PUT',
    //       headers: {
    //           'Accept': 'application/json',
    //             'Content-Type': 'application/json',
    //             'Authorization': 'Bearer ' + this.state.token,
    //     },
    //       body: JSON.stringify({
    //            profile_pic: base_64.result,
    //         fileextension: file_extension
    //      })
    //   }).then((response) => {
    //       return response.json();
    //      }).then((response) => {
    //            //(response)
    //          })
  }

  // datehandleChange = (date) => {
  //
  //     this.setState({
  //         date: date,
  //         dob: moment(date).format("YYYY-MM-DD")
  //     })
  // }

  handleChange = e => {
    if (e.target.value == '0') {
    } else {
      const name = e.target.name
      this.setState({
        [e.target.name]: e.target.value,
      })
      if (name === 'name') {
        this.setState({ nameErrorMessage: '' })
      } else if (name === 'surname') {
        this.setState({ surnameErrorMessage: '' })
      } else if (name === 'email') {
        this.setState({ emailErrorMessage: '' })
      } else if (name === 'dob') {
        this.setState({ birthdayErrorMessage: '' })
      } else if (name === 'country_id') {
        this.setState({
          countryErrorMessage: '',
          countryErrorClass: 'm-t-5 select_box',
        })
      }
    }
  }
  // handle curren password change
  handleCurrentPasswordChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
      old_passwordErrorMessage: '',
      displaytext: 'hide_block',
    })
  }

  activateTab = e => {
    this.setState({
      selected: e,
      profileActive: true,
      c_passwordErrorMessage: '',
      old_password: '',
      new_password: '',
      c_password: '',
    })
  }

  onLogout = () => {
    sessionStorage.clear()
    let isLogout = true
    sessionStorage.setItem('isLogout', isLogout)
  }

  handleSubmit = e => {
    e.preventDefault()

    // //(this.state.name);
    // //(this.state.surname);
    // //(this.state.email);
    // //(this.state.dob);
    // //(this.state.profile_pic);
    // //(this.state.type);
    // //(this.state.country_id);

    var name = this.state.name.trim()
    var surname = this.state.surname.trim()
    if (name === '') {
      this.setState({
        displaytext: 'show_block',
        nameErrorMessage: 'Name is required',
      })
      return
    } else {
      this.setState({
        displaytext: 'hide_block',
        nameErrorMessage: '',
      })
    }

    var nameValid = name.match('^[a-z A-Z]*$')
    if (!nameValid) {
      // this.setState({ );
      this.setState({
        displaytext: 'show_block',
        nameErrorMessage: 'Only alphabets are allowed',
      })
      return
    }

    if (name.length > 30) {
      this.setState({})
      this.setState({
        displaytext: 'show_block',
        nameErrorMessage: ' Name field can only contains 30 characters long.',
        surnameErrorMessage: '',
        emailErrorMessage: '',
        passwordErrorMessage: '',
        birthdayErrorMessage: '',
        countryErrorMessage: '',
        checkedErrorMessage: '',
      })
      return
    } else {
      this.setState({
        displaytext: 'hide_block',
        nameErrorMessage: '',
      })
    }
    if (name.length < 3) {
      this.setState({})
      this.setState({
        displaytext: 'show_block',
        nameErrorMessage: ' Name must be at least 3 characters long.',
        surnameErrorMessage: '',
        emailErrorMessage: '',
        passwordErrorMessage: '',
        birthdayErrorMessage: '',
        countryErrorMessage: '',
        checkedErrorMessage: '',
      })
      return
    } else {
      this.setState({
        displaytext: 'hide_block',
        nameErrorMessage: '',
      })
    }
    if (surname === '') {
      this.setState({})
      this.setState({
        displaytext: 'show_block',
        surnameErrorMessage: 'Surname is required',
      })
      return
    } else {
      this.setState({
        displaytext: 'hide_block',
        surnameErrorMessage: '',
      })
    }
    if (surname.length < 3) {
      this.setState({})
      this.setState({
        displaytext: 'show_block',
        surnameErrorMessage: ' Surname must be at least 3 characters long.',
        nameErrorMessage: '',
        emailErrorMessage: '',
        passwordErrorMessage: '',
        birthdayErrorMessage: '',
        countryErrorMessage: '',
        checkedErrorMessage: '',
      })
      return
    } else {
      this.setState({
        displaytext: 'hide_block',
        surnameErrorMessage: '',
      })
    }
    if (surname.length > 30) {
      this.setState({
        displaytext: 'show_block',
        surnameErrorMessage:
          ' Surname field can only contains 30 characters long.',
        nameErrorMessage: '',
        emailErrorMessage: '',
        passwordErrorMessage: '',
        birthdayErrorMessage: '',
        countryErrorMessage: '',
        checkedErrorMessage: '',
      })
      return
    } else {
      this.setState({
        displaytext: 'hide_block',
        surnameErrorMessage: '',
      })
    }
    var surnamenameValid = surname.match('^[a-z A-Z]*$')
    if (!surnamenameValid) {
      this.setState({
        displaytext: 'show_block',
        surnameErrorMessage: 'Only alphabets are allowed',
      })
      return
    }
    if (this.state.email === '') {
      this.setState({
        displaytext: 'show_block',
        emailErrorMessage: 'Email is required',
      })
      return
    } else {
      this.setState({
        displaytext: 'hide_block',
        emailErrorMessage: '',
      })
    }

    // var emailValid = this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    var emailValid = this.state.email.match(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
    if (emailValid) {
      if (this.state.password === '') {
        this.setState({
          displaytext: 'show_block',
          passwordErrorMessage: 'Password is required',
        })
        return
      } else {
        this.setState({
          displaytext: 'hide_block',
          passwordErrorMessage: '',
        })
      }
      if (this.state.dob === '') {
        this.setState({
          displaytext: 'show_block',
          birthdayErrorMessage: 'Birthday is required',
        })
        return
      } else {
        this.setState({
          displaytext: 'hide_block',
          birthdayErrorMessage: '',
        })
      }
      if (this.state.country_id === '') {
        this.setState({
          displaytext: 'show_block',
          countryErrorMessage: 'Country is Required',
          countryErrorClass: 'm-t-5 select_box select_box-rg-c-error',
        })
        return
      } else {
        this.setState({
          displaytext: 'hide_block',
          countryErrorMessage: '',
        })
      }

      if (this.state.profile_pic === '') {
        this.setState({})
        this.setState({
          displaytext: 'show_block',
          imgErrorMessage: 'Image is required',
        })
        return
      } else {
        this.setState({
          displaytext: 'hide_block',
          imgErrorMessage: '',
        })
      }
      if (this.state.isProfileUpdated) {
        var data = []
        if (this.state.dateChange) {
          var data = {
            name: name,
            surname: surname,
            email: this.state.email,
            dob: this.state.dob,
            country_id: this.state.country_id,
            profile_pic: this.state.profile_pic,
            type: this.state.type,
          }
        } else {
          var date = this.state.dob1
          var data = {
            name: this.state.name,
            surname: this.state.surname,
            email: this.state.email,
            dob: date,
            country_id: this.state.country_id,
            profile_pic: this.state.profile_pic,
            type: this.state.type,
          }
        }
      } else {
        if (this.state.dateChange) {
          var data = {
            name: this.state.name,
            surname: this.state.surname,
            email: this.state.email,
            dob: this.state.dob,
            country_id: this.state.country_id,
          }
        } else {
          var date = this.state.dob1
          var data = {
            name: this.state.name,
            surname: this.state.surname,
            email: this.state.email,
            dob: date,
            country_id: this.state.country_id,
          }
        }

        //(data)
      }

      fetch(api_Url + '/update', {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + window.sessionStorage.getItem('token'),
        },
      })
        .then(response => {
          return response.json()
        })
        .then(response => {
          //   //(response)
          if (response.status === 200) {
            this.setState({
              countryErrorMessage: response.message,
              countryErrorClass: 'm-t-5 select_box select_box-rg-c-error',
            })
            setTimeout(this.removeSuccessMessgae, 1500)
            // swal({
            //     text: "User Profile is Updated in successfully.",
            //     icon: "success",
            //     button : {
            //         text: "Ok",
            //         closeModal: true
            //     }
            //   }).then(OK =>{
            //     this.setState({isRedirect: true})
            //   })
          } else if (response.status === 422) {
            this.setState({
              countryErrorMessage: response.error,
            })
            // swal({

            //     text: "This email is already exist .",
            //     icon: "warning",
            //     dangerMode: true,
            // })
          }
        })
    } else {
      this.setState({
        displaytext: 'show_block',
        emailErrorMessage: 'Email is not valid',
      })
      return
    }
  }
  removeSuccessMessgae = e => {
    this.setState({
      countryErrorMessage: '',
      countryErrorClass: 'm-t-5 select_box',
    })
  }
  // Forgot Password
  forgotPassword = e => {
    e.preventDefault()

    if (
      this.state.old_password === '' &&
      this.state.new_password === '' &&
      this.state.c_password === ''
    ) {
      this.setState({
        displaytext: 'show_block',
        c_passwordErrorMessage: 'All password fields are  mandatory.',
      })
      return
    }
    // if (this.state.old_password === '') {
    //     this.setState({displaytext: 'show_block' , old_passwordErrorMessage: 'Current password field is mandatory' });
    //     return;
    // }

    if (this.state.new_password !== this.state.c_password) {
      this.setState({})
      this.setState({
        displaytext: 'show_block',
        c_passwordErrorMessage:
          'The new password and confirm password does not match.',
      })

      this.setState({})
      return
    } else {
      var data = {
        old_password: this.state.old_password,
        new_password: this.state.new_password,
        c_password: this.state.c_password,
      }

      fetch(api_Url + '/change-password', {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + window.sessionStorage.getItem('token'),
        },
      })
        .then(response => {
          return response.json()
        })
        .then(response => {
          if (response.status === 200) {
            let token = response.token
            this.setState({
              c_passwordErrorMessage: response.message,
            })
            window.sessionStorage.removeItem('token')
            window.sessionStorage.setItem('token', token)
          } else if (response.status === 422) {
            this.setState({
              passwordErrorMessage: response.error,
            })
          }
        })
    }
  }
  // GETTING PRODUCT LIST FOR NOTIFICATION

  getproductwithpremiumofuser = e => {
    fetch(api_Url + '/product/getproductwithpremiumofuser', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + window.sessionStorage.getItem('token'),
      },
    })
      .then(response => {
        return response.json()
      })
      .then(response => {
        var mainlang = []
        var pdata = response.product
        pdata.map(productData => {
          if (productData) {
            if (productData.result) {
              productData.result.map(resultData => {
                if (resultData) {
                  if (
                    resultData.user_id == window.sessionStorage.getItem('id')
                  ) {
                    if (mainlang.indexOf(productData) > -1) {
                    } else {
                      mainlang.push(productData)
                    }
                  }
                }
              })
            }
          }
        })
        if (mainlang.length > 0) {
          this.setState({
            languageList: mainlang,
          })
        } else {
        }
      })
  }

  // GET  ALL LANGUAGE FOR THE NOTIFICATION REDIRECTION
  getLanguageList = () => {
    //
    fetch(api_Url + `/product/getproductlist`)
      .then(response => response.json())

      .then(data => {
        //
        this.setState({
          AlllanguageList: data.product,
        })
        //(data,"All lang")
      })
  }

  // get Level List
  getLevelList = e => {
    // ;
    fetch(api_Url + '/language/listlevel')
      .then(response => response.json())
      .then(data => {
        //
        if (data.status == 200) {
          this.setState({
            levelList: data.level,
          })
        } else {
          this.setState({ levelList: [] })
        }

        //(data, "level")
      })
  }

  // FIND PRIMIUM ID OF PARICULAR PRODUCT

  getPremiunPriceidForNotification1 = languageId => {
    var premiumpriceId = []

    var promise = new Promise((resolve, reject) => {
      this.state.languageList.map(resultData => {
        if (resultData) {
          var product = resultData.product

          if (resultData.result[0]) {
            var rr = resultData.result[0]
            if (rr.product_id === languageId) {
              premiumpriceId = rr.premiumprice_id
            }
          }
        }

        resolve(premiumpriceId)
      })
    })
    return promise
  }
  // REDIERCT TO SELECTED PAGE

  redirectToPage = (e, data) => {
    const type = 'notification'
    if (
      data.notificationtype === 'language' ||
      data.notificationtype === 'package'
    ) {
      var notificatinType = data.notificationtype
      var languageId = data.language_id
      const product = this.state.AlllanguageList.find(l => l._id === languageId)
      const productName = product.product
      this.setState({
        language_id: languageId,
        productName: productName,
        notificatinType: notificatinType,
      })
    } else {
      const levelId = data.level_id
      const lessonType = data.lessontype
      const level = this.state.levelList.find(l => l._id === levelId)
      const levelName = level.level
      const chapterId = data.chapter_id
      const lessonId = data.lesson_id
      var notificatinType = data.notificationtype
      var languageId = data.language_id
      const product = this.state.languageList.find(l => l._id === languageId)
      const productName = product.product
      this.setState({
        language_id: languageId,
        lessonType: lessonType,
        productName: productName,
        type: type,
        level_id: levelId,
        levelName: levelName,
        chapter_id: chapterId,
        lesson_id: lessonId,
        notificatinType: notificatinType,
      })
    }
    this.getPremiunPriceidForNotification1(languageId).then(value => {
      var premiumpriceId = value
      if (this.state.notificatinType === 'chapter') {
        this.setState({ premiumpriceId: premiumpriceId })
        // return <Redirect to={`/introduction/${this.state.level_id}/${this.state.levelName}/${premiumpriceId}/${this.state.language_id}/${this.state.productName}/${this.state.chapter_id}/${this.state.type}`}/>
      }
    })
  }
  // hide notification
  hideNotification = () => {
    this.setState({ showNotificatonbox: !this.state.showNotificatonbox })
  }

  render() {
    if (
      this.state.lessonType === 'otherlevel' ||
      this.state.notificatinType == 'hourchapter'
    ) {
      if (this.state.notificatinType == 'hourchapter') {
        return (
          <Redirect
            to={`/introduction/${this.state.level_id}/${this.state.levelName}/${this.state.premiumprice_id}/${this.state.language_id}/${this.state.productName}/${this.state.chapter_id}/${this.state.type}`}
          />
        )
      } else if (this.state.notificatinType == 'lesson') {
        return (
          <Redirect
            to={`/introduction/${this.state.level_id}/${this.state.levelName}/${this.state.premiumprice_id}/${this.state.language_id}/${this.state.productName}/${this.state.chapter_id}/${this.state.lesson_id}/${this.state.type}`}
          />
        )
      } else if (this.state.notificatinType == 'language') {
        return <Redirect to={`/subscriptions`} />
      }
    } else {
      if (this.state.notificatinType == 'entertainmentchapter') {
        return (
          <Redirect
            to={`/entertainment/${this.state.level_id}/${this.state.levelName}/${this.state.premiumprice_id}/${this.state.language_id}/${this.state.productName}/${this.state.chapter_id}/${this.state.type}`}
          />
        )
      } else if (this.state.notificatinType == 'lesson') {
        return (
          <Redirect
            to={`/entertainment/${this.state.level_id}/${this.state.levelName}/${this.state.premiumprice_id}/${this.state.language_id}/${this.state.productName}/${this.state.chapter_id}/${this.state.lesson_id}/${this.state.type}`}
          />
        )
      } else if (this.state.notificatinType == 'language') {
        return <Redirect to={`/ser-subscription-details`} />
      } else if (this.state.notificatinType == 'level') {
        return <Redirect to={`/dashboard`} />
      } else if (this.state.notificatinType == 'package') {
        return <Redirect to={`/user-subscription-details`} />
      }
    }

    return (
      <div>
        {this.state.isLoading && <div class="loader1"></div>}
        <header className="topnav" id="myTopnav">
          <Link to="/">
            <img className="logo" src={logo} />
          </Link>

          <div className="navlist" id="navlist">
            <Link className="cursor0">
              <img
                style={{
                  height: '38px',
                  position: 'relative',
                  right: '-11px',
                }}
                src={coin__icon}
              />
              <span className="button__badge">{this.state.credit}</span>
            </Link>

            <Link
              style={{ cursor: 'pointer' }}
              onClick={this.getNotification}
              className="cursor0"
              to="/profile"
            >
              <i className="fa fa-bell-o"></i>
              <span className="button__badge">
                {this.state.notificationCount}
              </span>
              {this.state.showNotificatonbox && (
                <button
                  className="topBarBack"
                  type="button"
                  onClick={this.hideNotification}
                />
              )}

              {this.state.showNotificatonbox && (
                <div id="notifications">
                  <h3>Notifications</h3>
                  {this.state.isloading1 ? (
                    <div style={{ position: 'absolute' }} class="loader1"></div>
                  ) : (
                    <>
                      <div
                        className={
                          this.state.notificationList.length > 0
                            ? ' notification-data'
                            : ''
                        }
                      >
                        {this.state.notificationList.length > 0 ? (
                          this.state.notificationList.map(notification => (
                            <div
                              onClick={e =>
                                this.redirectToPage(e, notification)
                              }
                              className="main-nf-data"
                            >
                              {notification.user_id ? (
                                <img
                                  src={
                                    api_Url +
                                    '/UserProfile/' +
                                    notification.user_id.profile_pic
                                  }
                                />
                              ) : (
                                <img src={user} />
                              )}
                              {notification.role === 'Admin' ? (
                                <p>
                                  {''}
                                  {notification.notificationdata}
                                </p>
                              ) : (
                                <p>
                                  {' '}
                                  {notification.user_id &&
                                    notification.user_id.name}{' '}
                                  {''}
                                  {notification.notificationdata}
                                </p>
                              )}
                              <span></span>
                            </div>
                          ))
                        ) : (
                          <div className="notification-none-data">
                            <img src={notificationicon} />
                            <p
                              style={{
                                color: '#444444',
                              }}
                            >
                              You don't have notification
                            </p>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                  {/* <div class="seeAll"><a href="#">See All</a></div> */}
                </div>
              )}
            </Link>
            <Link className="navoption" to="/dashboard">
              My Courses
            </Link>
            <Link className="navoption" to="/profile">
              Profile<hr className="hr"></hr>
            </Link>
            {/* <Link className="navoption" to="/profilebookatutor">Book a Tutor</Link> */}
            <Link className="navoption" to="/user-subscription-details">
              Subscription
            </Link>
            <Link className="navoption" to="/login" onClick={this.onLogout}>
              Sign Out
            </Link>
            <a href="javascript:void(0);" className="icon" id="hamburger">
              <span></span>
              <span></span>
              <span></span>
            </a>
          </div>
        </header>
        <section className="profile-section bg-gray m-t-50 p-t-100 p-b-200">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 m-b-20">
                <div className="profile-left-side">
                  <ul>
                    <li
                      className={
                        this.state.selected === 'profile' ? 'active' : ''
                      }
                      onClick={() => this.activateTab('profile')}
                    >
                      <Link to="profile">Profile</Link>
                    </li>
                    <li
                      className={
                        this.state.selected === 'password' ? 'active' : ''
                      }
                      onClick={() => this.activateTab('password')}
                    >
                      <Link to="profile">Password</Link>
                    </li>
                    <li
                      className={
                        this.state.selected === 'notifications' ? 'active' : ''
                      }
                      onClick={() => this.activateTab('notifications')}
                    >
                      <Link to="profile">Notifications</Link>
                    </li>
                    <li
                      className={
                        this.state.selected === 'certificates' ? 'active' : ''
                      }
                      onClick={() => this.activateTab('certificates')}
                    >
                      <Link to="profile">Certificates</Link>
                    </li>
                    <li
                      className={
                        this.state.selected === 'subscription' ? 'active' : ''
                      }
                      onClick={() => this.activateTab('subscription')}
                    >
                      <Link to="profile">Subscription</Link>
                    </li>
                    <li
                      className={
                        this.state.selected === 'orderhistory' ? 'active' : ''
                      }
                      onClick={() => this.activateTab('orderhistory')}
                    >
                      <Link to="profile">Order History</Link>
                    </li>
                  </ul>
                </div>
              </div>
              {this.state.selected === 'profile' && (
                <div className="col-lg-8 m-b-20">
                  <div className="profile-right-side">
                    <div className="mr-right txt-center">
                      {this.state.staticImg && (
                        <img
                          className="profile-userpic"
                          src={
                            api_Url + '/UserProfile/' + this.state.profile_pic
                          }
                        />
                      )}
                      {this.state.dynamicImg && (
                        <img
                          className="profile-userpic"
                          src={this.state.file}
                        />
                      )}
                      <br />
                      {/* <label className="upload-a"><img src={upload_icon} /><input className="hide-input" type="file" accept=" image/jpeg, image/png" name="photo" onChange={this.imageChange} />Upload new image</label> */}
                      <label className="upload-a">
                        <img src={upload_icon} />
                        <input
                          className="hide-input"
                          accept=" image/jpeg, image/png"
                          name="photo"
                          onClick={this.imageChange}
                        />
                        Upload new image
                      </label>
                      {/* <p className={this.state.displaytext + " text-danger"}>{this.state.imgErrorMessage}</p> */}
                      <p>Registered on {this.state.register_date}</p>
                    </div>
                    <form
                      onSubmit={this.handleSubmit}
                      className="login100-form validate-form flex-sb flex-w m-t-0"
                    >
                      <span className="txt1 p-b-11">Name</span>
                      <div className="m-t-5">
                        <input
                          className="input100"
                          type="text"
                          name="name"
                          value={this.state.name}
                          onChange={this.handleChange}
                        />
                        <p className={this.state.displaytext + ' text-danger'}>
                          {this.state.nameErrorMessage}
                        </p>
                        <span className="focus-input100"></span>
                      </div>
                      <span className="txt1 p-b-11">Surname</span>
                      <div className="m-t-5">
                        <input
                          className="input100"
                          type="text"
                          name="surname"
                          value={this.state.surname}
                          onChange={this.handleChange}
                        />
                        <p className={this.state.displaytext + ' text-danger'}>
                          {this.state.surnameErrorMessage}
                        </p>
                        <span className="focus-input100"></span>
                      </div>
                      <span className="txt1 p-b-11">E-mail</span>
                      <div className="m-t-5">
                        <input
                          className="input100"
                          type="text"
                          name="email"
                          value={this.state.email}
                          onChange={this.handleChange}
                        />
                        <p className={this.state.displaytext + ' text-danger'}>
                          {this.state.emailErrorMessage}
                        </p>
                        <span className="focus-input100"></span>
                      </div>
                      <span className="txt1 p-b-11">Birthday</span>

                      <div className="m-t-5">
                        <DatePicker
                          className="input161-dt"
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          maxDate={new Date()}
                          value={this.state.dob}
                          selected={this.state.date}
                          onChange={this.datehandleChange}
                          placeholderText="birthday"
                        />
                        <p className={this.state.displaytext + ' text-danger'}>
                          {this.state.birthdayErrorMessage}
                        </p>
                        <span className="focus-input100"></span>
                      </div>
                      <span className="txt1 p-b-11">Country</span>
                      <div className={this.state.countryErrorClass}>
                        <select
                          className="input100 br-w-0"
                          name="country_id"
                          value={this.state.country_id}
                          onChange={this.handleChange}
                        >
                          <option value={'0'}>-Select Country-</option>
                          {this.state.country.length > 0
                            ? this.state.country.map(item => (
                                <option key={item._id} value={item._id}>
                                  {item.country}
                                </option>
                              ))
                            : null}
                        </select>
                        <p className={this.state.displaytext + ' text-danger'}>
                          {this.state.countryErrorMessage}
                        </p>
                        <span className="focus-input100"></span>
                      </div>
                      <div className="flex-sb-m w-full p-b-48 m-t-25">
                        <div className="container-login100-form-btn">
                          <button className="login100-form-btn">Save</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              )}
              {this.state.selected === 'password' && (
                <div className="col-lg-8 m-b-20">
                  <div className="profile-right-side">
                    <form
                      onSubmit={this.forgotPassword}
                      className="login100-form validate-form flex-sb flex-w m-t-0"
                    >
                      <span className="txt1 p-b-11">Change Password</span>
                      {/* <div className="m-t-5">
                                                <input className="input100" type="password" value={this.state.old_password} onChange={this.handleCurrentPasswordChange} name="old_password" placeholder="Current Password" />
                                                <p className={this.state.displaytext + " text-danger"}>{this.state.old_passwordErrorMessage}</p>
                                                <span className="focus-input100"></span>
                                            </div> */}

                      <div className="m-t-5">
                        <input
                          className="input100"
                          type="password"
                          value={this.state.new_password}
                          onChange={this.handleChange}
                          name="new_password"
                          placeholder="New Password"
                        />
                        <span className="focus-input100"></span>
                      </div>

                      <div className="m-t-5">
                        <input
                          className="input100"
                          type="password"
                          value={this.state.c_password}
                          onChange={this.handleChange}
                          name="c_password"
                          placeholder="Confirm New Password"
                        />
                        <p className={this.state.displaytext + ' text-danger'}>
                          {this.state.c_passwordErrorMessage}
                        </p>
                        <span className="focus-input100"></span>
                      </div>

                      <div className="flex-sb-m w-full p-b-48 m-t-25">
                        <div className="container-login100-form-btn">
                          <button className="login100-form-btn">Save</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              )}
              {this.state.selected === 'notifications' && (
                <div className="col-lg-8 m-b-20">
                  <div className="sub-right-side">
                    <form onSubmit={this.submitUserNotificationsetting}>
                      <h6 className="m-b-20">
                        Manage your Email Notifications
                      </h6>
                      <p>
                        <span className="announcement">
                          {' '}
                          {this.state.notificationCheckboxteaching1 ===
                            true && (
                            <i
                              className="fa fa-square-o"
                              onClick={() => this.checkbox1('teaching')}
                            ></i>
                          )}
                          {this.state.notificationCheckboxteaching2 ===
                            true && (
                            <i
                              className="fa fa-check-square"
                              onClick={() => this.checkbox2('teaching')}
                            ></i>
                          )}
                        </span>{' '}
                        Announcements from the teaching team
                      </p>

                      {/* <p><span className="comments"><i className="fa fa-square-o"></i></span> Comments added after mine on any video</p> */}
                      <p>
                        <span className="announcement">
                          {' '}
                          {this.state.notificationCheckboxcomment1 === true && (
                            <i
                              className="fa fa-square-o"
                              onClick={this.checkbox1}
                            ></i>
                          )}
                          {this.state.notificationCheckboxcomment2 === true && (
                            <i
                              className="fa fa-check-square"
                              onClick={this.checkbox2}
                            ></i>
                          )}
                        </span>{' '}
                        Comments added after mine on any video
                      </p>
                      <p className={this.state.displaytext + ' text-danger'}>
                        {this.state.notificationmessage}
                      </p>
                      <div className="container-login100-form-btn m-t-30">
                        <button className="login100-form-btn">Save</button>
                      </div>
                    </form>
                  </div>
                </div>
              )}
              {this.state.selected === 'certificates' && (
                <div className="col-lg-8 m-b-20">
                  {this.state.certificateList.length > 0 ? (
                    <div className="order-right-side">
                      <h6>My Certificates</h6>
                      <div className="mycertificate">
                        <table className="tb-margin">
                          <tbody>
                            <tr className="tr br-gray">
                              <th>Hindi</th>
                              <th>Files</th>
                            </tr>
                            <tr>
                              <td>One Hour level concluded</td>
                              <td>
                                <a className="td-a" href="">
                                  Download here
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : (
                    <div className="order-right-side">
                      <h6>My Certificates</h6>
                      <div className="mycertificate">
                        <table className="tb-margin">
                          <tbody>
                            <tr className="mycertificate-tr br-gray">
                              <th>Hindi</th>
                              <th>Files</th>
                            </tr>
                            <tr>
                              <td>No certificates available for download.</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {this.state.selected === 'subscription' && (
                <div className="col-lg-8 m-b-20">
                  {this.state.userSubscriptionDetails &&
                    !!this.state.userSubscriptionDetails.length &&
                    this.state.userSubscriptionDetails.map(subscriptoin => (
                      <div className="sub-right-side">
                        <h6>Manage your Subscription</h6>
                        <br />
                        <p>
                          You purchased{' '}
                          {subscriptoin.premiumprice_id.premiumname} through
                          langistan.com on{' '}
                          {moment(subscriptoin.created_at).format(
                            'MMMM DD, YYYY'
                          )}
                          . To continue using Cocoma Academy after{' '}
                          {moment(subscriptoin.expirytime).format(
                            'MMMM DD, YYYY'
                          )}
                          , your subscription has to be renewed at the price of
                          IN ₹ {subscriptoin.premiumprice_id.inrprice}.
                        </p>
                        {subscriptoin.sourcetype == 'free' ? (
                          <div className="sub-btn-group">
                            <div>
                              <a className="btn-renew" href="">
                                Upgrade Subscription
                              </a>
                            </div>
                            <div>
                              <a className="btn-questions" href="">
                                Questions? Contact Us
                              </a>
                            </div>
                          </div>
                        ) : (
                          <div className="sub-btn-group">
                            <div>
                              <a className="btn-renew" href="">
                                Renew Subscription
                              </a>
                            </div>
                            <div>
                              <a className="btn-questions" href="">
                                Questions? Contact Us
                              </a>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}

                  {/* <p>or</p> */}

                  {this.state.showUpgradesection === false && (
                    <div className="sub-right-side">
                      <h6>Manage your Subscription</h6>
                      <br />
                      <p>
                        You purchased a free plan through langistan.com on March
                        11, 2019. To have full access to the course, your
                        subscription has to be upgraded at the price of US$ 99.
                      </p>
                      <div className="sub-btn-group">
                        <div>
                          <a className="btn-renew" href="">
                            Renew Subscription
                          </a>
                        </div>
                        <div>
                          <a className="btn-questions" href="">
                            Questions? Contact Us
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {this.state.selected === 'orderhistory' && (
                <div className="col-lg-8 m-b-20">
                  {this.state.userSubscriptionDetails &&
                    !!this.state.userSubscriptionDetails.length &&
                    this.state.userSubscriptionDetails.map(subscriptoin => (
                      <div className="order-right-side">
                        <h6>My Purchases</h6>
                        <div className="mypurchase">
                          <table className="tb-margin">
                            <tbody>
                              <tr className="mypurchase-tr br-gray">
                                <th>{subscriptoin.product_id.product}</th>
                                <th>Date</th>
                                <th>Expiry Date</th>
                                <th>Status</th>
                              </tr>
                              <tr>
                                <td>
                                  {subscriptoin.premiumprice_id.premiumname}
                                </td>
                                <td>
                                  {moment(subscriptoin.created_at).format(
                                    'MMMM DD, YYYY'
                                  )}
                                </td>
                                <td>
                                  {moment(subscriptoin.expirytime).format(
                                    'MMMM DD, YYYY'
                                  )}
                                </td>
                                <td>Done</td>
                              </tr>
                              {/* <tr>
                                                        <td>Join a Conversation</td>
                                                        <td>March 30, 2019</td>
                                                        <td>April 6, 2019</td>
                                                        <td>Done</td>
                                                    </tr>  */}
                              {/* <tr>
                                                        <td>Join a Conversation</td>
                                                        <td>March 30, 2019</td>
                                                        <td>April 6, 2019</td>
                                                        <td>Done</td>
                                                    </tr> */}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ))}
                  <br />
                  {/* <p>or</p> */}
                  {this.state.showUpgradesection === false && (
                    <div className="order-right-side">
                      <h6>My Purchases</h6>
                      <div className="mypurchase">
                        <table className="tb-margin">
                          <tbody>
                            <tr className="mypurchase-tr br-gray">
                              <th>Hindi</th>
                              <th>Date</th>
                              <th>Expiry Date</th>
                              <th>Status</th>
                            </tr>
                            <tr>
                              <td>You do not have any orders yet.</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </section>
        {this.state.showImageCorp && (
          <div className="static-modal">
            <div>
              <Modal
                show={this.state.showImageCorp}
                onHide={this.CloseImageCrop}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Crop Image</Modal.Title>
                </Modal.Header>
                {/* <Modal.Body style = {{ textAlign:'center'}}> */}
                <Modal.Body style={{ padding: '50px' }}>
                  {/* <div style={{backgroundImage:'url("https://lumiere-a.akamaihd.net/v1/images/open-uri20150422-20810-af2aao_4e6f6afc.jpeg?region=0%2C0%2C400%2C300")',width:'250px',height:'250px',margin:'auto'}}>
        <AvatarImageCropper apply={this.apply.bind(this)} isBack={true}/>
      </div>  */}

                  <div
                    style={{
                      width: '100%',
                      textAlign: 'center',
                    }}
                  >
                    <Avatar
                      width={400}
                      height={300}
                      imageWidth={400}
                      imageHeight={300}
                      onCrop={this.onCrop}
                      onClose={this.onClose}
                      onBeforeFileLoad={this.onBeforeFileLoad}
                      label="Upload new image"
                      labelStyle={{
                        color: '#aacc88',
                        fontSize: '20px',
                      }}
                      borderStyle={{
                        border: '2px dashed #aacc88',
                        textAlign: 'center',
                        margin: 'auto',
                        marginTop: '25px',
                        marginBottom: '25px',
                      }}
                      closeIconColor="red"
                      src={this.state.src}
                    />{' '}
                  </div>
                  {/* </div>    */}
                  <p className={this.state.displaytext + ' text-danger'}>
                    {this.state.imgErrorMessage}
                  </p>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    className="reset-pass100-form-btn"
                    onClick={this.CloseImageCrop}
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    onClick={this.saveImage}
                    className="reset-pass100-form-btn"
                  >
                    {' '}
                    SAVE
                  </button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default Profile
