import { useHistory } from 'react-router'
import logo from 'src/asset/images/logoBeta.svg'

import { isMobile } from '../CourseDetail/Practice/Util'
const {
  Box,
  Flex,
  Text,
  Stack,
  Image,
  HStack,
  Button,
  VStack,
} = require('@chakra-ui/react')
const btnBg =
  'transparent linear-gradient(180deg, #2469F5 0%, #12357B 100%) 0% 0% no-repeat padding-box'
const btnHover = {
  color: '#fff',
  background:
    'transparent linear-gradient(180deg, #2469F5 0%, #12357B 100%) 0% 0% no-repeat padding-box',
}

const Toolbar = () => {
  const history = useHistory()
  const onLogin = () => {
    history.push({
      pathname: '/login',
      state: {
        comingFromHome: true,
      },
    })
  }
  const onSignUp = () => {
    history.push({
      pathname: '/register',
      state: {
        comingFromHome: true,
      },
    })
  }
  const height = '40px'
  const width = isMobile ? '80px' : '110px'
  return (
    <Box
      h="60px"
      py={5}
      px={[2, 0, 1]}
      boxShadow={'0px 3px 8px #00000029'}
      position={'sticky'}
      top={0}
      zIndex={1000}
      bg={'#fff'}
    >
      <HStack h={4} justifyContent={'space-between'} flexDir={'row'}>
        <Image
          src={logo}
          sx={{
            objectFit: 'contain',
            height: '65px',
            width: '150px',
            marginTop: '10px',
          }}
        ></Image>
        <HStack py={5} px={3}>
          <Button
            color="#707070"
            w={width}
            h={height}
            borderRadius={'5px'}
            // bg={btnBg}
            _hover={btnHover}
            style={{
              border: '1px solid #707070',
              backgroundColor: '#fff',
            }}
            onClick={onLogin}
          >
            Login
          </Button>
          <Button
            color="#707070"
            w={width}
            h={height}
            //bg={btnBg}
            style={{
              border: '1px solid #707070',
              backgroundColor: '#fff',
            }}
            borderRadius={'5px'}
            _hover={btnHover}
            onClick={onSignUp}
          >
            Sign up
          </Button>
        </HStack>
      </HStack>
    </Box>
  )
}

export default Toolbar
