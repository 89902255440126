import React from 'react'
import Header from './Header'
import Footer from './Footer'
import imageshomepage1 from '../Images/imageshomepage1.png'
import imageshomepage2 from '../Images/imageshomepage2.png'
import imageshomepage3 from '../Images/imageshomepage3.png'
import recommendation from '../Images/recommendation-tiffanyip.JPG'
import '../fonts/fonts/font-awesome-4.7.0/css/font-awesome.min.css'
import ReactPlayer from 'react-player'
import Ccutil from './ccavutil'
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from 'react-html-parser'

import { Link, Redirect } from 'react-router-dom'
import { PayPalButton } from 'react-paypal-button-v2'
import * as myConstClass from './constants'
const api_Url = myConstClass.api
const qs = require('querystring')

//const api_Url = 'http://35.244.3.174:4000';
class paypalmobile extends React.Component {
  constructor() {
    super()
    this.state = {
      testimoniallist: [],
      displaytext: 'hide_block',
      parmanenttestimoniallist: '',
      arr: [],
      indexValue: 0,
      formdata: '',
      transactionid: '',
      product_id: '',
      plan_id: '',
      expired_time: '',
      token: '',
    }
  }

  componentWillMount() {
    this.setState({
      transactionid: this.props.match.params.transactionid,
      product_id: this.props.match.params.product_id,
      plan_id: this.props.match.params.plan_id,
      expired_time: this.props.match.params.expired_time,
      token: this.props.match.params.token,
      user: this.props.match.params.user,
    })
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  saveTransactionData = (result, trackingId) => {
    var data = {
      product_id: this.props.match.params.product_id,
      premiumprice_id: this.props.match.params.plan_id,
      expirytime_id: this.props.match.params.expired_time,
      type: 'foreigner',
      sourcetype: 'PayPal',
      order_id: result.orderID,
      transaction_id: this.props.match.params.transactionid,
      payerid: trackingId,
      tracking_id: trackingId,
    }

    fetch(api_Url + '/usersubscribeonproductforpaypal', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.props.match.params.token,
      },
    })
      .then(response => {
        return response.json()
      })
      .then(response => {
        if (response.status === 200) {
          this.setState({ isRedirect: 'success' })
          // if(this.state.planType==='foreigner'){
          //   this.setState({isRedirectPayPalSuccessPage:true})
          // }

          //     this.getsubscriptionList()
        } else if (response.status === 422) {
          this.setState({ isRedirect: 'cancel' })

          // this.setState({
          //   countryErrorMessage: response.error
          // })
        }
      })
  }

  render() {
    if (this.state.isRedirect == 'cancel') {
      return <Redirect to="/cancel_payment" />
    } else if (this.state.isRedirect == 'success') {
      return <Redirect to="/success_payment" />
    }
    return (
      <div>
        <PayPalButton
          amount="1"
          onSuccess={(details, data) => {
            this.saveTransactionData(data, details.id)
          }}
          catchError={(err, msg) => {
            this.setState({ isRedirectPayPalCancelPage: true })
          }}
          onError={(err, msg) => {
            this.setState({ isRedirectPayPalCancelPage: true })
          }}
          options={{
            clientId:
              'ARI6EPCb_DOLS2qhvJqoQ-3SaoyxJLpH2ohK34vPKrHGcqy946g_apchxoDK2SyaSZbITljtIIzAfDKj',
            currency: 'INR',
          }}
        />
      </div>
    )
  }
}

export default paypalmobile
