import '../fonts/fonts/font-awesome-4.7.0/css/font-awesome.min.css'
import '../fonts/work-sans-cufonfonts-webfont/style.css'

import * as $ from 'jquery'
import React from 'react'
import { Link, Redirect } from 'react-router-dom'

import coin__icon from '../Images/coin-icon.png'
import logo from '../Images/logo2x.png'
import notificationicon from '../Images/notification.png'
//const api_Url = 'http://35.244.3.174:4000';
import * as myConstClass from './constants'
import Footer from './Footer'
import Header from './Header'
const api_Url = myConstClass.api
class Prices extends React.Component {
  constructor() {
    super()
    this.state = {
      displaytext: 'hide_block',
      product: [],
      result: [],
      languageList: [],
      product_id: '',
      SearchStr: '',
      notificationCount: '',
      notificationList: '',
      showNotificatonbox: false,
      isLoading: true,
      productName: '',
      levelName: '',
      notificatinType: '',
      type: '',
      language_id: '',
      chapter_id: '',
      level_id: '',
      lesson_id: '',
      AlllanguageList: [],
      notificationlanguageList: [],
      lessonType: '',
      credit: 0,
      countrycode: '',
      levelList: [],
    }
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  handleChange = e => {
    if (e.target.value !== '0') {
      this.setState(
        {
          [e.target.name]: e.target.value,
          SearchStr: e.target.value,
        },
        () => {
          if (this.state.SearchStr == '0') {
            return
          } else {
            this.getProductList()
          }
        }
      )
    }
  }

  onLogout = () => {
    sessionStorage.clear()
    let isLogout = true
    sessionStorage.setItem('isLogout', isLogout)
  }

  componentWillMount() {
    let token = window.sessionStorage.getItem('token')
    if (token) {
      this.getNotificationCount()
      this.getproductwithpremiumofuser()
      this.getLanguageListforNotification()
      this.getLevelList()
    } else {
      return this.props.history.push('/')
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside)
    $(document).ready(function () {
      var _ = this

      var opcionesnav = $('.navoption').length
      var clickhamb = 0

      $('#hamburger').click(function () {
        clickhamb = 1
        var header = $('#myTopnav')
        if (header[0].classList.length === 1) {
          header.addClass('responsive')
          $('header').height((opcionesnav + 1) * 70)
          $('.navlist a:not(.icon)').css('display', 'block')
          setTimeout(function () {
            $('.navlist a:not(.icon)').css('transform', 'translateX(0px)')
          }, 50)
        } else {
          $('.navlist a:not(.icon)').css('transform', 'translateX(600px)')
          header.height(70)
          setTimeout(function () {
            header.removeClass('responsive')
            header.height(70)
            $('.navlist a:not(.icon)').css('display', 'none')
          }, 50)
        }
      })

      $(window).on('resize', function () {
        if ($(window).width() > 600 && clickhamb == 1) {
          $('#myTopnav').height(70)
          $('.navlist a:not(.icon)').css('display', 'block')
          setTimeout(function () {
            $('.navlist a:not(.icon)').css('transform', 'translateX(0px)')
          }, 50)
        }
      })
    })

    // this.ChangeTime()

    window.scrollTo(0, 0)
    this.getLanguageList()
    this.getProductList()
  }

  getNotificationCount() {
    fetch(api_Url + '/get-notifications-count', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + window.sessionStorage.getItem('token'),
      },
    })
      .then(response => response.json())

      .then(response => {
        if (response.status === 200) {
          this.setState(
            {
              notificationCount: response.notification,
              credit: response.credit,
              call: true,
            },
            () => {}
          )
        } else {
        }
      })
  }
  getNotification = e => {
    fetch(api_Url + '/get-notifications-data', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + window.sessionStorage.getItem('token'),
      },
    })
      .then(response => response.json())

      .then(response => {
        if (response.status === 200) {
          this.setState(
            {
              notificationList: response.notification,
              showNotificatonbox: !this.state.showNotificatonbox,
              called: 'yes',
            },
            () => {
              this.componentDidMount()
            }
          )
          this.ChangeTime()
        }
      })
  }
  ChangeTime() {
    fetch(api_Url + '/change-time', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + window.sessionStorage.getItem('token'),
      },
    })
      .then(response => response.json())

      .then(response => {
        this.setState({ notificationCount: response.notification })
        this.setState({ notificationCount: '0' })
      })
  }
  handleClickOutside(event) {
    //

    $(document).click(e => {
      //
      $('#notifications').hide()
    })
    //  this.setState({showNotificatonbox:false})

    // if(this.state.called ==='yes' && this.state.showNotificatonbox){
    //     this.setState({showNotificatonbox:false})

    // }
  }
  getProductList() {
    fetch(api_Url + `/product/getproductlist?search=${this.state.SearchStr}`)
      .then(response => response.json())

      .then(data => {
        if (data.status === 200) {
          this.setState({
            product: data.product,
          })
        } else {
          this.setState({
            product: [],
          })
        }
        this.setState({ isLoading: false })
      })
  }

  getLanguageList() {
    fetch(api_Url + `/product/getproductlist`)
      .then(response => response.json())

      .then(data => {
        this.setState(
          {
            languageList: data.product,
          },
          () => {
            if (data.product.length == 0) {
            } else {
              this.setState(
                {
                  product_id: this.state.languageList[0].product,
                  SearchStr: this.state.languageList[0].product,
                },
                () => {
                  this.getProductList()
                }
              )
            } // var selected  = this.filterDefaultLanguage(0);
            // this.setState({product_id: selected[0].product, SearchStr :selected[0].product},()=>{
            // this.getProductList();
            //  })
          }
        )
      })
  }
  // GETTING PRODUCT LIST FOR NOTIFICATION

  getproductwithpremiumofuser = e => {
    fetch(api_Url + '/product/getproductwithpremiumofuser', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + window.sessionStorage.getItem('token'),
      },
    })
      .then(response => {
        return response.json()
      })
      .then(response => {
        var mainlang = []
        var pdata = response.product
        pdata.map(productData => {
          if (productData) {
            if (productData.result) {
              productData.result.map(resultData => {
                if (resultData) {
                  if (
                    resultData.user_id == window.sessionStorage.getItem('id')
                  ) {
                    if (mainlang.indexOf(productData) > -1) {
                    } else {
                      mainlang.push(productData)
                    }
                  }
                }
              })
            }
          }
        })
        if (mainlang.length > 0) {
          this.setState({
            notificationlanguageList: mainlang,
          })
        } else {
        }
      })
  }

  // GET  ALL LANGUAGE FOR THE NOTIFICATION REDIRECTION
  getLanguageListforNotification = () => {
    //
    fetch(api_Url + `/product/getproductlist`)
      .then(response => response.json())

      .then(data => {
        //
        this.setState({
          AlllanguageList: data.product,
        })
      })
  }

  // get Level List
  getLevelList = e => {
    // ;
    fetch(api_Url + '/language/listlevel')
      .then(response => response.json())
      .then(data => {
        //
        if (data.status == 200) {
          this.setState({
            levelList: data.level,
          })
        } else {
          this.setState({ levelList: [] })
        }
      })
  }

  // FIND PRIMIUM ID OF PARICULAR PRODUCT

  getPremiunPriceidForNotification1 = languageId => {
    var premiumpriceId = []

    var promise = new Promise((resolve, reject) => {
      this.state.languageList.map(resultData => {
        if (resultData) {
          var product = resultData.product

          if (resultData.result[0]) {
            var rr = resultData.result[0]
            if (rr.product_id === languageId) {
              premiumpriceId = rr.premiumprice_id
            }
          }
        }

        resolve(premiumpriceId)
      })
    })
    return promise
  }
  // REDIERCT TO SELECTED PAGE

  redirectToPage = (e, data) => {
    const type = 'notification'
    if (
      data.notificationtype === 'language' ||
      data.notificationtype === 'package'
    ) {
      //   var notificatinType = data.notificationtype;
      //   var languageId = data.language_id;
      const product = this.state.AlllanguageList.find(
        l => l._id === data.language_id
      )
      //   const productName = product.product;
      this.setState({
        language_id: data.language_id,
        productName: product.product,
        notificatinType: data.notificationtype,
      })
    } else {
      const levelId = data.level_id
      // const lessonType = data.lessontype
      const level = this.state.levelList.find(l => l._id === data.level_id)
      // const levelName = level.level;
      // const chapterId = data.chapter_id;
      // const lessonId = data.lesson_id
      // var notificatinType = data.notificationtype;
      // var languageId = data.language_id;
      const product = this.state.languageList.find(
        l => l._id === data.language_id
      )
      // const productName = product.product;
      this.setState({
        language_id: data.language_id,
        lessonType: data.lessontype,
        productName: product.product,
        type: type,
        level_id: data.level_id,
        levelName: level.level,
        chapter_id: data.chapter_id,
        lesson_id: data.lesson_id,
        notificatinType: data.notificationtype,
      })
    }
    this.getPremiunPriceidForNotification1(data.language_id).then(value => {
      var premiumpriceId = value
      if (this.state.notificatinType === 'chapter') {
        this.setState({ premiumpriceId: premiumpriceId })
        // return <Redirect to={`/introduction/${this.state.level_id}/${this.state.levelName}/${premiumpriceId}/${this.state.language_id}/${this.state.productName}/${this.state.chapter_id}/${this.state.type}`}/>
      }
    })
  }

  render() {
    var refresh = sessionStorage.getItem('refresh')
    if (refresh === null) {
      sessionStorage.setItem('refresh', '1')
    }
    if (this.state.lessonType === 'otherlevel') {
      if (this.state.notificatinType == 'chapter') {
        return (
          <Redirect
            to={`/introduction/${this.state.level_id}/${this.state.levelName}/${this.state.premiumprice_id}/${this.state.language_id}/${this.state.productName}/${this.state.chapter_id}/${this.state.type}`}
          />
        )
      } else if (this.state.notificatinType == 'lesson') {
        return (
          <Redirect
            to={`/introduction/${this.state.level_id}/${this.state.levelName}/${this.state.premiumprice_id}/${this.state.language_id}/${this.state.productName}/${this.state.chapter_id}/${this.state.lesson_id}/${this.state.type}`}
          />
        )
      } else if (this.state.notificatinType == 'language') {
        sessionStorage.removeItem('refresh')
        return <Redirect to={`/subscriptions`} />
      }
    } else {
      if (this.state.notificatinType == 'chapter') {
        return (
          <Redirect
            to={`/entertainment/${this.state.level_id}/${this.state.levelName}/${this.state.premiumprice_id}/${this.state.language_id}/${this.state.productName}/${this.state.chapter_id}/${this.state.type}`}
          />
        )
      } else if (this.state.notificatinType == 'lesson') {
        return (
          <Redirect
            to={`/entertainment/${this.state.level_id}/${this.state.levelName}/${this.state.premiumprice_id}/${this.state.language_id}/${this.state.productName}/${this.state.chapter_id}/${this.state.lesson_id}/${this.state.type}`}
          />
        )
      } else if (this.state.notificatinType == 'language') {
        sessionStorage.removeItem('refresh')
        return <Redirect to={`/subscriptions`} />
      } else if (this.state.notificatinType == 'level') {
        return <Redirect to={`/dashboard`} />
      } else if (this.state.notificatinType == 'package') {
        return (
          <Redirect
            to={`/user-subscription-details/${this.state.productName}`}
          />
        )
      }
    }

    return (
      <div>
        {this.state.isLoading && <div class="loader1"></div>}
        <Header />
        <header className="topnav" id="myTopnav">
          <Link to="/">
            <img className="logo" src={logo} />
          </Link>

          <div className="navlist" id="navlist">
            <Link className="cursor0">
              <img
                style={{
                  height: '38px',
                  position: 'relative',
                  right: '-11px',
                }}
                src={coin__icon}
              />
              <span className="button__badge">{this.state.credit}</span>
            </Link>

            <Link
              onClick={this.getNotification}
              className="cursor0"
              to="/subscriptions"
            >
              <i className="fa fa-bell-o"></i>
              <span className="button__badge">
                {this.state.notificationCount}
              </span>
              {this.state.showNotificatonbox && (
                <div id="notifications">
                  <h3>Notifications</h3>
                  <div
                    className={
                      this.state.notificationList.length > 0
                        ? ' notification-data'
                        : ''
                    }
                  >
                    {this.state.notificationList.length > 0 ? (
                      this.state.notificationList.map(notification => (
                        <div
                          onClick={e => this.redirectToPage(e, notification)}
                          className="main-nf-data"
                        >
                          <img
                            src={
                              api_Url +
                              '/UserProfile/' +
                              notification.user_id.profile_pic
                            }
                          />
                          {notification.role === 'Admin' ? (
                            <p>
                              {''}
                              {notification.notificationdata}
                            </p>
                          ) : (
                            <p>
                              {' '}
                              {notification.user_id &&
                                notification.user_id.name}{' '}
                              {''}
                              {notification.notificationdata}
                            </p>
                          )}
                          <span></span>
                        </div>
                      ))
                    ) : (
                      <div className="notification-none-data">
                        <img src={notificationicon} />
                        <p style={{ color: '#444444' }}>
                          You don't have notification
                        </p>
                      </div>
                    )}
                  </div>
                  {/* <div class="seeAll"><a href="#">See All</a></div> */}
                </div>
              )}
            </Link>
            <Link className="navoption" to="/dashboard">
              My Courses
            </Link>
            <Link className="navoption" to="/profile">
              Profile
            </Link>
            {/* <Link className="navoption" to="/profilebookatutor">Book a Tutor</Link> */}
            <Link className="navoption" to="/user-subscription-details">
              Subscription<hr className="hr"></hr>
            </Link>
            <Link className="navoption" to="/login" onClick={this.onLogout}>
              Sign Out
            </Link>
            <a href="javascript:void(0);" className="icon" id="hamburger">
              <span></span>
              <span></span>
              <span></span>
            </a>
          </div>
        </header>
        {/* <section className="banner-register">
                    <div className=""></div>
                    <div className="container">
                        <div className="row">
                            <div className="banner-section-title">
                                <h1>Register</h1>
                            </div>
                        </div>
                    </div>
                </section> */}
        <section className="bg-white p-t-60">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-12">
                <form className="tutor-form login100-form validate-form flex-sb flex-w m-t-0">
                  <div className="tutor-input-w m-t-5 select_box-tutor">
                    <p className="">Choose your learning language</p>
                    <select
                      className="input100 br-w-1"
                      name="product_id"
                      value={this.state.product_id}
                      onChange={this.handleChange}
                    >
                      <option value={'0'}>-Select language-</option>
                      {this.state.languageList.length > 0
                        ? this.state.languageList.map(language => (
                            <option key={language._id} value={language.product}>
                              {language.product}
                            </option>
                          ))
                        : null}
                    </select>{' '}
                    <span className="focus-input100"></span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-white p-tb-50 bx-shadow m-b-20">
          <div className="container">
            <div className="row">
              {this.state.product &&
                !!this.state.product.length &&
                this.state.product.map(product => (
                  <div className="col-lg-4 col-md-6 txt-center m-b-40">
                    <div className="plan-bx">
                      <h1>{product.product}</h1>
                      <p>Plan</p>
                      <br />
                      <div>
                        <h2></h2>

                        {product.result.map(result => (
                          <span>
                            {result.is_active && (
                              <span>
                                <hr />
                                <br />
                                <h3>{result.premiumname}</h3>
                                <h4>
                                  {result.inrprice === 0 ? (
                                    <span>Free</span>
                                  ) : (
                                    <span>Paid</span>
                                  )}{' '}
                                  /{result.expired_timename}
                                </h4>
                              </span>
                            )}
                          </span>
                        ))}
                        <br />
                      </div>
                    </div>
                    {/* <Link  className="btn-view" >View Details</Link> */}
                    {product.result.length > 0 ? (
                      <div style={{ marginTop: '-10px' }}>
                        <Link
                          className="btn-view"
                          to={`/user-subscription-details/${product.product}`}
                        >
                          View Details
                        </Link>
                      </div>
                    ) : (
                      <p></p>
                    )}
                    {/* 
                                     <Link  className="btn-view"  to={`/user-subscription-details/${product.product}`}>View Details</Link>  */}
                    {/* < className="btn-view"  >View Details</a> */}
                  </div>
                ))}

              {/* <div className="col-lg-4 col-md-6 txt-center m-b-40">
                                    <div className="plan-bx">
                                        <h1>Hindi</h1>
                                        <p>Plan</p>
                                        <br/>
                                        <div>
                                        <h2>Free</h2>
                                        <hr/>
                                        <br/>
                                        <h3>FOR INDIANS</h3>
                                        <h4><span>5,999₹ </span>/ year</h4>
                                        <hr/>
                                        <h3>FOR FOREIGNERS</h3>
                                        <h4><span>99 US$ </span>/ year</h4>
                                        <br/>
                                    </div>
                                </div>
                                        <a className="btn-view" href="">View Details</a>
                            </div> */}
              {/* <div className="col-lg-4 col-md-6 txt-center m-b-40">
                                    <div className="plan-bx">
                                            <h1>Hindi</h1>
                                            <p>Plan</p>
                                            <br/>
                                        <div>
                                            <h2>Free</h2>
                                            <hr/>
                                            <br/>
                                            <h3>FOR INDIANS</h3>
                                            <h4><span>5,999₹ </span>/ year</h4>
                                            <hr/>
                                            <h3>FOR FOREIGNERS</h3>
                                            <h4><span>99 US$ </span>/ year</h4>
                                            <br/>
                                        </div>
                                    </div>
                                        <a className="btn-view" href="">View Details</a>
                                </div> */}
              {/* <div className="col-lg-4 col-md-6 txt-center m-b-40">
                                        <div className="plan-bx">
                                            <h1>Hindi</h1>
                                            <p>Plan</p>
                                            <br/>
                                            <div>
                                            <h2>Free</h2>
                                            <hr/>
                                            <br/>
                                            <h3>FOR INDIANS</h3>
                                            <h4><span>5,999₹ </span>/ year</h4>
                                            <hr/>
                                            <h3>FOR FOREIGNERS</h3>
                                            <h4><span>99 US$ </span>/ year</h4>
                                            <br/>
                                        </div>
                                    </div>
                                        <a className="btn-view" href="">View Details</a>
                                </div> */}
              {/* <div className="col-lg-4 col-md-6 txt-center m-b-40">
                                    <div className="plan-bx">
                                        <h1>Hindi</h1>
                                        <p>Plan</p>
                                        <br/>
                                        <div>
                                        <h2>Free</h2>
                                        <hr/>
                                        <br/>
                                        <h3>FOR INDIANS</h3>
                                        <h4><span>5,999₹ </span>/ year</h4>
                                        <hr/>
                                        <h3>FOR FOREIGNERS</h3>
                                        <h4><span>99 US$ </span>/ year</h4>
                                        <br/>
                                    </div>
                                </div>
                                        <a className="btn-view" href="">View Details</a>
                                </div> */}
              {/* <div className="col-lg-4 col-md-6 txt-center m-b-40">
                                    <div className="plan-bx">
                                        <h1>Hindi</h1>
                                        <p>Plan</p>
                                        <br/>
                                        <div>
                                        <h2>Free</h2>
                                        <hr/>
                                        <br/>
                                        <h3>FOR INDIANS</h3>
                                        <h4><span>5,999₹ </span>/ year</h4>
                                        <hr/>
                                        <h3>FOR FOREIGNERS</h3>
                                        <h4><span>99 US$ </span>/ year</h4>
                                        <br/>
                                    </div>
                                </div>
                                    <a className="btn-view" href="">View Details</a>
                                </div> */}
            </div>
          </div>
        </section>
        {/* <Footer /> */}
      </div>
    )
  }
}

export default Prices
