import {
  Box,
  Flex,
  HStack,
  IconButton,
  Image,
  List,
  ListItem,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
  VStack,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import Select, { components } from 'react-select'
import analyticsmastery from 'src/asset/images/analyticsmastery.svg'
import analyticsmobile from 'src/asset/images/analyticsmobile.svg'
import levelIcon from 'src/asset/images/book.svg'
import cocomaacademy from 'src/asset/images/cocomaacademy.png'
import contentmarketing from 'src/asset/images/content-marketing.svg'
import googleadword from 'src/asset/images/googleadword.svg'
import graphicdesign from 'src/asset/images/graphicdesign.svg'
// import help from 'src/asset/images/help.svg'
import hindi from 'src/asset/images/hindi2x.svg'
import instagrammanager from 'src/asset/images/instagrammanager.svg'
import logoutmobile from 'src/asset/images/logoutmobile.svg'
import metaadvertising from 'src/asset/images/metaadvertising.svg'
import motiongraphics from 'src/asset/images/motiongraphics.svg'
import nepali from 'src/asset/images/nepali.png'
import notification from 'src/asset/images/notification-line.svg'
import profile from 'src/asset/images/profileicon.svg'
import profilmobile from 'src/asset/images/profilmobile.svg'
import analyticsIcon from 'src/asset/images/report.svg'
import seo from 'src/asset/images/seo.svg'
import videoediting from 'src/asset/images/videoediting.svg'
import youtubemanager from 'src/asset/images/youtubemanager.svg'
import { useAuth } from 'src/context/AuthContext'
import useSubscription from 'src/hooks/useSubscription'
import { useUser } from 'src/hooks/useUser'
import ImpersonateUser from 'src/ImpersonateUser'
import {
  GetUnitWiseProgressCount,
  setSelectedLanguage,
  setSelectedLanguageId,
} from 'src/redux/actions'
import { UIConfigHeader } from 'src/util/UIConfig'

import { getFileUrl, isMobile } from './CourseDetail/Practice/Util'

const getInitials = (firstName, lastName) => {
  let initials
  if (firstName && lastName) {
    initials = firstName.substring(0, 1) + lastName.substring(0, 1)
  } else if (firstName) {
    initials = firstName.substring(0, 1)
  } else return

  return initials.toUpperCase()
}
const LangIcon = {
  Hindi: hindi,
  Nepali: nepali,
  'Content Marketing': contentmarketing,
  'Graphic Design': graphicdesign,
  'Video Editing': videoediting,
  'YouTube Manager': youtubemanager,
  SEO: seo,
  // "UI/UX Design":uiux,
  'Motion Graphics': motiongraphics,
  'Instagram Manager': instagrammanager,
  'Google Adword': googleadword,
  'Meta Advertising': metaadvertising,
  'Analytics Mastery': analyticsmastery,
  // "Blogging":blogging,
  // "Content Writing":contentwriting,
  // "Client Servicing":clientservicing
}
const iconHover = {
  color:
    'invert(30%) sepia(85%) saturate(3384%) hue-rotate(215deg) brightness(101%) contrast(92%)',
  filter:
    'invert(30%) sepia(85%) saturate(3384%) hue-rotate(215deg) brightness(101%) contrast(92%)',
}
const getUserInfo = authInfo => {
  const firstName = authInfo?.firstName || ''
  const lastName = authInfo?.lastName || ''
  const profileIconText = getInitials(firstName, lastName)
  return {
    firstName,
    lastName,
    profileIconText,
    email: authInfo?.email,
  }
}

const ProfileMenu = () => {
  const auth = useAuth()
  const { user } = useUser()
  const { isLoading, isSubscriptionActive, manageSubscrption } =
    useSubscription()
  const [showImpersonateUser, setShowImpersonateUser] = useState(false)
  const history = useHistory()
  const onLogoutClick = async () => {
    try {
      await auth.logout()
    } catch (err) {
      console.log(err)
    } finally {
      history.push('/login')
      // @todo: Need to fix window reloading
      window.location.reload(false)
    }
  }

  const onProfileClick = () => {
    history.push('/userprofile')
  }
  const onNotificationClick = () => {
    history.push('/notification')
  }
  const onAnalyticsClick = () => {
    history.push('/analytics')
  }
  const onImpersonateUser = async () => {
    setShowImpersonateUser(true)
  }
  return (
    <>
      <Menu>
        {() => {
          const { firstName, lastName, profileIconText, email } =
            getUserInfo(user)
          return (
            <>
              <MenuButton
                as={IconButton}
                background="none"
                width="auto"
                aria-label="Profile"
                icon={
                  <Image borderRadius="full" width={'48px'} src={profile} />
                }
              />
              <MenuList>
                <MenuGroup>
                  <Flex justifyContent="center" padding="8px 12.8px">
                    <Flex flex={0.2} alignItems="center">
                      <Box
                        color="#FFFFFF"
                        bg="#000000"
                        width={8}
                        height={8}
                        borderRadius="50%"
                        padding="4px"
                      >
                        {profileIconText}
                      </Box>
                    </Flex>
                    <Flex flex={0.8} flexDirection="column">
                      <Text>{`${firstName} ${lastName}`}</Text>
                      <Text fontSize={12}>{email}</Text>
                    </Flex>
                  </Flex>
                  {isMobile && (
                    <>
                      <MenuItem color="#2469F5" onClick={onAnalyticsClick}>
                        <Image
                          boxSize="2rem"
                          src={analyticsmobile}
                          mr="12px"
                          width={3}
                        />
                        Analytics
                      </MenuItem>
                      <MenuItem color="#2469F5" onClick={onNotificationClick}>
                        <Image
                          boxSize="2rem"
                          src={notification}
                          mr="12px"
                          width={4}
                        />
                        Notifications
                      </MenuItem>
                      {/* <MenuItem color="#2469F5">
                      <Image
                        boxSize="2rem"
                        src={help} 
                        mr="12px"
                        width={4}
                      />
                      Help
                    </MenuItem> */}
                    </>
                  )}
                  <MenuItem color="#2469F5" onClick={onProfileClick}>
                    <Image
                      boxSize="2rem"
                      src={profilmobile}
                      mr="12px"
                      width={4}
                    />
                    Profile Setting
                  </MenuItem>
                  {user.role === 'ADMIN' && (
                    <MenuItem color="#2469F5" onClick={onImpersonateUser}>
                      <Image
                        boxSize="2rem"
                        src={profilmobile}
                        mr="12px"
                        width={4}
                      />
                      Impersonate User
                    </MenuItem>
                  )}
                  {!isLoading && isSubscriptionActive === true && (
                    <MenuItem color="#2469F5" onClick={manageSubscrption}>
                      Manage Subscription
                    </MenuItem>
                  )}
                  <MenuItem color="#2469F5" onClick={onLogoutClick}>
                    <Image
                      boxSize="2rem"
                      src={logoutmobile}
                      mr="12px"
                      width={4}
                    />
                    Logout
                  </MenuItem>
                </MenuGroup>
              </MenuList>
            </>
          )
        }}
      </Menu>
      {showImpersonateUser && (
        <ImpersonateUser
          isOpen={showImpersonateUser}
          setShowImpersonateUser={setShowImpersonateUser}
        ></ImpersonateUser>
      )}
    </>
  )
}

const LearnPageToolbar = () => {
  let selectedLesson = useSelector(store => store.mainReducer.selectedLesson)
  const history = useHistory()
  const onBacktoCourseClick = () => {
    history.push(`/coursedetails/${selectedLesson?.extraInfo?.unitId}`)
  }

  return (
    <Text
      onClick={onBacktoCourseClick}
      style={{
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      }}
      cursor="pointer"
    >{`< Back to ${selectedLesson?.extraInfo?.unitName || ''}`}</Text>
  )
}

export default function Toolbar() {
  const history = useHistory()
  const dispatch = useDispatch()

  let languages = useSelector(store => store.mainReducer.courses)
  let selectedLanguageId = useSelector(
    store => store.mainReducer.selectedLanguageId
  )
  let selectedLanguage = useSelector(
    store => store.mainReducer.selectedLanguage
  )
  let location = useLocation()
  const [toolbarLocation, setToolbarLocation] = useState(true)

  const { user } = useUser()

  // const [showHelpSection, setShowHelpSection] = useState(false)

  const onLogoClick = () => {
    history.push('/dashboard')
  }
  const onNotificationClick = () => {
    history.push('/notification')
  }

  // const onHelpClick = () => {
  //   setShowHelpSection(true)
  // }

  const onAnalyticsClick = () => {
    history.push('/analytics')
  }
  const iconsList = {
    Learn: levelIcon,
    Analytics: analyticsIcon,
  }

  const getLanguagesOptions = () => {
    return languages.map(item => {
      item.value = item.id
      item.label = item.course
      return item
    })
  }
  useEffect(() => {
    if (languages) {
      dispatch(setSelectedLanguage(selectedLanguage || languages[0]))
      dispatch(setSelectedLanguageId(selectedLanguageId || languages[0]?.id))
    }
  }, [languages])

  useEffect(() => {
    if (isMobile && location.pathname.includes('/introduction/course/')) {
      setToolbarLocation('learn')
    } else if (isMobile && location.pathname.includes('/course/')) {
      setToolbarLocation('practice')
    } else {
      setToolbarLocation('course')
    }
  }, [location])

  useEffect(() => {
    selectedLanguageId &&
      user &&
      dispatch(GetUnitWiseProgressCount(selectedLanguageId))
    if (
      window.location.href.toLowerCase().indexOf('login') === -1 &&
      !sessionStorage.getItem('userWatchIntroduction') &&
      !user?.watchedIntroduction
    ) {
      // setShowHelpSection(true)
    }
  }, [selectedLanguageId, user])

  const onLangChange = lang => {
    dispatch(setSelectedLanguage(lang))
    dispatch(setSelectedLanguageId(lang.id))
    history.push('/dashboard')
  }

  const renderList = item => {
    const list = []
    for (let key in UIConfigHeader.sideList) {
      list.push(
        <ListItem
          padding={1}
          key={key}
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          marginLeft={'10px'}
          marginRight={'30px'}
          justifyContent={'space-between'}
          color={item === key ? 'lightBlue' : ''}
          _hover={iconHover}
        >
          <Image
            src={iconsList[UIConfigHeader.sideList[key]]}
            alt={iconsList[UIConfigHeader.sideList[key]]}
            cursor={'pointer'}
            data-key={UIConfigHeader.sideList[key]}
            onClick={e => {
              e.currentTarget.getAttribute('data-key') === 'Learn'
                ? onLogoClick()
                : onAnalyticsClick()
            }}
            style={{
              height: '24px',
              marginLeft:
                UIConfigHeader.sideList[key] === 'Learn' ? '35px' : '20px',
              marginTop:
                UIConfigHeader.sideList[key] === 'Learn' ? '10px' : '-5px',
            }}
          />
          <Text
            fontSize={'12'}
            ml={UIConfigHeader.sideList[key] === 'Learn' ? '35px' : '20px'}
            onClick={e => {
              e.target.innerText === 'Learn'
                ? onLogoClick()
                : onAnalyticsClick()
            }}
          >
            {UIConfigHeader.sideList[key]}
          </Text>
        </ListItem>
      )
    }
    return list
  }
  const { Option } = components

  const IconOption = props => {
    return (
      <Option {...props}>
        <VStack width={'100%'} justifyContent={'center'}>
          <img
            src={getFileUrl(props?.data?.langIcon)}
            style={{ width: '45%' }}
            alt={props.data.label}
          />

          <Text paddingLeft={2} fontSize={'12px'}>
            {' '}
            {props.data.label}
          </Text>
        </VStack>
      </Option>
    )
  }
  const languageStyle = {
    option: (base, state) => ({
      ...base,
      backgroundColor: '#fff',
      color: '#000',
      justifyContent: 'center',
      display: 'flex',
    }),
    control: base => ({
      ...base,
      height: 3,
      minHeight: 3,
      fontSize: 12,
      marginTop: -16,
      marginLeft: !isMobile ? 0 : 20,
      width: !isMobile ? '100%' : '100px',
    }),
  }

  if (!user) {
    return null
  }
  const languagesDirection = !isMobile ? 'column' : 'row'
  if (window.location.href.includes('/home')) {
    return null
  }

  return (
    <>
      {toolbarLocation === 'practice' ? (
        ''
      ) : (
        <HStack
          justifyContent="space-between"
          px="18px"
          py="18px"
          position={'sticky'}
          zIndex={'1111'}
          width={'100%'}
          height={'60px'}
          background={'#fff'}
          top={0}
          border={'1px solid #d8d8d8'}
          boxShadow={'3px 2px 3px 0px rgb(167 167 167 / 20%)'}
        >
          {toolbarLocation === 'course' ? (
            <>
              <Box cursor={'pointer'} zIndex={'9999'}>
                {/* {showHelpSection && (
          <HelpSection open={true} setOpen={setShowHelpSection}></HelpSection>
        )} */}
                <Image
                  onClick={onLogoClick}
                  src={cocomaacademy}
                  sx={{
                    objectFit: 'contain',
                    height: '65px',
                    width: '150px',
                    marginTop: '10px',
                  }}
                />
              </Box>
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
                position={'absolute'}
                left={isMobile ? '80px' : '100px'}
              >
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  flexDirection={'column'}
                  w="400px"
                >
                  <img
                    src={getFileUrl(selectedLanguage?.langIcon)}
                    alt={selectedLanguage?.langIcon}
                    style={{
                      width: 30,
                      marginLeft: 50,
                      zIndex: 99,
                      marginBottom: 6,
                    }}
                  />
                  <Select
                    bg="white"
                    color="black"
                    mr="auto"
                    // menuIsOpen={true}
                    onChange={onLangChange}
                    className={'langSelect'}
                    options={getLanguagesOptions()}
                    styles={languageStyle}
                    isSearchable={false}
                    defaultValue={selectedLanguageId}
                    value={selectedLanguage}
                    components={{ Option: IconOption }}
                  ></Select>
                </Box>
                <Box w="60%">
                  <List
                    py="2"
                    spacing={3}
                    cursor={'pointer'}
                    display={'flex'}
                    flexDirection={'row'}
                  >
                    {!isMobile && renderList()}
                  </List>
                </Box>
              </Box>

              <HStack gap={3}>
                {/* <InputGroup>
          <Input placeholder="Search" borderRadius={'20px'} />
          <InputRightAddon
            as="button"
            bg="transparent linear-gradient(180deg, #2469F5 0%, #12357B 100%) 0% 0% no-repeat padding-box"
            children={<SearchIcon color="white" />}
          />
        </InputGroup> */}
                {/* {!isMobile && (
          <Image
            width={'50px'}
            color="blue"
            src={help}
            _hover={iconHover}
            style={{ cursor: 'pointer' }}
            onClick={onHelpClick}
          />
        )} */}
                {!isMobile && (
                  <Image
                    width={'50px'}
                    color="blue"
                    src={notification}
                    _hover={iconHover}
                    style={{ cursor: 'pointer' }}
                    onClick={onNotificationClick}
                  />
                )}
                {/* <Image
          width={'50px'}
          color="blue"
          src={help}
          _hover={iconHover}
          style={{ cursor: 'pointer' }}
          onClick={onHelpClick}
        /> */}

                <ProfileMenu />
              </HStack>
            </>
          ) : (
            <LearnPageToolbar />
          )}
        </HStack>
      )}
    </>
  )
}
