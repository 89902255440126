/* eslint-disable react-hooks/exhaustive-deps */

import {
  Box,
  Flex,
  Heading,
  HStack,
  Image,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import activiteImg from 'src/asset/images/activites.svg'
import chapters from 'src/asset/images/chapters.svg'
import lessonImg from 'src/asset/images/lesson.svg'
import nopreview from 'src/asset/images/nopreview.svg'
import profile2x from 'src/asset/images/profile2x.png'
import Carousel from 'src/component/Carousel'
import { getFileUrl } from 'src/component/CourseDetail/Practice/Util'
import MediaSharePanel from 'src/component/MediaSharePanel'
import { useUser } from 'src/hooks/useUser'
import {
  GetCourseLeftMenu,
  GetUsersFeedbacks,
  SetCourseLeftMenu,
} from 'src/redux/actions'
import config from 'src/util/config'
import { getCourseCreatorName } from 'src/util/helpers'

import UserFeedbacks from './UserFeedbacks'
function CourseDetails(props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const { courseId } = useParams()
  const unitWiseProgressCount = useSelector(
    store => store.mainReducer.unitWiseProgressCount
  )
  const { user: auth } = useUser()
  const { courseLeftMenu, loading, usersFeedbacks } = useSelector(
    store => store.mainReducer
  )
  const [currentCourse, setCurrentCourse] = useState(null)
  const [courseList, setCourseList] = useState()
  const [showAllCourse, setShowAllCourse] = useState(true)
  const blockName = 'courseDetails';
  useEffect(() => {
    dispatch(GetCourseLeftMenu(courseId))
    dispatch(GetUsersFeedbacks(courseId))
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    setCurrentCourse(() =>
      unitWiseProgressCount.filter(course => course.id === courseId)
    )
    setCourseList(courseLeftMenu)
  }, [unitWiseProgressCount])

  if (loading) {
    return (
      <Skeleton startColor="white" endColor="lightBlue" h="400px">
        <div>contents wrapped</div>
        <div>won't be visible</div>
      </Skeleton>
    )
  }

  const getCounts = () => {
    let counts = {
      lessons: 0,
      excercises: 0,
    }
    courseLeftMenu?.chapters?.length &&
      courseLeftMenu?.chapters.forEach(chapter => {
        counts.lessons = counts.lessons + chapter.lessons.length
        chapter.lessons.forEach(lesson => {
          counts.excercises =
            counts.excercises + lesson.linkedExerciseIds.length
        })
      })

    return counts
  }
  console.log('currentCourse ====>', currentCourse)
  const onBackClick = () => {
    history.go(-1)
  }
  const iconStyle = { width: '20px', height: '20px' }

  let thumbnailImage = nopreview
  if (currentCourse && currentCourse[0] && currentCourse[0].videoThumbnail) {
    thumbnailImage = getFileUrl(currentCourse[0].videoThumbnail)
  }

  const usersFeedbacksItems =
    usersFeedbacks?.length &&
    usersFeedbacks.map(userFeedback => (
      <UserFeedbacks
        key={userFeedback.id.toString()}
        userFeedback={userFeedback}
      />
    ))
  const onSeeAllBtnClick = (key, showAllCourse) => {
    setCourseList(
      courseLeftMenu.chapters.filter(item => item.chapterId === key.chapterId)
    )
    setShowAllCourse(showAllCourse)
  }
  return (
    <>
      <Flex className={blockName} w="95%" mx="auto" gap="2" paddingTop={'20px'}>
        <button
          onClick={onBackClick}
          style={{ fontSize: '15px', color: '#646464' }}
          // disabled={disableLeftArrow}
        >
          &lt; Back to Learn
        </button>
      </Flex>

      <Flex
        direction={['column', 'column', 'row']}
        w="95%"
        mx="auto"
        gap="2"
        py="20px"
      >
        <Box flex="2">
          {thumbnailImage ? (
            <Image src={thumbnailImage} />
          ) : (
            currentCourse?.[0]?.video && (
              <Box>
                <video
                  width="420"
                  height="250"
                  autoPlay={true}
                  controls
                  src={`${config.API_URI}download/${currentCourse[0]?.video}`}
                >
                  Your browser does not support the video tag.
                </video>
              </Box>
            )
          )}
        </Box>
        <Stack flex="3" spacing="2">
          <Heading fontWeight={'bolder'} fontSize={'24px'} variant="medium">
            {/* {currentCourse && currentCourse[0]?.difficultyLevel} guide to{' '} */}
            {currentCourse && currentCourse[0]?.name}
          </Heading>
          <Text>{currentCourse && currentCourse[0]?.description}</Text>
          <HStack>
            <img
              src={profile2x}
              alt=""
              style={{ width: '32px', height: '32px' }}
            ></img>
            <Text fontSize="xs" whiteSpace="nowrap">
              {getCourseCreatorName(auth)}
            </Text>
          </HStack>
          <HStack>
            <img alt="" src={chapters} style={iconStyle}></img>
            <Text fontSize="xs" whiteSpace="nowrap">
              {courseLeftMenu?.chapters?.length} chapters
            </Text>
            <img alt="" src={lessonImg} style={iconStyle}></img>
            <Text fontSize="xs" whiteSpace="nowrap">
              {getCounts().lessons} lessons
            </Text>
            <img alt="" src={activiteImg} style={iconStyle}></img>
            <Text fontSize="xs" whiteSpace="nowrap">
              {getCounts().excercises} Activity
            </Text>
          </HStack>
          <HStack>
            {' '}
            <MediaSharePanel />{' '}
            <Text fontSize="xs" whiteSpace="nowrap">
              Share
            </Text>
          </HStack>

          {/* <HStack>
            <Select bg="lightBlue" color="white" placeholder="Chapters" />
            <Select placeholder="Lesson" />
          </HStack> */}
        </Stack>
      </Flex>
      <Flex direction="row" mb={4} w="95%" mx="auto">
        <Stack position="relative" overflow="hidden">
          {!showAllCourse &&
            courseList?.map((chapter, index) => {
              return (
                <Carousel
                  // todo: this static id will be removed later
                  navUrl={`introduction/course/5d85ff2bedef344fc029f31c/chapter/${chapter?.chapterId}/unit/${courseId}/lesson`}
                  key={'courseList' + index}
                  auth={auth}
                  showAllCourse={showAllCourse}
                  courseKey={chapter}
                  seeAllBtnFn={onSeeAllBtnClick}
                  hasStartButton={false}
                  title={chapter.chapterName}
                  data={chapter.lessons}
                  dataKeyName="name"
                  showProgress={true}
                />
              )
            })}
          {showAllCourse &&
            courseLeftMenu?.chapters?.map((chapter, index) => {
              return (
                <Carousel
                  // todo: this static id will be removed later
                  navUrl={`introduction/course/5d85ff2bedef344fc029f31c/chapter/${chapter?.chapterId}/unit/${courseId}/lesson`}
                  key={'courseLeftMenu' + index}
                  auth={auth}
                  showAllCourse={true}
                  courseKey={chapter}
                  seeAllBtnFn={onSeeAllBtnClick}
                  hasStartButton={false}
                  title={chapter.chapterName}
                  data={chapter.lessons}
                  dataKeyName="name"
                  showProgress={true}
                />
              )
            })}
        </Stack>
      </Flex>
      <Flex direction="row" w="95%" mx="auto">
        {usersFeedbacks?.length ? (
          <Stack gap={2}>
            <Heading size={'md'}>Student reviews</Heading>
            {usersFeedbacksItems}
          </Stack>
        ) : (
          <Heading size={'md'}>No reviews</Heading>
        )}
      </Flex>
    </>
  )
}

export default CourseDetails
