import { useState } from 'react'
import { useHistory } from 'react-router'
import sectionfiveone from 'src/asset/images/sectionfive/sectionfiveone.jpg'
import sectionfivethree from 'src/asset/images/sectionfive/sectionfivethree.jpg'
import sectionfivetwo from 'src/asset/images/sectionfive/sectionfivetwo.jpg'

import { isMobile } from '../CourseDetail/Practice/Util'
import ImageSlider from './ImageSlider'
import {
  btnHover,
  fontMain,
  fontSecond,
  hyperTextColor,
  imageFlex,
  imagelg,
  imagemd,
  imageSx,
  textPanelWidth,
} from './util'

const { Box, Flex, Text, Button } = require('@chakra-ui/react')
const selctionFiveData = [
  {
    image: sectionfiveone,
  },
  {
    image: sectionfivetwo,
  },
  {
    image: sectionfivethree,
  },
]
const height = '40px'
const width = isMobile ? '180px' : '150px'
const SectionFive = () => {
  const history = useHistory()
  const [autoPlay, setAutoPlay] = useState(false)
  const onSignUp = () => {
    history.push({
      pathname: '/register',
      state: {
        comingFromHome: true,
      },
    })
  }
  return (
    <Flex
      flex={'1 0 100%'}
      section={'1'}
      bg="#F6F7FB"
      direction={['column', 'column', 'row']}
      w={'100%'}
      px={[5, 5, 5]}
      py={5}
      justifyContent={'center'}
    >
      <Box
        textAlign={['center', 'left', 'left']}
        w={['90%', '100%', '30%']}
        flex={imageFlex}
      >
        <ImageSlider
          px={[10, 0, 0]}
          sx={[imageSx, imagemd, imagelg]}
          borderRadius={'15px'}
          slides={selctionFiveData}
          autoPlay={autoPlay}
          onClick={() => {
            setAutoPlay(!autoPlay)
          }}
        ></ImageSlider>
      </Box>
      <Box px={[10, 1, 10]} py={[10, 2, 1]} w={['90%', '100%', '58%']}>
        <Text
          bg="{hyperTextColor}"
          //color={'#fff'}
          display={'inline'}
          fontWeight={900}
          fontSize={fontMain}
        >
          {'Accelerate your learning.'}
        </Text>
        {!isMobile && (
          <Text
            textAlign={'left'}
            pt={3}
            fontSize={fontSecond}
            fontWeight={600}
          >
            {
              'Learning only literal meaning is limited and injustice to your time invested. '
            }
          </Text>
        )}
        {isMobile && (
          <Text
            textAlign={'left'}
            pt={3}
            fontSize={fontSecond}
            fontWeight={600}
          >
            {
              'Learning only literal meaning is limited and injustice to your time invested.'
            }
          </Text>
        )}
        {!isMobile && (
          <Text
            textAlign={'left'}
            pb={0}
            fontSize={fontSecond}
            fontWeight={600}
          >
            {
              'In Cocoma Academy we teach you more than just words, sentences, or grammar rules.'
            }
          </Text>
        )}
        {isMobile && (
          <>
            <Text
              textAlign={'left'}
              pt={3}
              fontSize={fontSecond}
              fontWeight={600}
            >
              {
                'In Cocoma Academy we teach you more than just words, sentences, or grammar rules. We will not only teach you just the word for a man or woman rather how native men or women look, what they wear, what dresses are, and many other distinctive features visually compiled to give you holistic meaning.'
              }
            </Text>
          </>
        )}
        {!isMobile && (
          <>
            <Text
              textAlign={'left'}
              pt={3}
              fontSize={fontSecond}
              fontWeight={600}
            >
              {
                'We will not only teach you just the word for a man or woman rather  how '
              }
            </Text>
            <Text
              textAlign={'left'}
              pb={0}
              fontSize={fontSecond}
              fontWeight={600}
            >
              {
                ' native men or women look, what they wear, what dresses are, and many other'
              }
            </Text>
            <Text
              textAlign={'left'}
              pb={0}
              fontSize={fontSecond}
              fontWeight={600}
            >
              {
                'distinctive features visually compiled to give you holistic meaning.'
              }
            </Text>
          </>
        )}
        <Box pt={[5, 5, 3]} textAlign={['center', 'center', 'inherit']}>
          <Button
            color="#707070"
            w={width}
            h={height}
            //bg={btnBg}
            style={{
              border: '1px solid #707070',
              backgroundColor: '#fff',
            }}
            borderRadius={'5px'}
            _hover={btnHover}
            onClick={onSignUp}
          >
            Sign up Now
          </Button>
        </Box>
      </Box>
    </Flex>
  )
}

export default SectionFive
