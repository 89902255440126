import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'

import { useUser } from './hooks/useUser'
import { impersonateUser } from './redux/actions'
const btnBg =
  'transparent linear-gradient(180deg, #2469F5 0%, #12357B 100%) 0% 0% no-repeat padding-box'
const btnHover = {
  color: '#fff',
  background:
    'transparent linear-gradient(180deg, #2469F5 0%, #12357B 100%) 0% 0% no-repeat padding-box',
}
const ImpersonateUser = ({ isOpen, setShowImpersonateUser }) => {
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const toast = useToast()
  const { user } = useUser()
  const history = useHistory();
  const onImpersonateUser = async () => {
    let invalid = false
    let message = 'Please enter user email address'

    if (email.trim().length === 0) {
      invalid = true
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      invalid = true
      message = 'Please enter a valid email address'
    }
    if (invalid) {
      toast({
        status: 'warning',
        title: message,
        position: 'bottom-left',
        isClosable: true,
      })
    } else {
      const authInfo = await dispatch(
        impersonateUser({
          email: email,
          userId: user._id,
        })
      )
      if (authInfo.status === 400) {
        toast({
          status: 'warning',
          title: authInfo.error,
          position: 'bottom-left',
          isClosable: true,
        })
      } else {
        setShowImpersonateUser(false)
        history.push('/dashboard');
        window.location.reload(false)
        console.log('authInfo', authInfo)
      }
    }
  }
  const onClose = () => {
    setShowImpersonateUser(false)
  }
  return (
    <>
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Impersonate User</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              value={email}
              placeholder="Enter user email address"
              onChange={el => {
                setEmail(el.target.value)
              }}
            />
          </ModalBody>
          <ModalFooter justifyContent={'space-between'} py={5}>
            <Button
              onClick={onClose}
              color="#707070"
              w={'150px'}
              h={'40px'}
              //bg={btnBg}
              style={{
                border: '1px solid #707070',
                backgroundColor: '#fff',
              }}
            >
              Close
            </Button>
            <Button
              bg={btnBg}
              w={'150px'}
              h={'40px'}
              onClick={onImpersonateUser}
            >
              Switch User
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ImpersonateUser
