import React from 'react'
import Header from './Header'
import Footer from './Footer'

import '../fonts/fonts/font-awesome-4.7.0/css/font-awesome.min.css'
import '../fonts/work-sans-cufonfonts-webfont/style.css'
import { element } from 'prop-types'
import { blockStatement, pipelineBareFunction } from '@babel/types'
import { Link } from 'react-router-dom'
import * as myConstClass from './constants'
const api_Url = myConstClass.api
//const api_Url = 'http://35.244.3.174:4000';
class Subscription extends React.Component {
  constructor() {
    super()
    this.state = {
      selected: 'profile',
      staticImg: true,
      file: null,
      country: '',
      date: '',
      token: '',
      name: '',
      surname: '',
      email: '',
      dob: '',
      profile_pic: '',
      country_id: '',
      register_date: '',
      type: '',
      displaytext: 'hide_block',
      nameErrorMessage: '',
      surnameErrorMessage: '',
      emailErrorMessage: '',
      passwordErrorMessage: '',
      birthdayErrorMessage: '',
      countryErrorMessage: '',
      imgErrorMessage: '',
      subscriptonList: [],
      result: [],
      languageList: [],
      product_id: '',
      SearchStr: '',
      planName: '',
      isIndian: false,
      isLoading: true,
    }
  }

  handleChange = e => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        SearchStr: e.target.value,
      },
      () => {
        this.getProductList()
      }
    )
  }

  //get palan name from url parms
  dataSource(props) {
    var planName = this.props.match.params.plan_name
    this.setState({ planName: planName }, () => {
      this.getsubscriptionList()
    })
  }

  componentWillMount() {
    this.dataSource()
    let token = window.sessionStorage.getItem('token')
    if (token) {
      // return <Redirect to="/dashboard" />
      // return <Link to="/dashboard" />
      // window.history.push('/dashboard');
      // window.history.forward();
      this.props.history.push('/dashboard')
      // window.history.go(1);
      // this.props.context.history.push('/dashboard');
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    this.getLanguageList()
    fetch('https://ipapi.co/json/', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())

      .then(response => {
        if (response.country == 'IN') {
          this.setState({ isIndian: true })
        } else {
          this.setState({ isIndian: false })
        }
      })
    //this.getProductList();
  }

  getsubscriptionList() {
    fetch(api_Url + `/product/getproductlist`)
      .then(response => response.json())

      .then(data => {
        if (data.status === 200) {
          this.setState({
            subscriptonList: data.product,
          })
        } else {
          this.setState({
            subscriptonList: [],
          })
        }
        this.setState({ isLoading: false })
      })
  }

  displayPriceInr(list) {
    var digit = list.inrprice.toString()
    if (digit.length > 3) {
      var inprice = Intl.NumberFormat('en-IN').format(digit)
      var splitPrice = inprice.split(',')
      var fristPrice = splitPrice[0]
      var scondPrice = ',' + splitPrice[1]
    } else {
      var fristPrice = digit
    }
    return (
      <span
        style={{
          borderBottom: 'none',
          padding: '0',
          display: 'inline-flex',
        }}
      >
        <h1 style={{ fontSize: '60px', color: '#ff8800' }}>{fristPrice}</h1>
        <p
          style={{
            marginTop: '20px',
            marginLeft: '7px',
            fontSize: '18px',
            fontWeight: '600',
          }}
        >
          {scondPrice}₹
        </p>
        <span className={digit.length <= 3 ? 'price-2' : 'price'}>
          /{list.expired_timename}
        </span>
      </span>
    )
  }
  displayPriceFr(list) {
    var digit = list.fprice.toString()
    if (digit.length > 3) {
      var inprice = Intl.NumberFormat('en-IN').format(digit)
      var splitPrice = inprice.split(',')
      var fristPrice = splitPrice[0]
      var scondPrice = ',' + splitPrice[1]
    } else {
      var fristPrice = digit
    }
    return (
      <span
        style={{
          borderBottom: 'none',
          padding: '0',
          display: 'inline-flex',
        }}
      >
        <h1 style={{ fontSize: '60px', color: '#ff8800' }}>{fristPrice}</h1>
        <p
          style={{
            marginTop: '20px',
            marginLeft: '7px',
            fontSize: '18px',
            fontWeight: '600',
          }}
        >
          {scondPrice}US$
        </p>
        <span className={digit.length <= 3 ? 'price-fr-2' : 'price-fr'}>
          /{list.expired_timename}
        </span>
      </span>
    )
  }

  renderplan(list) {
    if (list.isfree == true && list.is_active) {
      return (
        <li class="main-list-item">
          <div class="a" href="">
            <div class="plan-bx">
              <h1>{list.premiumname}</h1>
              <p>Free</p>
              <ul>
                {list.entertainmentvideo === 'false' ? (
                  <li>
                    <p>X</p>
                  </li>
                ) : (
                  <li>
                    <p>{list.entertainmentvideo}</p>
                  </li>
                )}

                {list.accesslesson === 'false' ? (
                  <li>
                    <p>X</p>
                  </li>
                ) : (
                  <li>
                    <p>{list.accesslesson}</p>
                  </li>
                )}

                {list.exerciselesson === 'false' ? (
                  <li>
                    <p>X</p>
                  </li>
                ) : (
                  <li>
                    <p>{list.exerciselesson}</p>
                  </li>
                )}
                {list.lsystem === 'false' ? (
                  <li>
                    <p>X</p>
                  </li>
                ) : (
                  <li>
                    <p>{list.lsystem}</p>
                  </li>
                )}
                {list.vocabulary === 'false' ? (
                  <li>
                    <p>X</p>
                  </li>
                ) : (
                  <li>
                    <p>{list.vocabulary}</p>
                  </li>
                )}
                {list.materialdownload === 'false' ? (
                  <li>
                    <p>X</p>
                  </li>
                ) : (
                  <li>
                    <p>{list.materialdownload}</p>
                  </li>
                )}
                {list.exclusivewebinars === 'false' ? (
                  <li>
                    <p>X</p>
                  </li>
                ) : (
                  <li>
                    <p>{list.exclusivewebinars}</p>
                  </li>
                )}
                {list.tutoring === 'false' ? (
                  <li>
                    <p>X</p>
                  </li>
                ) : (
                  <li>
                    <p>{list.tutoring}</p>
                  </li>
                )}
                {list.conversation === 'false' ? (
                  <li>
                    <p>X</p>
                  </li>
                ) : (
                  <li>
                    <p>{list.conversation}</p>
                  </li>
                )}
                {/* {(list.subscribtion==='false')?<li><p>X</p></li>:<li><p>{list.subscribtion}</p></li>} */}
                {list.isfree == true ? (
                  <li
                    style={{
                      borderBottom: 'none',
                      height: '79px',
                    }}
                  ></li>
                ) : (
                  <li></li>
                )}
              </ul>
            </div>
            <div style={{ marginTop: '-10px' }}>
              <Link class="btn-view" to="/register">
                Register
              </Link>{' '}
            </div>
            {/* {(list.user_id[0].subscridtion ==="true" && (list.user_id[0].type ==="indian" ||list.user_id[0].type ==="foreigner") ) ? <a class="subscribe-btn-view" style={{backgroundColor:'#aacc88'}} >Subscribed</a>:<a class="subscribe-btn-view" onClick ={()=>this.subscribePlanList(list,"indian")} >Buy Now</a> } */}
            {/* //{list.isfree===true?null:(list.user_id[0].subscridtion ==="true" && list.user_id[0].type ==="foreigner" )? <a class="subscribe-btn-view" style={{backgroundColor:'#aacc88'}} >Subscribed</a>:<a class="subscribe-btn-view" onClick ={()=>this.subscribePlanList(list,"foreigner")} >Subscribe</a> } */}
            {/* <Link class="btn-view" to ="/register" >Register</Link> */}
          </div>
        </li>
      )
    } else if (list.is_active) {
      return (
        <li className="main-list-item">
          <li className="main-list-item">
            <div className="a" href="">
              <div className="plan-bx">
                <h1>{list.premiumname}</h1>
                <p>FOR INDIANS/FOREIGNERS</p>
                <ul>
                  {list.entertainmentvideo === 'false' ? (
                    <li>
                      <p>X</p>
                    </li>
                  ) : (
                    <li>
                      <p>{list.entertainmentvideo}</p>
                    </li>
                  )}

                  {list.accesslesson === 'false' ? (
                    <li>
                      <p>X</p>
                    </li>
                  ) : (
                    <li>
                      <p>{list.accesslesson}</p>
                    </li>
                  )}

                  {list.exerciselesson === 'false' ? (
                    <li>
                      <p>X</p>
                    </li>
                  ) : (
                    <li>
                      <p>{list.exerciselesson}</p>
                    </li>
                  )}
                  {list.lsystem === 'false' ? (
                    <li>
                      <p>X</p>
                    </li>
                  ) : (
                    <li>
                      <p>{list.lsystem}</p>
                    </li>
                  )}
                  {list.vocabulary === 'false' ? (
                    <li>
                      <p>X</p>
                    </li>
                  ) : (
                    <li>
                      <p>{list.vocabulary}</p>
                    </li>
                  )}
                  {list.materialdownload === 'false' ? (
                    <li>
                      <p>X</p>
                    </li>
                  ) : (
                    <li>
                      <p>{list.materialdownload}</p>
                    </li>
                  )}
                  {list.exclusivewebinars === 'false' ? (
                    <li>
                      <p>X</p>
                    </li>
                  ) : (
                    <li>
                      <p>{list.exclusivewebinars}</p>
                    </li>
                  )}
                  {list.tutoring === 'false' ? (
                    <li>
                      <p>X</p>
                    </li>
                  ) : (
                    <li>
                      <p>{list.tutoring}</p>
                    </li>
                  )}
                  {list.conversation === 'false' ? (
                    <li>
                      <p>X</p>
                    </li>
                  ) : (
                    <li>
                      <p>{list.conversation}</p>
                    </li>
                  )}
                  {/* {(list.subscribtion==='false')?<li><p>X</p></li>:<li><p>{list.subscribtion}</p></li>} */}
                  {list.isfree == false ? (
                    <li
                      style={{
                        borderBottom: 'none',
                        padding: '0',
                        display: 'inline-flex',
                      }}
                    >
                      {this.state.isIndian == true &&
                        this.displayPriceInr(list)}
                      {this.state.isIndian == false &&
                        this.displayPriceFr(list)}

                      {/* <div  style = {{display:'inline-flex'}}><h1 style ={{fontWeight:'700', color:'#ff8800', fontSize:'40px', }}>{list.inrprice}</h1> <p>₹<span> 2 Year</span></p></div> */}
                    </li>
                  ) : null}
                  {/* {product.result.isfree===true?null:product.result.isfree === false?<td>{product.result.inrprice}US$</td>: <td>X</td> } */}
                </ul>
              </div>
              <div style={{ marginTop: '-10px' }}>
                <Link class="btn-view" to="/register">
                  Register
                </Link>
              </div>
              {/* <Link class="btn-view" to ="/register" >Register</Link> */}
            </div>
          </li>
          {/* <li className ="main-list-item" >
 <div className="a" href="">
 <div className="plan-bx">
 <h1>{list.premiumname}</h1>
 <p>FOR FOREIGNERS</p>           
 <ul>
 {(list.entertainmentvideo==='false')?<li><p>X</p></li>:<li><p>{list.entertainmentvideo}</p></li>}
 
 {(list.accesslesson==='false')?<li><p>X</p></li>:<li><p>{list.accesslesson}</p></li>}
 
 {(list.exerciselesson==='false')?<li><p>X</p></li>:<li><p>{list.exerciselesson}</p></li>}
 {(list.lsystem==='false')?<li><p>X</p></li>:<li><p>{list.lsystem}</p></li>}
 {(list.vocabulary==='false')?<li><p>X</p></li>:<li><p>{list.vocabulary}</p></li>}
 {(list.materialdownload==='false')?<li><p>X</p></li>:<li><p>{list.materialdownload}</p></li>}
 {(list.exclusivewebinars==='false')?<li><p>X</p></li>:<li><p>{list.exclusivewebinars}</p></li>}
 {(list.tutoring==='false')?<li><p>X</p></li>:<li><p>{list.tutoring}</p></li>}
 {(list.conversation==='false')?<li><p>X</p></li>:<li><p>{list.conversation}</p></li>}
 {(list.isfree==false)?<li style ={{borderBottom: 'none',padding:'0', display:'inline-flex'}}>.
 {this.displayPriceFr(list)}
 </li>:null}
 </ul>
 </div>
 <div style ={{marginTop:'-10px'}}>
 <Link className="btn-view" to ="/register" >Register</Link> </div>
 </div>
 </li> */}
        </li>
      )
    }
  }
  paidplain(list) {
    if (list.isfree === true) {
      return (
        <li className="main-list-item">
          <div className="a" href="">
            <div className="plan-bx">
              <h1>{list.premiumname}</h1>
              <p>Plan</p>
              <ul>
                {list.entertainmentvideo === 'false' ? (
                  <li>
                    <p>X</p>
                  </li>
                ) : (
                  <li>
                    <p>{list.entertainmentvideo}</p>
                  </li>
                )}

                {list.accesslesson === 'false' ? (
                  <li>
                    <p>X</p>
                  </li>
                ) : (
                  <li>
                    <p>{list.accesslesson}</p>
                  </li>
                )}

                {list.exerciselesson === 'false' ? (
                  <li>
                    <p>X</p>
                  </li>
                ) : (
                  <li>
                    <p>{list.exerciselesson}</p>
                  </li>
                )}
                {list.lsystem === 'false' ? (
                  <li>
                    <p>X</p>
                  </li>
                ) : (
                  <li>
                    <p>{list.lsystem}</p>
                  </li>
                )}
                {list.vocabulary === 'false' ? (
                  <li>
                    <p>X</p>
                  </li>
                ) : (
                  <li>
                    <p>{list.vocabulary}</p>
                  </li>
                )}
                {list.materialdownload === 'false' ? (
                  <li>
                    <p>X</p>
                  </li>
                ) : (
                  <li>
                    <p>{list.materialdownload}</p>
                  </li>
                )}
                {list.exclusivewebinars === 'false' ? (
                  <li>
                    <p>X</p>
                  </li>
                ) : (
                  <li>
                    <p>{list.exclusivewebinars}</p>
                  </li>
                )}
                {list.tutoring === 'false' ? (
                  <li>
                    <p>X</p>
                  </li>
                ) : (
                  <li>
                    <p>{list.tutoring}</p>
                  </li>
                )}
                {list.conversation === 'false' ? (
                  <li>
                    <p>X</p>
                  </li>
                ) : (
                  <li>
                    <p>{list.conversation}</p>
                  </li>
                )}
                {list.subscribtion === 'false' ? (
                  <li>
                    <p>X</p>
                  </li>
                ) : (
                  <li>
                    <p>{list.subscribtion}</p>
                  </li>
                )}
              </ul>
            </div>
            <div style={{ marginTop: '-10px' }}>
              <Link className="btn-view" to="/register">
                Register
              </Link>
            </div>
          </div>
        </li>
      )
    } else {
      return (
        <div>
          <li className="main-list-item">
            <div className="a" href="">
              <div className="plan-bx">
                <h1>{list.premiumname}</h1>
                <p>Plan</p>
                <ul>
                  {list.entertainmentvideo === 'false' ? (
                    <li>
                      <p>X</p>
                    </li>
                  ) : (
                    <li>
                      <p>{list.entertainmentvideo}</p>
                    </li>
                  )}

                  {list.accesslesson === 'false' ? (
                    <li>
                      <p>X</p>
                    </li>
                  ) : (
                    <li>
                      <p>{list.accesslesson}</p>
                    </li>
                  )}

                  {list.exerciselesson === 'false' ? (
                    <li>
                      <p>X</p>
                    </li>
                  ) : (
                    <li>
                      <p>{list.exerciselesson}</p>
                    </li>
                  )}
                  {list.lsystem === 'false' ? (
                    <li>
                      <p>X</p>
                    </li>
                  ) : (
                    <li>
                      <p>{list.lsystem}</p>
                    </li>
                  )}
                  {list.vocabulary === 'false' ? (
                    <li>
                      <p>X</p>
                    </li>
                  ) : (
                    <li>
                      <p>{list.vocabulary}</p>
                    </li>
                  )}
                  {list.materialdownload === 'false' ? (
                    <li>
                      <p>X</p>
                    </li>
                  ) : (
                    <li>
                      <p>{list.materialdownload}</p>
                    </li>
                  )}
                  {list.exclusivewebinars === 'false' ? (
                    <li>
                      <p>X</p>
                    </li>
                  ) : (
                    <li>
                      <p>{list.exclusivewebinars}</p>
                    </li>
                  )}
                  {list.tutoring === 'false' ? (
                    <li>
                      <p>X</p>
                    </li>
                  ) : (
                    <li>
                      <p>{list.tutoring}</p>
                    </li>
                  )}
                  {list.conversation === 'false' ? (
                    <li>
                      <p>X</p>
                    </li>
                  ) : (
                    <li>
                      <p>{list.conversation}</p>
                    </li>
                  )}
                  {list.subscribtion === 'false' ? (
                    <li>
                      <p>X</p>
                    </li>
                  ) : (
                    <li>
                      <p>{list.subscribtion}</p>
                    </li>
                  )}
                </ul>
              </div>
              <Link className="btn-view" to="/register">
                Register
              </Link>
            </div>
          </li>
          <li className="main-list-item">
            <div className="a" href="">
              <div clclassNameass="plan-bx">
                <h1>{list.premiumname}</h1>
                <p>Plan</p>
                <ul>
                  {list.entertainmentvideo === 'false' ? (
                    <li>
                      <p>X</p>
                    </li>
                  ) : (
                    <li>
                      <p>{list.entertainmentvideo}</p>
                    </li>
                  )}

                  {list.accesslesson === 'false' ? (
                    <li>
                      <p>X</p>
                    </li>
                  ) : (
                    <li>
                      <p>{list.accesslesson}</p>
                    </li>
                  )}

                  {list.exerciselesson === 'false' ? (
                    <li>
                      <p>X</p>
                    </li>
                  ) : (
                    <li>
                      <p>{list.exerciselesson}</p>
                    </li>
                  )}
                  {list.lsystem === 'false' ? (
                    <li>
                      <p>X</p>
                    </li>
                  ) : (
                    <li>
                      <p>{list.lsystem}</p>
                    </li>
                  )}
                  {list.vocabulary === 'false' ? (
                    <li>
                      <p>X</p>
                    </li>
                  ) : (
                    <li>
                      <p>{list.vocabulary}</p>
                    </li>
                  )}
                  {list.materialdownload === 'false' ? (
                    <li>
                      <p>X</p>
                    </li>
                  ) : (
                    <li>
                      <p>{list.materialdownload}</p>
                    </li>
                  )}
                  {list.exclusivewebinars === 'false' ? (
                    <li>
                      <p>X</p>
                    </li>
                  ) : (
                    <li>
                      <p>{list.exclusivewebinars}</p>
                    </li>
                  )}
                  {list.tutoring === 'false' ? (
                    <li>
                      <p>X</p>
                    </li>
                  ) : (
                    <li>
                      <p>{list.tutoring}</p>
                    </li>
                  )}
                  {list.conversation === 'false' ? (
                    <li>
                      <p>X</p>
                    </li>
                  ) : (
                    <li>
                      <p>{list.conversation}</p>
                    </li>
                  )}
                  {list.subscribtion === 'false' ? (
                    <li>
                      <p>X</p>
                    </li>
                  ) : (
                    <li>
                      <p>{list.subscribtion}</p>
                    </li>
                  )}
                </ul>
              </div>
              <div style={{ marginTop: '-10px' }}>
                <Link className="btn-view" to="/register">
                  Register
                </Link>
              </div>
            </div>
          </li>
        </div>
      )
    }
  }

  getLanguageList() {
    fetch(api_Url + `/product/getproductlist`)
      .then(response => response.json())

      .then(data => {
        this.setState({
          languageList: data.product,
        })
      })
  }

  render() {
    return (
      <div>
        {this.state.isLoading && <div className="loader1"></div>}
        <Header />
        <section className="banner-register">
          <div className=""></div>
          <div className="container">
            <div className="row">
              <div className="banner-section-title">
                <h1>Register</h1>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-white p-tb-50 bx-shadow m-b-20">
          <div className="packg-container">
            <div className="row">
              <div className="col-lg-3 col-md-6 txt-center m-b-40">
                <ul className="plan-list-item">
                  <li>
                    <p>Hundreds of entertainment vídeos </p>
                  </li>
                  <li>
                    <p>Full access to all lessons</p>
                  </li>
                  <li>
                    <p>Exercises following each lesson</p>
                  </li>
                  <li>
                    <p>4L System</p>
                  </li>
                  <li>
                    <p>Vocabulary</p>
                  </li>
                  <li>
                    <p>Material for downloads</p>
                  </li>
                  <li>
                    <p>Exclusive webinars</p>
                  </li>
                  <li>
                    <p>Tutoring</p>
                  </li>
                  <li>
                    <p>Conversation after finishing level</p>
                  </li>
                  <li>
                    <p>Yearly subscription</p>
                  </li>
                </ul>
              </div>

              <div className="col-lg-9">
                <ul className="scroll-box">
                  {this.state.subscriptonList &&
                    !!this.state.subscriptonList.length &&
                    this.state.subscriptonList.map(product =>
                      product.result.map(
                        list => this.renderplan(list)
                        // list.isfree===true &&
                        // <li class ="main-list-item" >
                        // <div class="a" href="">
                        //      <div class="plan-bx">
                        //      <h1>{list.premiumname}</h1>
                        //      <p>Plan</p>
                        //      <ul>

                        // {(list.entertainmentvideo==='false')?<li><p>X</p></li>:<li><p>{list.entertainmentvideo}</p></li>}

                        // {(list.accesslesson==='false')?<li><p>X</p></li>:<li><p>{list.accesslesson}</p></li>}

                        // {(list.exerciselesson==='false')?<li><p>X</p></li>:<li><p>{list.exerciselesson}</p></li>}
                        // {(list.lsystem==='false')?<li><p>X</p></li>:<li><p>{list.lsystem}</p></li>}
                        // {(list.vocabulary==='false')?<li><p>X</p></li>:<li><p>{list.vocabulary}</p></li>}
                        // {(list.materialdownload==='false')?<li><p>X</p></li>:<li><p>{list.materialdownload}</p></li>}
                        // {(list.exclusivewebinars==='false')?<li><p>X</p></li>:<li><p>{list.exclusivewebinars}</p></li>}
                        // {(list.tutoring==='false')?<li><p>X</p></li>:<li><p>{list.tutoring}</p></li>}
                        // {(list.conversation==='false')?<li><p>X</p></li>:<li><p>{list.conversation}</p></li>}
                        // {(list.subscribtion==='false')?<li><p>X</p></li>:<li><p>{list.subscribtion}</p></li>}
                        //      </ul>
                        //      </div>
                        // <Link class="btn-view" to ="/register" >Register</Link>
                        // </div>

                        // </li>
                      )
                    )}
                </ul>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    )
  }
}

export default Subscription
