import { EditIcon } from '@chakra-ui/icons'
import {
  Box,
  Flex,
  HStack,
  Icon,
  Image,
  Progress,
  Stack,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import { isNil } from 'lodash'
import { NavLink } from 'react-router-dom'
import activiteImg from 'src/asset/images/activites.svg'
import activiteWhiteImg from 'src/asset/images/activities_white.svg'
import chaptersImg from 'src/asset/images/chapters.svg'
import lessonImg from 'src/asset/images/lesson.svg'
import { getAdminAppUrl } from 'src/util/getAdminAppUrl'
import { getCourseCreatorName } from 'src/util/helpers'

import clock from '../asset/images/clock.svg'
import playicon from '../asset/images/play.svg'
import profile from '../asset/images/profile2x.png'

const RenderNavLink = props => {
  return props.hasStartButton || props.url ? (
    <NavLink to={props.url}>{props.children}</NavLink>
  ) : (
    <>{props.children}</>
  )
}

export default function ImageCard({
  src,
  name,
  id,
  auth,
  navUrl,
  hasStartButton,
  hrs,
  minutes,
  progress,
  contentEditable,
  isAdmin,
  showProgress,
  continueLearning,
  lessonCount,
  activitiesCount,
  chapterCount,
  linkedExerciseCount,
  linkedVocabularyCount,
  seconds,
}) {
  const property = {
    imageAlt: '',
    title: name || 'Untitled',
  }
  let url = continueLearning ? `/${navUrl}` : `/${navUrl}/${id}`

  const onCourseEditClick = id => {
    window.open(getAdminAppUrl() + `app/units?_id=${id}`, '_blank')
  }

  const showExAndVocb =
    !isNil(linkedExerciseCount) || !isNil(linkedVocabularyCount)
  hrs = hrs === '0' ? '00' : hrs < 9 ? '0' + hrs : hrs
  minutes = minutes === '0' ? '00' : minutes < 9 ? '0' + minutes : minutes
  seconds =
    seconds === '0' || !seconds ? '00' : seconds < 9 ? '0' + seconds : seconds
  return (
    <Box
      minW="xs"
      maxW="xs"
      borderWidth="1px"
      borderRadius="lg"
      marginInlineStart={'0'}
      className="imageCard"
    >
      <RenderNavLink url={url} hasStartButton={hasStartButton}>
        <Box height='200px' position="relative">
          <VStack>
            {!hasStartButton && (
              <Image
                zIndex={99999}
                position="absolute"
                left="38%"
                top="30%"
                src={playicon}
                alt={property.imageAlt}
                _hover={{
                  opacity: 1,
                  border: '1px solid ##3b3b3b',
                  borderRadius: '50%',
                  backgroundColor: '#000',
                }}
                style={{
                  opacity: 0.86,
                  borderRadius: '50%',
                }}
              />
            )}
            <Image
              zIndex={3}
              style={{ marginTop: '0px' }}
              src={src}
              alt={property.imageAlt}
            />
            {showExAndVocb && (
              <>
                <Text
                  fontSize="10px"
                  whiteSpace="nowrap"
                  p={1}
                  style={{
                    position: 'absolute',
                    zIndex: 9999,
                    top: '-10px',
                    right: '0',
                    color: '#fff',
                    width: '41px',
                    hight: '19px',
                    backgroundColor: '#000',
                    textAlign: 'center',
                  }}
                >
                  {minutes}:{seconds}
                </Text>
                <HStack alignItems="center" gridGap="8px">
                  <Image
                    src={activiteWhiteImg}
                    size={'sm'}
                    style={{
                      position: 'absolute',
                      zIndex: 9999,
                      top: '80%',
                      right: '90px',
                      color: '#fff',
                      width: '29px',
                      padding: '6px',
                      backgroundColor: '#000',
                    }}
                  ></Image>
                  <Text
                    fontSize="14px"
                    fontWeight={'600'}
                    whiteSpace="nowrap"
                    p={1}
                    style={{
                      position: 'absolute',
                      zIndex: 9999,
                      top: '80%',
                      right: '0',
                      width: '95px',
                      color: '#fff',
                      textAlign: 'center',
                      backgroundColor: '#000',
                    }}
                  >
                    {linkedExerciseCount + linkedVocabularyCount} Activities
                  </Text>
                </HStack>
              </>
            )}
          </VStack>
        </Box>
      </RenderNavLink>
      {showProgress && (
        <HStack spacing="2px">
          <Box w="100%" style={{ marginTop: '-10px' }}>
            <Progress
              borderRadius={2}
              colorScheme={progress === 100 ? 'green' : 'red'}
              size="xs"
              value={progress}
              sx={{
                '& > div': {
                  background: progress === 100 ? 'green' : 'red',
                },
              }}
            />
          </Box>
        </HStack>
      )}
      <Box pt="16px" pl="16px" pr="16px" pb="2">
        <Stack gap="1">
          <Box
            fontWeight="semibold"
            as="h4"
            lineHeight="tight"
            isTruncated
            className="title"
            mt={-2}
          >
            <Tooltip label={property.title} aria-label="A tooltip">
              <Text display="inline" fontSize={16}>
                {property.title}
              </Text>
            </Tooltip>
          </Box>
          {!showProgress && (
            <HStack>
              <Image boxSize="20px" src={profile} />
              <Text fontSize="xs" whiteSpace="nowrap">
                {getCourseCreatorName(auth)}
              </Text>
              <Image
                boxSize="20px"
                src={clock}
                style={{ marginLeft: '80px' }}
              />

              <Text fontSize="xs" whiteSpace="nowrap">
                {hrs} hr {minutes} min
              </Text>
            </HStack>
          )}
          {!showExAndVocb && (
            <HStack>
              <Image src={chaptersImg} size={'sm'}></Image>
              <Text fontSize="xs" whiteSpace="nowrap">
                {chapterCount} chapters
              </Text>
              <Image src={lessonImg} size={'sm'}></Image>
              <Text fontSize="xs" whiteSpace="nowrap">
                {lessonCount} lessons
              </Text>
              <Image src={activiteImg} size={'sm'}></Image>
              <Text fontSize="xs" whiteSpace="nowrap">
                {activitiesCount} Activity
              </Text>
            </HStack>
          )}
          {hasStartButton ? (
            <Flex justifyContent={'center'}>
              {isAdmin() && contentEditable && (
                <Icon
                  ml={3}
                  onClick={e => {
                    e.preventDefault()
                    onCourseEditClick(id)
                  }}
                  as={EditIcon}
                />
              )}
            </Flex>
          ) : (
            ''
          )}
        </Stack>
      </Box>
    </Box>
  )
}
