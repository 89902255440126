import 'src/web.config'
import './index.css'

import { ChakraProvider } from '@chakra-ui/react'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import App from 'src/App'
import { SWRConfig } from 'swr'

import { AuthProvider } from './context/AuthContext'
import Layout from './layout'
import { setupStore } from './redux/store'
import theme from './theme'

const store = setupStore({})

ReactDOM.render(
  <SWRConfig value={{ revalidateOnFocus: false, shouldRetryOnError: false }}>
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          <AuthProvider>
          <Layout>
            <App />
          </Layout>
          </AuthProvider>
        </BrowserRouter>
      </ChakraProvider>
    </Provider>
  </SWRConfig>,
  document.getElementById('root')
)
