import 'react-step-progress-bar/styles.css'
import 'react-sweet-progress/lib/style.css'

import * as $ from 'jquery'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { ProgressBar } from 'react-step-progress-bar'
import { getCourses, GetUnitWiseProgressCount } from 'src/redux/actions'
import LocalStorageService from 'src/util/localStorageService'

const showPercentege = (item, total, selectedLanguageId) => {
  if (total === 0) {
    return (
      <div className="row" style={{ marginBottom: '18px' }}>
        <div className="col-lg-12">
          <Link
            style={{
              textDecoration: 'none',
              cursor: 'not-allowed',
            }}
          >
            <div className="ruler-bx bg-white">
              <div className="left-side" style={{ backgroundColor: '#F1F1F1' }}>
                <h3 style={{ color: ' #c3bebe' }}>{item?.unit}</h3>
              </div>

              <div className="right-side">
                <div>
                  <span
                    style={{
                      color: '#444',
                      opacity: '0.6',
                      float: 'left',
                      marginTop: '-2px',
                      marginRight: '60px',
                      width: '31px',
                      marginLeft: '-4px',
                    }}
                  >
                    COMING
                  </span>
                  <span
                    style={{
                      color: '#444',
                      opacity: '0.6',
                      float: 'left',
                      marginTop: '18px',
                      marginRight: '-13px',
                      width: '18px',
                      marginLeft: '-83px',
                    }}
                  >
                    SOON
                  </span>
                </div>
                <div style={{ marginTop: '18px' }}>
                  <ProgressBar
                    percent={(item?.completed / item?.total) * 100}
                    filledBackground="linear-gradient(to right, #FF8800, #AACC88)"
                  />
                </div>
              </div>
            </div>
          </Link>
        </div>

        <br />
      </div>
    )
  } else {
    return (
      item?.unit !== 'entertainment' && (
        <div className="row" style={{ marginBottom: '18px' }}>
          <div className="col-lg-12">
            <Link
              to={`/introduction/course/${selectedLanguageId}/unit/${item?.id}`}
              style={{ textDecoration: 'none' }}
            >
              <div className="ruler-bx bg-white">
                <div className="left-side">
                  <h3>{item?.unit}</h3>
                </div>

                <div className="right-side">
                  <div>
                    <span
                      style={{
                        color: '#444',
                        opacity: '0.6',
                        float: 'left',
                        marginTop: '9px',
                        marginRight: '41px',
                        width: '31px',
                        marginLeft: '15px',
                      }}
                    >
                      {item?.completed}/{item?.total}
                    </span>
                  </div>
                  <div style={{ marginTop: '18px' }}>
                    <ProgressBar
                      percent={(item?.completed / item?.total) * 100}
                      filledBackground="linear-gradient(to right, #FF8800, #AACC88)"
                    />
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <br />
        </div>
      )
    )
  }
}

const Mycourses = ({ history }) => {
  const dispatch = useDispatch()
  const languages = useSelector(store => store.mainReducer.courses)
  const levelList = useSelector(
    store => store.mainReducer.unitWiseProgressCount
  )

  const [selectedLanguageId, setSelectedLanguageId] = useState(
    LocalStorageService.getSelectedCourseId() || ''
  )
  const [isLoading, setIsLoading] = useState(false)
  const [noLanguageAssigned, setNoLanguageAssigned] = useState(false)

  useEffect(() => {
    let token = window.sessionStorage.getItem('token')

    if (token === null && window.location.href) {
      history.push('/')
    }
  })

  useEffect(() => {
    if (!languages.length) {
      getLanguages()
    }

    $(document).ready(function () {
      var opcionesnav = $('.navoption').length
      var clickhamb = 0

      $('#hamburger').click(function () {
        clickhamb = 1
        var header = $('#myTopnav')
        if (header[0].classList.length === 1) {
          header.addClass('responsive')
          $('header').height((opcionesnav + 1) * 70)
          $('.navlist a:not(.icon)').css('display', 'block')
          setTimeout(function () {
            $('.navlist a:not(.icon)').css('transform', 'translateX(0px)')
          }, 50)
        } else {
          $('.navlist a:not(.icon)').css('transform', 'translateX(600px)')
          header.height(70)
          setTimeout(function () {
            header.removeClass('responsive')
            header.height(70)
            $('.navlist a:not(.icon)').css('display', 'none')
          }, 50)
        }
      })

      $(window).on('resize', function () {
        if ($(window).width() > 600 && clickhamb === 1) {
          $('#myTopnav').height(70)
          $('.navlist a:not(.icon)').css('display', 'block')
          setTimeout(function () {
            $('.navlist a:not(.icon)').css('transform', 'translateX(0px)')
          }, 50)
        }
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const cache = window.sessionStorage.getItem('isReload', true)
    if (cache) {
      window.location.reload(true)
      window.sessionStorage.removeItem('isReload')
    }
    // TODO: Remove this code when we start saving course selection in database
    const selectedLanguageKey = LocalStorageService.getSelectedCourseId()
    if (selectedLanguageKey) {
      setSelectedLanguageId(selectedLanguageKey)
    }
  }, [])

  useEffect(() => {
    if (selectedLanguageId) {
      getLevelList(selectedLanguageId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguageId])

  const handleChange = e => {
    if (e.target.value === '0') {
    } else {
      // TODO: remove it when we start saving selection in database.
      LocalStorageService.setSelectedCourseId(e.target.value)
      setSelectedLanguageId(e.target.value)
    }
  }

  const getLanguages = async () => {
    const result = await dispatch(getCourses())
    if (!result.length) {
      setNoLanguageAssigned(true)
    }
  }
  const setLoading = loading => setIsLoading(loading)
  const getLevelList = async courseId => {
    setLoading(true)
    await dispatch(GetUnitWiseProgressCount(courseId))
    setLoading(false)
  }

  const onLogout = () => {
    sessionStorage.clear()
    sessionStorage.setItem('isLogout', true)
  }

  return (
    <div>
      {isLoading && <div className="loader1"></div>}
      {/* <header className="topnav" id="myTopnav">
        <Link to="/">
          <img className="logo" src={logo} />
        </Link>
        <div className="navlist" id="navlist">
          <Link className="navoption" to="/dashboard">
            My Courses<hr className="hr"></hr>
          </Link>
          <Link className="navoption" to="/login" onClick={onLogout}>
            Sign Out
          </Link>
          <a className="icon" id="hamburger">
            <span></span>
            <span></span>
            <span></span>
          </a>
        </div>
      </header> */}
      <section className="profile-section bg-gray m-t-50 p-t-60 p-b-200">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-3">
              <form className="login100-form validate-form flex-sb flex-w m-t-0">
                <span className="txt1 p-b-11">
                  Choose your learning language
                </span>
                <div className="m-t-5 select_box">
                  <select
                    className="input100 bg-dark-gray"
                    value={selectedLanguageId}
                    name="language_id"
                    onChange={e => handleChange(e, e.target.key)}
                  >
                    <option value={'0'}>--Select language--</option>
                    {languages.map(course => (
                      <option key={course.id} value={course.id}>
                        {course.course}{' '}
                      </option>
                    ))}
                  </select>
                  <span className="focus-input100"></span>
                </div>
              </form>
            </div>
          </div>
          {noLanguageAssigned && (
            <div className="row" style={{ marginBottom: '18px' }}>
              <div className="col-lg-12">
                <div className="ruler-bx bg-white">
                  <div
                    className="left-side"
                    style={{
                      backgroundColor: 'white',
                      width: '100%',
                    }}
                  >
                    <h3
                      style={{
                        color: ' #444',
                        textTransform: 'none',
                      }}
                    >
                      You haven't subscribed any package yet, Please click
                      <Link
                        title="Go to subscription page"
                        to={'/user-subscription-details'}
                      >
                        here
                      </Link>{' '}
                      to subscribe
                    </h3>
                  </div>
                </div>
              </div>
              <br />
            </div>
          )}
          {!isLoading &&
            levelList.map(item => (
              <div key={item.id}>
                {showPercentege(item, item?.total, selectedLanguageId)}
              </div>
            ))}
        </div>
      </section>
    </div>
  )
}

export default Mycourses
