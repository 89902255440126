import {
  Button,
  FormControl,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Textarea,
  useToast,
} from '@chakra-ui/react'
import { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useUser } from 'src/hooks/useUser'
import { getAdminAppUrl } from 'src/util/getAdminAppUrl'

import { postReport } from '../redux/actions'

const ReportSection = ({
  open,
  setOpen,
  selectedExercise,
  selectedVocabularies,
}) => {
  const dispatch = useDispatch()
  const reportTypeRef = useRef()
  const reportDescriptionRef = useRef()
  const toast = useToast()
  const selectedLesson = useSelector(store => store.mainReducer.selectedLesson)
  const { user: auth } = useUser()
  const onReportSubmit = () => {
    if (!reportDescriptionRef.current.value) {
      toast({
        title: 'Warning',
        description: 'Please enter some comments',
        status: 'warning',
        duration: 2000,
        isClosable: true,
      })
    } else if (
      reportDescriptionRef.current.value &&
      reportDescriptionRef.current.value.trim().length < 20
    ) {
      toast({
        title: 'Warning',
        description: 'Please enter some comments with minimum length of 20',
        status: 'warning',
        duration: 2000,
        isClosable: true,
      })
    } else {
      const data = {
        userName: `${auth.firstName} ${auth.lastName}`,
        userEmail: `${auth.email}`,
        userId: `${auth._id}`,
        lesson_id: selectedLesson.lesson?.id,
        issueType: !reportTypeRef.current.value
          ? 'content'
          : reportTypeRef.current.value,
        status: 'open',
        isDeleted: false,
        comments: reportDescriptionRef.current.value,
        reportLocation: window.location.href,
        exerciseDetailUrl:
          selectedExercise?.id &&
          !selectedVocabularies?.id &&
          getAdminAppUrl() + `app/exerciseDetails?_id=${selectedExercise?.id}`,
        exerciseMasterUrl:
          selectedExercise?.exerciseMasterId &&
          !selectedVocabularies?.id &&
          getAdminAppUrl() +
            `app/exerciseMasters?_id=${selectedExercise?.exerciseMasterId}`,
        vocabularyUrl:
          selectedVocabularies?.id &&
          getAdminAppUrl() +
            `app/entertainmentVocabularies?_id=${selectedVocabularies?.id}`,
      }
      setOpen(false)
      dispatch(postReport(data))
      toast({
        title: 'Success',
        description:
          'Thank you for contacting us regarding your concern, we will update you soon',
        status: 'success',
        duration: 2000,
        isClosable: true,
      })
    }
  }
  return (
    <Modal
      blockScrollOnMount={false}
      isOpen={open}
      onClose={() => {
        setOpen(false)
      }}
    >
      <ModalOverlay />
      <ModalContent w={'95%'}>
        <ModalHeader justifyContent={'center'} color={'#2469F5'}>
          Report the issue
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl as="fieldset" flexDirection={'column'}>
            <RadioGroup
              defaultValue="content"
              ref={reportTypeRef}
              onChange={e => (reportTypeRef.current.value = e)}
            >
              <HStack flexDirection={'column'}>
                <Radio value="content" width={'180px'} variantColor="green">
                  Content issue
                </Radio>
                <Radio value="technical" width={'190px'} variantColor="green">
                  Tech issue
                </Radio>
                <Radio value="other" width={'190px'} variantColor="green">
                  Other issue
                </Radio>
                <Radio value="suggestion" width={'190px'} variantColor="red">
                  Any suggestion
                </Radio>
              </HStack>
            </RadioGroup>
          </FormControl>
          <Textarea
            placeholder="Please enter issue"
            size="lg"
            required
            ref={reportDescriptionRef}
            mt={'10px'}
          />
        </ModalBody>
        <ModalFooter justifyContent={'center'}>
          <Button
            color="#fff"
            bg={
              'transparent linear-gradient(180deg, #2469F5 0%, #12357B 100%) 0% 0% no-repeat padding-box'
            }
            _hover={{
              background:
                'transparent linear-gradient(180deg, #2469F5 0%, #12357B 100%) 0% 0% no-repeat padding-box',
            }}
            mr={3}
            onClick={onReportSubmit}
          >
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
export default ReportSection
