import * as React from 'react'
import { useToken } from 'src/hooks/useToken'
import * as auth from 'src/providers/AuthProvider'
import { tokenStore } from 'src/redux/useTokenStore'

const AuthContext = React.createContext()
AuthContext.displayName = 'AuthContext'

function AuthProvider(props) {
  const { token, isLoading, error } = useToken()

  const login = React.useCallback(
    form =>
      auth.login(form).then(resp => {
        tokenStore.setState({ token: resp.token })
      }),
    []
  )

  const register = React.useCallback(
    form =>
      auth.register(form).then(resp => tokenStore.setState({ token: resp })),
    []
  )

  const logout = React.useCallback(async () => {
    await auth.logout()
    tokenStore.setState({ token: null })
  }, [])

  const value = React.useMemo(
    () => ({ token, login, logout, register }),
    [login, logout, register, token]
  )

  // if (isLoading) {
  //   return <FullPageSpinner />
  // }

  if (error) {
    console.log('Error in Auth:', error)
  }

  return <AuthContext.Provider value={value} {...props} />
}

function useAuth() {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }
  return context
}

export { AuthProvider, useAuth }
