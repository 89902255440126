import {
  Box,
  Button,
  ButtonGroup,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react'
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  InstapaperIcon,
  InstapaperShareButton,
  LineIcon,
  LineShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  LivejournalIcon,
  LivejournalShareButton,
  PinterestIcon,
  PinterestShareButton,
  PocketIcon,
  PocketShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TumblrIcon,
  TumblrShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'next-share'
import React from 'react'
import shareIcon from 'src/asset/images/share.png'
const hasTag = '#Cocoma Academy'
const sharingContent =
  'Please promote our Cocoma Academy content with your experience in social media'

const url = `https://langistan-student-panel.vercel.app${window.location.pathname} `
const MediaSharePanel = () => {
  const initialFocusRef = React.useRef()

  return (
    <Popover
      initialFocusRef={initialFocusRef}
      placement="bottom"
      closeOnBlur={true}
    >
      <PopoverTrigger>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Image
            src={shareIcon}
            alt="Share Cocoma Academy"
            boxSize="20px"
            cursor={'pointer'}
          ></Image>
        </Box>
      </PopoverTrigger>
      <PopoverContent
        color="#000"
        bg="#fff"
        border={'none'}
        boxShadow={'inset 0px 1px 2px 0px rgb(68 68 68 / 60%)'}
      >
        <PopoverHeader pt={2} fontWeight="bold" border="0">
          Social Media Sharing
        </PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody padding={2}>
          You can share this content on social media using the buttons below
          <Box paddingTop={10}>
            <ButtonGroup size="sm">
              <FacebookShareButton
                url={url}
                quote={sharingContent}
                hashtag={hasTag}
                description={sharingContent}
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <WhatsappShareButton
                url={url}
                title={sharingContent}
                quote={sharingContent}
                hasTag={hasTag}
                separator=":: "
              >
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
              <LinkedinShareButton
                url={url}
                quote={sharingContent}
                hasTag={hasTag}
              >
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
              <EmailShareButton
                url={url}
                quote={sharingContent}
                subject={'Lanistan'}
                body="sharingContent"
              >
                <EmailIcon size={32} round />
              </EmailShareButton>
              <PinterestShareButton
                url={url}
                hasTag={hasTag}
                media={sharingContent}
              >
                <PinterestIcon size={32} round />
              </PinterestShareButton>
              <TwitterShareButton
                url={url}
                hasTag={hasTag}
                title={sharingContent}
              >
                <TwitterIcon size={32} round />
              </TwitterShareButton>
              <TelegramShareButton
                url={url}
                hasTag={hasTag}
                title={sharingContent}
              >
                <TelegramIcon size={32} round />
              </TelegramShareButton>
            </ButtonGroup>
            <ButtonGroup size="sm">
              <LineShareButton url={url} hasTag={hasTag} title={sharingContent}>
                <LineIcon />
              </LineShareButton>
              <RedditShareButton
                url={url}
                hasTag={hasTag}
                title={sharingContent}
              >
                <RedditIcon size={32} round />
              </RedditShareButton>
              <TumblrShareButton
                url={url}
                hasTag={hasTag}
                title={sharingContent}
              >
                <TumblrIcon size={32} round />
              </TumblrShareButton>
              <LivejournalShareButton
                title={'Cocoma Academy Share'}
                url={url}
                hasTag={hasTag}
                description={sharingContent}
              >
                <LivejournalIcon size={32} round />
              </LivejournalShareButton>
              <PocketShareButton
                title={'Cocoma Academy Share'}
                url={url}
                hasTag={hasTag}
                description={sharingContent}
              >
                <PocketIcon size={32} round />
              </PocketShareButton>
              <InstapaperShareButton
                title={'Cocoma Academy Share'}
                url={url}
                hasTag={hasTag}
                description={sharingContent}
              >
                <InstapaperIcon size={32} round />
              </InstapaperShareButton>
            </ButtonGroup>
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default MediaSharePanel
