const {
  Box,
  Flex,
  Text,
  Stack,
  Image,
  HStack,
  Button,
  VStack,
} = require('@chakra-ui/react')
const InfoSection = () => {
  return (
    <Flex
      bg={'hsla(217, 100%, 50%, 1)'}
      alignItems={'center'}
      flex={'1 0 100%'}
      section={'1'}
      direction={['column', 'column', 'row']}
      w={'100%'}
      px={[5, 5, 5]}
      py={2}
      style={{
        marginTop:'15px'
      }}
      justifyContent={'center'}
    >
      <Box py={[5, 5, 5]} h={['120px', '150px', '180px']}>
        <Text
          color={'#fff'}
          w="100%"
          textAlign={'center'}
          fontSize={['12px', '20px', '25px']}
          fontWeight={[900, 600, 600]}
        >
          {'Ready to speak Hindi?'}
        </Text>
        <Text
          w="100%"
          textAlign={'center'}
          fontSize={['10px', '14px', '18px']}
          fontWeight={[400]}
          pt={3}
          color={'#fff'}
        >
          {'Start your Hindi learning journey with Cocoma Academy'}
        </Text>
        <Box py={3} textAlign={'center'}>
          <Button
            py={1}
            borderRadius={0}
            color="#2469F5"
            w={'250px'}
            h={'48px'}
            bg={'#fff'}
            _hover={'#fff'}
          >
            Get Started
          </Button>
        </Box>
      </Box>
    </Flex>
  )
}
export default InfoSection
