import React from 'react'
import Header from './Header'
import Footer from './Footer'
import '../fonts/fonts/font-awesome-4.7.0/css/font-awesome.min.css'
import '../fonts/work-sans-cufonfonts-webfont/style.css'

class Contact extends React.Component {
  componentWillMount() {
    document.body.style.backgroundColor = null
    let token = window.sessionStorage.getItem('token')
    if (token) {
      // return <Redirect to="/dashboard" />
      // return <Link to="/dashboard" />
      // window.history.push('/dashboard');
      // window.history.forward();
      this.props.history.push('/dashboard')
      // window.history.go(1);
      // this.props.context.history.push('/dashboard');
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <div>
        <Header />
        <section className="banner-contact">
          <div className=""></div>
          <div className="container">
            <div className="row">
              <div className="banner-section-title">
                <h1>Contact</h1>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-white p-t-60 p-b-116 bx-shadow m-b-20">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 txt-center">
                <h1 className="contact-title m-b-60">
                  We'd{' '}
                  <span>
                    <i className="fa fa-heart"></i>{' '}
                  </span>{' '}
                  to help you!
                </h1>
                <p className="contact-p">
                  Are you having any trouble? Contact: support@langistan.com
                </p>
                <p className="contact-p">
                  Journalist or blogger? Please reach out to:
                  press@langistan.com
                </p>
                <p className="contact-p">
                  Looking to partner with us? Mail: partnerships@langistan.com
                </p>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    )
  }
}

export default Contact
