import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useAuth } from 'src/context/AuthContext'

import LoginForm from './LoginForm'
import RegistrationForm from './RegistrationForm'

const LoginSignupTabs = () => {
  const location = useLocation()
  const history = useHistory()
  const { user } = useAuth()

  //   const isLogin = location.pathname === 'login'
  const [tabIndex, setTabIndex] = useState(0)
  useEffect(() => {
    setTabIndex(0)
  }, [location.pathname])

  const handleTabsChange = () => {
    history.push(tabIndex === 0 ? 'register' : 'login')
  }

  if (user) {
    history.push('/dashboard')
  }

  const onClose = () => {
    history.push('/home')
  }
  return (
    <Modal isCentered isOpen onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay bg="white" backdropFilter="blur(0px)" />
      <ModalContent>
        <ModalBody>
          <Tabs
            p={3}
            variant="soft-rounded"
            index={tabIndex}
            onChange={handleTabsChange}
          >
            <TabList justifyContent="center">
              <Flex boxShadow="5px 3px 6px #00000029" borderRadius={40}>
                <Tab
                  fontSize="sm"
                  width={'141px'}
                  _focus={{ boxShadow: 'none' }}
                  _selected={{
                    background:
                      'transparent linear-gradient(180deg, #2469F5 0%, #12357B 100%) 0% 0% no-repeat padding-box',
                    color: 'white',
                  }}
                >
                  Login
                </Tab>
                {/* <Tab
                  width={'141px'}
                  height={'48px'}
                  _focus={{ boxShadow: 'none' }}
                  _selected={{
                    background:
                      'transparent linear-gradient(180deg, #2469F5 0%, #12357B 100%) 0% 0% no-repeat padding-box',
                    color: 'white',
                  }}
                  fontSize="sm"
                >
                  Signup
                </Tab> */}
              </Flex>
            </TabList>
            <TabPanels>
              <TabPanel>
                <LoginForm />
              </TabPanel>
              <TabPanel>
                <RegistrationForm />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
export default LoginSignupTabs
