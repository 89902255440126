import { useState } from 'react'
import { useHistory } from 'react-router'
import sectionsixone from 'src/asset/images/sectionsix/sectionsixone.jpg'
import sectionsixthree from 'src/asset/images/sectionsix/sectionsixthree.jpg'
import sectionsixtwo from 'src/asset/images/sectionsix/sectionsixtwo.jpg'

import { isMobile } from '../CourseDetail/Practice/Util'
import ImageSlider from './ImageSlider'
import {
  btnHover,
  fontMain,
  fontSecond,
  hyperTextColor,
  imageFlex,
  imagelg,
  imagemd,
  imageSx,
  textPanelWidth,
} from './util'
const { Box, Flex, Text, Button } = require('@chakra-ui/react')

const height = '40px'
const width = isMobile ? '180px' : '150px'
const selctionSixData = [
  {
    image: sectionsixone,
  },
  {
    image: sectionsixtwo,
  },
  {
    image: sectionsixthree,
  },
]
const SectionSix = () => {
  const history = useHistory()
  const [autoPlay, setAutoPlay] = useState(false)
  const onSignUp = () => {
    history.push({
      pathname: '/register',
      state: {
        comingFromHome: true,
      },
    }) 
  }
  return (
    <Flex
      flex={'1 0 100%'}
      section={'1'}
      bg="#fff"
      w={'100%'}
      direction={['column', 'column-reverse', 'row']}
      px={[5, 5, 5]}
      py={5}
      justifyContent={'center'}
    >
      <Box px={[10, 1, 5]} py={[10, 0, 1]} w={textPanelWidth}>
        <Text textAlign={'left'} pb={3} fontSize={fontMain} fontWeight={900}>
          {'Every frame of Cocoma Academy 4L videos is a lesson in itself'}
        </Text>
        {!isMobile && (
          <>
            <Text
              textAlign={'left'}
              pb={0}
              fontSize={fontSecond}
              fontWeight={600}
            >
              <Text textAlign={'left'} fontSize={fontSecond} fontWeight={600}>
                {
                  'So special that you learn to pronounce, read in your native script, understand the meaning word to word, phrase to phrase,'
                }
              </Text>
              <Text textAlign={'left'} fontSize={fontSecond} fontWeight={600}>
                {
                  'get break-down of grammar and actual translation all at the same time from the very single frame.'
                }
              </Text>
            </Text>
          </>
        )}
        {isMobile && (
          <>
            <Text
              textAlign={'left'}
              pb={0}
              fontSize={fontSecond}
              fontWeight={600}
            >
              <Text
                bg="{hyperTextColor}"
                // color={'#fff'}
                display={'inline'}
                fontWeight={900}
                // px={2}
                fontSize={fontSecond}
              >
                {
                  'So special that you learn to pronounce, read in your native script, understand the meaning word to word, phrase to phrase, get break-down of grammar and actual translation all at the same time from the very single frame.'
                }
              </Text>
            </Text>
          </>
        )}
        <Box  pt={[5, 5, 3]} textAlign={['center', 'center', 'inherit']}>
          <Button
            color="#707070"
            w={width}
            h={height}
            //bg={btnBg}
            style={{
              border: '1px solid #707070',
              backgroundColor: '#fff',
            }}
            borderRadius={'5px'}
            _hover={btnHover}
            onClick={onSignUp}
          >
            Sign up Now
          </Button>
        </Box>
      </Box>
      <Box
        textAlign={['center', 'left', 'left']}
        w={['90%', '100%', '30%']}
        flex={imageFlex}
        pt={3}
      >
        <ImageSlider
          px={[10, 0, 0]}
          sx={[imageSx, imagemd, imagelg]}
          borderRadius={'15px'}
          slides={selctionSixData}
          autoPlay={autoPlay}
          onClick={() => {
            setAutoPlay(!autoPlay)
          }}
        ></ImageSlider>
      </Box>
    </Flex>
  )
}

export default SectionSix
