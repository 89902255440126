import '../fonts/fonts/font-awesome-4.7.0/css/font-awesome.min.css'
import '../fonts/work-sans-cufonfonts-webfont/style.css'

import React from 'react'

import Footer from './Footer'
import Header from './Header'

class Termsconditions extends React.Component {
  componentWillMount() {
    document.body.style.backgroundColor = null
    let token = window.sessionStorage.getItem('token')
    if (token) {
      // return <Redirect to="/dashboard" />
      // return <Link to="/dashboard" />
      // window.history.push('/dashboard');
      // window.history.forward();
      this.props.history.push('/dashboard')
      // window.history.go(1);
      // this.props.context.history.push('/dashboard');
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <div>
        <Header />
        <section className="banner-terms">
          <div className=""></div>
          <div className="container">
            <div className="row">
              <div className="banner-section-title">
                <h1>Terms & Conditions</h1>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-white p-t-60 p-b-100 bx-shadow m-b-20">
          <div className="container">
            <div className="row">
              <div className="col-md-12 privacy-content">
                <h1>INTRODUCTION - EDUCATION THROUGH THE WEB</h1>
                <p className="m-b-20">
                  We place education as a powerful tool in today’s
                  technology-driven and fast-paced world. We believe in creating
                  a world where everyone has access to knowledge and learning.
                  We want to provide opportunities which everyone can gain
                  through education. Cocoma Academy.com adheres to its mission of
                  providing quality education through affordable online tutorial
                  services. The core competencies of our tutors are
                  subject-based teaching, having solid experience in conducting
                  lessons in through website or app or other virtual learning
                  programs. We provide high quality of education through modules
                  based on a specific subject with a small price tag. We foster
                  group discussions and help create relationships with our
                  students. We nurture the thirst for knowledge – and this
                  speaks true of what we value most, learning.
                </p>
                <br />
                <h1>ABOUT US</h1>
                <p className="m-b-20">
                  Cocoma Academy.com is a team of dedicated, hardworking and reliable
                  tutors who place learning above everything else. Formed in
                  2018 as a response to the growing need for non-traditional
                  learning that promotes self-discovery, knowledge construction,
                  group-based discussions, transformational and independent
                  learning, which is beneficial to students who want to exercise
                  their flexibility and options on what to learn. We employ the
                  best teaching methods done online through our virtual
                  classroom or learning management system. Our modules are
                  created in a friendly and engaging manner to capture the
                  student’s interest. We cultivate the passion of understanding
                  the world around, giving us more motivation to teach.
                </p>
                <br />
                <h1>FAQ</h1>
                <h2>
                  Q: What are the requirements for the computer unit (PC/MAC)
                  should I meet?
                </h2>
                <p className="m-b-20">
                  Your computer must meet the following system requirements in
                  order to run the Virtual Classroom software: • Windows XP
                  (w/Service Pack 3), Vista (w/Service Pack 2), or Windows 7
                  (w/Service pack 1); • 2.0 GB of system memory (RAM); • Single
                  Core Processor at 2.4 GHz; • At least DSL internet connection;
                  • Microsoft Internet Explorer 7.0 or higher.
                </p>
                <br />
                <h2>
                  Q: What are the available time slot for the online tutorial
                  facility?
                </h2>
                <p className="m-b-20">
                  Cocoma Academy.com is available any time, everyday, even on
                  weekends and holidays.
                </p>
                <br />
                <h2>Q: What subjects can I enroll in?</h2>
                <p className="m-b-20">
                  We offer tutorial services currently in Hindi and soon we will
                  include other languages as well. Our proficient tutors can
                  teach you from the subject fundamentals to exam preparations.
                </p>
                <br />
                <h2>
                  Q: What are the applicable membership fees upon signing?{' '}
                </h2>
                <p className="m-b-20">
                  The membership fee for the annual package is 99US$.
                </p>
                <br />
                <h2>
                  Q: Can I cancel my membership any time? Will I get a refund?{' '}
                </h2>
                <p className="m-b-20">
                  {' '}
                  Presently langistan.com doesn’t offer any cancellation of
                  membership and hence no refund. If there is any rare case, you
                  can contact us via our contact form and we might consider your
                  request and take possible action.
                </p>
                <br />
                <h1>TERMS OF AGREEMENTS</h1>
                <h2>1. Definitions</h2>
                <p className="m-b-20">
                  Cocoma Academy.com is a online language learning program offered to
                  its clients referred to as “the student”, as a flexible and
                  affordable means to education. “The Services” shall be
                  referred to as the permission to log on to the portal, online
                  tutorial and customer support. These Website Standard Terms
                  And Conditions (these “Terms” or these “Website Standard Terms
                  And Conditions”) contained herein on this webpage, shall
                  govern your use of this website, including all pages within
                  this website (collectively referred to herein below as this
                  “Website”). These Terms apply in full force and effect to your
                  use of this Website and by using this Website, you expressly
                  accept all terms and conditions contained herein in full. You
                  must not use this Website if you have any objection to any of
                  these Website Standard Terms And Conditions.
                </p>
                <br />
                <h2>2. Scope of Service</h2>
                <p className="m-b-20">
                  We will be able to connect with our students through our
                  registered software and online portal found in Cocoma Academy.com.
                  The Student must provide his/her computer, internet connection
                  and must create his/her own account in order to access the
                  tutorial services. In relation, the Services we provide shall
                  be made available 24 hours, seven days a week, 365 days in a
                  year. Should there be maintenance or upgrade issues, we will
                  notify the Student within the 48 hours prior to the actual
                  activity. The customer support details shall be provided in
                  your account and can be accessed 24 hours, every day.
                </p>
                <br />
                <h2>3. Privacy Policy</h2>
                <p className="m-b-20">
                  Your registered account will be verified through your
                  registered email address. Upon confirmation, you will be asked
                  to fill out a form in which you will share your personal
                  information. After accepting the terms and conditions in the
                  privacy policy, you may log on using your registered account.
                  We respect the Students right to privacy. We will not disclose
                  nor sell the Student’s personal identification and other
                  pertinent details that will reveal any close connection to the
                  Student’s true identity.
                </p>
                <br />
                <h2>4. Payments </h2>
                <p className="m-b-20">
                  You can choose packages from our membership plans. Payments
                  can be made through PayPal or wire transfer.{' '}
                </p>
                <br />
                <h2>5. User Content </h2>
                <p className="m-b-20">
                  Any material, information or communication you upload using
                  our facility will be treated as non-confidential and
                  non-proprietary. You automatically grant us permission to use
                  the material/information/communication for our use whether for
                  dissemination, quality control, curriculum development, or
                  marketing our services.
                </p>
                <br />
                <h2>6. Modifications of the Service</h2>
                <p className="m-b-20">
                  {' '}
                  We may add, modify or disable some of the existing features in
                  the online classroom. We reserve the right to change or
                  discontinue the service from time to time to make room for
                  maintenance procedures or upgrade of system/facilities. We
                  will not be held liable for any changes or modifications we
                  deem necessary for the improvement of the overall site
                  features.{' '}
                </p>
                <br />
                <h2>7. Restrictions </h2>
                <p className="m-b-20">
                  Certain areas of this Website are restricted from access by
                  you and Cocoma Academy.com may further restrict access by you to
                  any areas of this Website, at any time, in its sole and
                  absolute discretion. Any user ID and password you may have for
                  this Website are confidential and you must maintain the
                  confidentiality of such information. You are expressly and
                  emphatically restricted from all of the following:
                  <br />
                  • Publishing any Website material in any media; <br />
                  • Selling, sublicensing and/or otherwise commercializing any
                  Website material; <br />
                  • Publicly performing and/or showing any Website material;{' '}
                  <br />
                  • Using this Website in any way that is, or maybe, damaging to
                  this Website; <br />
                  • Using this Website in any way that impacts user access to
                  this Website; <br />
                  • Using this Website contrary to applicable laws and
                  regulations, or in a way that causes, or may cause, harm to
                  the Website, or to any person or business entity; <br />
                  • Engaging in any data mining, data harvesting, data
                  extracting or any other similar activity in relation to this
                  Website, or while using this Website; <br />• Using this
                  Website to engage in any advertising or marketing.
                </p>
                <h2>8. Variation of Terms </h2>
                <p className="m-b-20">
                  Cocoma Academy.com is permitted to revise these Terms at any time
                  as it sees fit, and by using this Website you are expected to
                  review such Terms on a regular basis to ensure you understand
                  all terms and conditions governing the use of this Website.
                </p>
                <br />
                <h2>9. Assignment </h2>
                <p className="m-b-20">
                  Cocoma Academy.com shall be permitted to assign, transfer, and
                  subcontract its rights and/or obligations under these Terms
                  without any notification or consent required. However, .you
                  shall not be permitted to assign, transfer, or subcontract any
                  of your rights and/or obligations under these Terms.{' '}
                </p>
                <br />
                <h2>10. Entire Agreement</h2>
                <p className="m-b-20">
                  These Terms, including any legal notices and disclaimers
                  contained on this Website, constitute the entire agreement
                  between Cocoma Academy.com and you in relation to your use of this
                  Website and supersede all prior agreements and understandings
                  with respect to the same.
                </p>
                <br />
                <h2>11. Governing Law & Jurisdiction</h2>
                <p className="m-b-20">
                  These Terms will be governed by and construed in accordance
                  with the laws of the State of Rajasthan, India, and you submit
                  to the non-exclusive jurisdiction of the state and federal
                  courts located in Jodhpur, Rajasthan, India for the resolution
                  of any disputes.
                </p>
                <br />
                <h3 className="txt-right">Last revised on 1th March, 2019</h3>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    )
  }
}

export default Termsconditions
