import facebook2x from 'src/asset/images/facebook2x.png'
import instagram2x from 'src/asset/images/instagram2x.png'
import logo from 'src/asset/images/logoBeta.svg'
import twitter2x from 'src/asset/images/twitter2x.png'
import youtube2x from 'src/asset/images/youtube2x.png'
const {
  Box,
  Flex,
  Text,
  Stack,
  Image,
  HStack,
  VStack,
} = require('@chakra-ui/react')
const Footer = () => {
  const textFont = ['16px', '14px', '16px']
  const textFontWeight = 600
  const onFooterClick = url => {
    window.open(url, '_blank')
  }
  return (
    <Stack
      direction={['column', 'column', 'row']}
      spacing="24px"
      w={'100%'}
      justifyContent={'space-between'}
      py={15}
    >
      <Flex
        justifyContent={['space-between', 'center', 'space-between']}
        direction={['column', 'column', 'column']}
        maxH={'130px'}
      >
        <Box pb={[0, 5, 0]} px={[2]}>
          <Image
            src={logo}
            sx={{
              objectFit: 'contain',
              width: '150px',
              marginTop: '11px',
            }}
          ></Image>
        </Box>
        <Box px={[2, 5, 2]}>
          <Text fontSize={'12px'} px={[0, 5, 2]} pb={[3, 2, 3]}>
            {'Follow us'}
          </Text>
          <HStack px={[0, 5, 2]}>
            <Image
              src={facebook2x}
              cursor={'pointer'}
              sx={{
                width: '16px',
              }}
              onClick={() =>
                window.open('https://www.facebook.com/Cocoma Academy', '_blank')
              }
            ></Image>
            <Image
              src={instagram2x}
              cursor={'pointer'}
              sx={{
                objectFit: 'contain',
                width: '34px',
              }}
              onClick={() =>
                window.open(
                  'https://instagram.com/langistan?igshid=YmMyMTA2M2Y=',
                  '_blank'
                )
              }
            ></Image>
            <Image
              src={twitter2x}
              cursor={'pointer'}
              sx={{
                objectFit: 'contain',
                width: '34px',
              }}
              onClick={() =>
                window.open(
                  'https://twitter.com/langistanhindi?t=DpRbuzm8V9QQ5ozEnhrxPg&s=09',
                  '_blank'
                )
              }
            ></Image>
            <Image
              src={youtube2x}
              cursor={'pointer'}
              sx={{
                objectFit: 'contain',
                width: '40px',
              }}
              onClick={() =>
                window.open(
                  'https://youtube.com/channel/UCFPf524YNhI9AS5f-EWHuxQ',
                  '_blank'
                )
              }
            ></Image>
          </HStack>
        </Box>
      </Flex>
      <Box py={[5, 0, 5]}>
        <VStack
          py={3}
          px={[2, 10, 2]}
          alignItems={['baseline', 'flex-start', 'baseline']}
        >
          <Text
            fontSize={textFont}
            fontWeight={textFontWeight}
            cursor={'pointer'}
            onClick={() => {
              onFooterClick('https://langistan.com/about-us')
            }}
          >
            {'About us'}
          </Text>
          <Text
            fontSize={textFont}
            fontWeight={textFontWeight}
            cursor={'pointer'}
            onClick={() => {
              onFooterClick('https://langistan.com/contact-us')
            }}
          >
            {'Our Team'}
          </Text>
          <Text fontSize={textFont} fontWeight={textFontWeight}>
            {'Pricing'}
          </Text>
          <Text
            fontSize={textFont}
            fontWeight={textFontWeight}
            cursor={'pointer'}
            onClick={() => {
              onFooterClick('https://langistan.com/contact-us')
            }}
          >
            {'Contact'}
          </Text>
          <Text
            fontSize={textFont}
            fontWeight={textFontWeight}
            cursor={'pointer'}
            onClick={() => {
              onFooterClick(
                'https://www.langistan.com/return-and-refund-policy'
              )
            }}
          >
            {'Return and Refund Policy'}
          </Text>
          <Text
            fontSize={textFont}
            fontWeight={textFontWeight}
            cursor={'pointer'}
            onClick={() => {
              onFooterClick('https://www.langistan.com/privacy-policy')
            }}
          >
            {'Privacy Policy'}
          </Text>
          <Text
            fontSize={textFont}
            fontWeight={textFontWeight}
            cursor={'pointer'}
            onClick={() => {
              onFooterClick('https://www.langistan.com/terms-conditions')
            }}
          >
            {'T&C'}
          </Text>
        </VStack>
      </Box>
      <Box py={[5, 2, 5]} maxWidth={'300px'} alignItems={'baseline'}>
        <VStack
          py={[3, 0, 3]}
          px={[2, 10, 2]}
          alignItems={['baseline', 'baseline', 'baseline']}
        >
          <Text fontSize={textFont} fontWeight={textFontWeight}>
            {'Contact Us'}
          </Text>
          <Text fontSize={textFont}>{'Are you having any trouble?'}</Text>
          <Text fontSize={textFont} color={'#033CC3'}>
            {'support@langistan.com'}
          </Text>
          <Text fontSize={textFont} fontWeight={textFontWeight}>
            {'Address'}
          </Text>
          <Text fontSize={textFont} fontWeight={500}>
            {'Cocoma Digital Pvt Ltd'}
          </Text>
          <Text fontSize={textFont}>
            {
              'Bombay Bazar, Shourie Complex, 101, JP Rd, Andheri West, Mumbai, Maharashtra 400065'
            }
          </Text>
        </VStack>
      </Box>
    </Stack>
  )
}

export default Footer
