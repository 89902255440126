import { useState } from 'react'
import { useHistory } from 'react-router'
import mfive from 'src/asset/images/banner/mobile/mfive.jpg'
import mfour from 'src/asset/images/banner/mobile/mfour.jpg'
import mone from 'src/asset/images/banner/mobile/mone.jpg'
import mthree from 'src/asset/images/banner/mobile/mthree.jpg'
import mtwo from 'src/asset/images/banner/mobile/mtwo.jpg'

import { isMobile } from '../CourseDetail/Practice/Util'
import ImageSlider from './ImageSlider'
import {
  bannerText,
  fontMain,
  fontSecond,
  imagelg,
  imagemd,
  imageSx,
} from './util'

const { Box, Flex, Text, HStack, VStack, Button } = require('@chakra-ui/react')
const selctionOneData = [
  {
    image: mone,
  },
  {
    image: mtwo,
  },
  {
    image: mthree,
  },
  {
    image: mfour,
  },
  {
    image: mfive,
  },
]
const btnBg =
  'transparent linear-gradient(180deg, #2469F5 0%, #12357B 100%) 0% 0% no-repeat padding-box'
const btnHover = {
  background:
    'transparent linear-gradient(180deg, #2469F5 0%, #12357B 100%) 0% 0% no-repeat padding-box',
}
const MobileBanner = () => {
  const [activeIndex, setActiveIndex] = useState(0)
  const history = useHistory()
  const onLogin = () => {
    history.push({
      pathname: '/login',
      state: {
        comingFromHome: true,
      },
    })
    window.location.reload(false)
  }
  return (
    <Flex
      direction={['column']}
      w={'100%'}
      px={[2]}
      py={[0]}
      alignItems={'center'}
      style={{
        marginTop: 0,
      }}
    >
      <VStack textAlign={['center']} w={['100%']} h={['100%']}>
        <ImageSlider
          px={[0]}
          sx={[imagemd]}
          // borderRadius={'15px'}
          autoPlay={true}
          slides={selctionOneData}
          centerMode={false}
          onChange={index => {
            setActiveIndex(index)
          }}
        ></ImageSlider>

        <HStack
          px={[8]}
          py={[6]}
          pos={'absolute'}
          w={['100%']}
          top={['1.5%']}
          flexDirection={'column'}
          alignItems={'center'}
        >
          <Text
            textAlign={'center'}
            fontSize={isMobile ? '24px' : '25px'}
            fontWeight={900}
            color={'#2d2d2d'}
          >
            {bannerText[activeIndex]['main']}
          </Text>
          <Text
            textAlign={'center'}
            pb={4}
            py={3}
            fontSize={'16px'}
            fontWeight={400}
            style={{
              marginInlineStart: 0,
            }}
            color={'#2d2d2d'}
          >
            {bannerText[activeIndex]['nextText']}
          </Text>

          <Box alignItems={'center'} py={3}>
            <Button
              color="#fff" 
              w={'220px'}
              h={'50px'}
              fontSize={'20px'}
              bg={btnBg}
              _hover={btnHover}
              borderRadius={5}
              onClick={onLogin} 
            >
              {bannerText[activeIndex]['buttonText']}
            </Button>
          </Box>
        </HStack>
      </VStack>
    </Flex>
  )
}

export default MobileBanner
