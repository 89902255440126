const isDev = process.env.REACT_APP_ENVIRONMENT === 'dev'
const isLocal = process.env.REACT_APP_ENVIRONMENT === 'local'

module.exports = Object.freeze({
  API_URI: isLocal
    ? process.env.REACT_APP_LOCAL_BACKEND_API
    : isDev
    ? process.env.REACT_APP_DEV_BACKEND_API
    : process.env.REACT_APP_BACKEND_API,
  MEDIA_URI: isLocal
    ? process.env.REACT_APP_LOCAL_BACKEND_MEDIA_API
    : isDev
    ? process.env.REACT_APP_DEV_BACKEND_MEDIA_API
    : process.env.REACT_APP_BACKEND_MEDIA_API,
})
