import * as $ from 'jquery'
import moment from 'moment'
import React from 'react'
import { Col,Form, FormControl, FormGroup } from 'react-bootstrap'
import { Button, Modal } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { Link, Redirect } from 'react-router-dom'

import coin__icon from '../Images/coin-icon.png'
import logo from '../Images/logo2x.png'
import notificationicon from '../Images/notification.png'
import * as myConstClass from './constants'

const api_Url = myConstClass.api

class Profilebookatutor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tutorList: [],
      languageList: [],
      product_id: '',
      language_id: '',
      classSize: '',
      language_id: '',
      showMessageBox: false,
      message: '',
      classSizesend: '',
      showSendButton: false,
      tutorId: '',
      showPostMessage: '',
      displaytext: 'hide_block',
      showBookLesson: false,
      type: '',
      showBookLessonButton: '',
      time: '',
      date: '',
      notificationList: [],
      notificationCount: '',
      showNotificatonbox: '',
      isLoading: true,
      productName: '',
      levelName: '',
      notificatinType: '',
      type: '',
      language_id: '',
      chapter_id: '',
      level_id: '',
      lesson_id: '',
      AlllanguageList: [],
      languageList: [],
      lessonType: '',
      credit: 0,
      levelList: [],
    }
  }

  Logout = () => {
    sessionStorage.clear()
    let isLogout = true
    sessionStorage.setItem('isLogout', isLogout)
  }

  handleChange = e => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        this.getTutorList()
      }
    )
  }

  handleChangeSend = (e, type) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        type: type,
      },
      () => {
        if (this.state.type === 'message') {
          if (
            this.state.language_id === '' ||
            this.state.language_id === '0' ||
            this.state.classSizesend === '' ||
            this.state.classSizesend === '0' ||
            this.state.message === ''
          ) {
            this.setState({ showSendButton: false })
          } else {
            this.setState({ showSendButton: true })
          }
        } else if (this.state.type === 'lesson') {
          if (
            this.state.language_id === '' ||
            this.state.language_id === '0' ||
            this.state.classSizesend === '' ||
            this.state.classSizesend === '0' ||
            this.state.time == ''
          ) {
            this.setState({ showBookLessonButton: false })
          } else {
            this.setState({ showBookLessonButton: true })
          }
        }
      }
    )
  }

  datehandleChange = date => {
    this.setState(
      {
        date: date,
        time: moment(date).format('DD-MMM-YYYY'),
      },
      () => {
        if (
          this.state.language_id === '' ||
          this.state.language_id === '0' ||
          this.state.classSizesend === '' ||
          this.state.classSizesend === '0' ||
          this.state.time == ''
        ) {
          this.setState({ showBookLessonButton: false })
        } else {
        }
        this.setState({ showBookLessonButton: true })
      }
    )
  }
  componentWillMount() {
    let token = window.sessionStorage.getItem('token')
    if (token == null) {
      // return <Redirect to="/dashboard" />
      // return <Link to="/dashboard" />
      // window.history.push('/dashboard');
      // window.history.forward();
      this.props.history.push('/login')
      // window.history.go(1);
      // this.props.context.history.push('/dashboard');
    } else {
      this.getNotificationCount()
      this.getTutorList()
      setTimeout(this.getCertificateList, 1500)
      this.getproductwithpremiumofuser()
      this.getLanguageList()
      this.getLevelList()
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside)
    this.getLanguageList()
    $(document).ready(function () {
      var opcionesnav = $('.navoption').length
      var clickhamb = 0

      $('#hamburger').click(function () {
        clickhamb = 1
        var header = $('#myTopnav')
        if (header[0].classList.length === 1) {
          header.addClass('responsive')
          $('header').height((opcionesnav + 1) * 70)
          $('.navlist a:not(.icon)').css('display', 'block')
          setTimeout(function () {
            $('.navlist a:not(.icon)').css('transform', 'translateX(0px)')
          }, 50)
        } else {
          $('.navlist a:not(.icon)').css('transform', 'translateX(600px)')
          header.height(70)
          setTimeout(function () {
            header.removeClass('responsive')
            header.height(70)
            $('.navlist a:not(.icon)').css('display', 'none')
          }, 50)
        }
      })

      $(window).on('resize', function () {
        if ($(window).width() > 600 && clickhamb == 1) {
          $('#myTopnav').height(70)
          $('.navlist a:not(.icon)').css('display', 'block')
          setTimeout(function () {
            $('.navlist a:not(.icon)').css('transform', 'translateX(0px)')
          }, 50)
        }
      })
    })
  }

  getNotificationCount() {
    fetch(api_Url + '/get-notifications-count', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + window.sessionStorage.getItem('token'),
      },
    })
      .then(response => response.json())

      .then(response => {
        if (response.status === 200) {
          this.setState(
            {
              notificationCount: response.notification,
              credit: response.credit,
              call: true,
            },
            () => {}
          )
        } else {
        }
      })
  }

  getNotification = e => {
    this.setState({ showNotificatonbox: !this.state.showNotificatonbox })
    fetch(api_Url + '/get-notifications-data', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + window.sessionStorage.getItem('token'),
      },
    })
      .then(response => response.json())

      .then(response => {
        if (response.status === 200) {
          this.setState(
            {
              notificationList: response.notification,
              // showNotificatonbox:!this.state.showNotificatonbox,
              // called:"yes",
            },
            () => {
              this.componentDidMount()
            }
          )
          this.ChangeTime()
        }
      })
  }

  ChangeTime() {
    fetch(api_Url + '/change-time', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + window.sessionStorage.getItem('token'),
      },
    })
      .then(response => response.json())

      .then(response => {
        this.setState({
          notificationCount: response.notification,
          notificationCount: '0',
        })
      })
  }

  handleClickOutside(event) {
    //

    $(document).click(e => {
      //
      $('#notifications').hide()
    })
  }
  getLanguageList() {
    fetch(api_Url + `/product/getproductlist`)
      .then(response => response.json())

      .then(data => {
        this.setState(
          {
            languageList: data.product,
          }
          // ()=>{
          //     ;
          //     this.setState({product_id:this.state.languageList[0].product, SearchStr: this.state.languageList[0].product},()=>{ this.getProductList();})

          //    }
        )
      })
  }
  //get tutor member details
  getTutorList() {
    fetch(api_Url + `/tutor/list/?language_id=${this.state.product_id}`)
      .then(response => response.json())
      .then(data => {
        if (data.status === 200) {
          this.setState({
            tutorList: data.tutor,
          })
        } else {
          this.setState({
            tutorList: [],
          })
        }
        this.setState({ isLoading: false })
      })
  }

  showLanguageName(data) {
    var tutorlanguage = ''
    data.language_id.map(
      product => (tutorlanguage = tutorlanguage + ',' + product.product)
    )
    var tutormain = tutorlanguage.substring(1)
    return <h2>{tutormain}</h2>
  }
  /* open message box */
  openMessageBox = e => {
    this.setState({
      showMessageBox: true,
      message: '',
      tutorId: e,
      language_id: '',
      showPostMessage: '',
      displaytext: '',
      classSizesend: '',
    })
  }
  CloseMessageBox = e => {
    this.setState({ showMessageBox: false })
  }

  /* open book lessson box */
  openBookLessonBox = e => {
    this.setState({
      showBookLesson: true,
      tutorId: e,
      language_id: '',
      showBookLessonMessage: '',
      displaytext: '',
      classSizesend: '',
      date: '',
    })
  }

  CloseBookLessonbox = e => {
    this.setState({ showBookLesson: false })
  }

  /* Send user message*/

  sendMessage = e => {
    fetch(api_Url + '/tutor/requesttutormsgwithauth', {
      method: 'POST',
      body: JSON.stringify({
        message: this.state.message,
        language_id: this.state.language_id,
        classsize: this.state.classSizesend,
        tutor_id: this.state.tutorId,
        type: 'message',
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + window.sessionStorage.getItem('token'),
      },
    })
      .then(response => response.json())
      .then(response => {
        if (response.status == 200) {
          this.setState({
            showPostMessage: 'Message has been sent successsfully',
            displaytext: 'show_block',
          })
          setTimeout(this.CloseMessageBox, 1500)
        } else {
        }
      })
  }

  /*  Send Book a Lesson*/
  /* Send user message*/

  bookAlesson = e => {
    fetch(api_Url + '/tutor/requesttutormsgwithauth', {
      method: 'POST',
      body: JSON.stringify({
        language_id: this.state.language_id,
        classsize: this.state.classSizesend,
        date: this.state.time,
        tutor_id: this.state.tutorId,
        type: 'lesson',
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + window.sessionStorage.getItem('token'),
      },
    })
      .then(response => response.json())
      .then(response => {
        if (response.status == 200) {
          this.setState({
            showBookLessonMessage: 'Your request has been sent successfully',
            displaytext: 'show_block',
          })
          setTimeout(this.CloseBookLessonbox, 1500)
        } else {
        }
      })
  }

  // GETTING PRODUCT LIST FOR NOTIFICATION

  getproductwithpremiumofuser = e => {
    fetch(api_Url + '/product/getproductwithpremiumofuser', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + window.sessionStorage.getItem('token'),
      },
    })
      .then(response => {
        return response.json()
      })
      .then(response => {
        var mainlang = []
        var pdata = response.product
        pdata.map(productData => {
          if (productData) {
            if (productData.result) {
              productData.result.map(resultData => {
                if (resultData) {
                  if (
                    resultData.user_id == window.sessionStorage.getItem('id')
                  ) {
                    if (mainlang.indexOf(productData) > -1) {
                    } else {
                      mainlang.push(productData)
                    }
                  }
                }
              })
            }
          }
        })
        if (mainlang.length > 0) {
          this.setState({
            languageList: mainlang,
          })
        } else {
        }
      })
  }

  // GET  ALL LANGUAGE FOR THE NOTIFICATION REDIRECTION
  getLanguageList = () => {
    //
    fetch(api_Url + `/product/getproductlist`)
      .then(response => response.json())

      .then(data => {
        //
        this.setState({
          AlllanguageList: data.product,
        })
      })
  }

  // get Level List
  getLevelList = e => {
    // ;
    fetch(api_Url + '/language/listlevel')
      .then(response => response.json())
      .then(data => {
        //
        if (data.status == 200) {
          this.setState({
            levelList: data.level,
          })
        } else {
          this.setState({ levelList: [] })
        }
      })
  }

  // FIND PRIMIUM ID OF PARICULAR PRODUCT

  getPremiunPriceidForNotification1 = languageId => {
    var premiumpriceId = []

    var promise = new Promise((resolve, reject) => {
      this.state.languageList.map(resultData => {
        if (resultData) {
          var product = resultData.product

          if (resultData.result[0]) {
            var rr = resultData.result[0]
            if (rr.product_id === languageId) {
              premiumpriceId = rr.premiumprice_id
            }
          }
        }
        resolve(premiumpriceId)
      })
    })
    return promise
  }
  // REDIERCT TO SELECTED PAGE

  redirectToPage = (e, data) => {
    const type = 'notification'
    if (
      data.notificationtype === 'language' ||
      data.notificationtype === 'package'
    ) {
      var notificatinType = data.notificationtype
      var languageId = data.language_id
      const product = this.state.AlllanguageList.find(l => l._id === languageId)
      const productName = product.product
      this.setState({
        language_id: languageId,
        productName: productName,
        notificatinType: notificatinType,
      })
    } else {
      const levelId = data.level_id
      const lessonType = data.lessontype
      const level = this.state.levelList.find(l => l._id === levelId)
      const levelName = level.level
      const chapterId = data.chapter_id
      const lessonId = data.lesson_id
      var notificatinType = data.notificationtype
      var languageId = data.language_id
      const product = this.state.languageList.find(l => l._id === languageId)
      const productName = product.product
      this.setState({
        language_id: languageId,
        lessonType: lessonType,
        productName: productName,
        type: type,
        level_id: levelId,
        levelName: levelName,
        chapter_id: chapterId,
        lesson_id: lessonId,
        notificatinType: notificatinType,
      })
    }
    this.getPremiunPriceidForNotification1(languageId).then(value => {
      var premiumpriceId = value
      if (this.state.notificatinType === 'chapter') {
        this.setState({ premiumpriceId: premiumpriceId })
        // return <Redirect to={`/introduction/${this.state.level_id}/${this.state.levelName}/${premiumpriceId}/${this.state.language_id}/${this.state.productName}/${this.state.chapter_id}/${this.state.type}`}/>
      }
    })
  }

  // hide notification
  hideNotification = () => {
    this.setState({ showNotificatonbox: !this.state.showNotificatonbox })
  }

  render() {
    if (
      this.state.lessonType === 'otherlevel0' ||
      this.state.notificatinType == 'hourchapter'
    ) {
      if (this.state.notificatinType == 'hourchapter') {
        return (
          <Redirect
            to={`/introduction/${this.state.level_id}/${this.state.levelName}/${this.state.premiumprice_id}/${this.state.language_id}/${this.state.productName}/${this.state.chapter_id}/${this.state.type}`}
          />
        )
      } else if (this.state.notificatinType == 'lesson') {
        return (
          <Redirect
            to={`/introduction/${this.state.level_id}/${this.state.levelName}/${this.state.premiumprice_id}/${this.state.language_id}/${this.state.productName}/${this.state.chapter_id}/${this.state.lesson_id}/${this.state.type}`}
          />
        )
      } else if (this.state.notificatinType == 'language') {
        return <Redirect to={`/subscriptions`} />
      }
    } else {
      if (this.state.notificatinType == 'entertainmentchapter') {
        return (
          <Redirect
            to={`/entertainment/${this.state.level_id}/${this.state.levelName}/${this.state.premiumprice_id}/${this.state.language_id}/${this.state.productName}/${this.state.chapter_id}/${this.state.type}`}
          />
        )
      } else if (this.state.notificatinType == 'lesson') {
        return (
          <Redirect
            to={`/entertainment/${this.state.level_id}/${this.state.levelName}/${this.state.premiumprice_id}/${this.state.language_id}/${this.state.productName}/${this.state.chapter_id}/${this.state.lesson_id}/${this.state.type}`}
          />
        )
      } else if (this.state.notificatinType == 'language') {
        return <Redirect to={`/user-subscription-details`} />
      } else if (this.state.notificatinType == 'level') {
        return <Redirect to={`/dashboard`} />
      } else if (this.state.notificatinType == 'package') {
        return <Redirect to={`/user-subscription-details`} />
      }
    }
    return (
      <div>
        {this.state.isLoading && <div class="loader1"></div>}
        <header className="topnav" id="myTopnav">
          <Link to="/">
            <img className="logo" src={logo} />
          </Link>

          <div className="navlist" id="navlist">
            <Link style={{ cursor: 'pointer' }} className="cursor0">
              <img
                style={{
                  height: '38px',
                  position: 'relative',
                  right: '-11px',
                }}
                src={coin__icon}
              />
              <span className="button__badge">{this.state.credit}</span>
            </Link>
            {this.state.showNotificatonbox && (
              <button
                className="topBarBack"
                type="button"
                onClick={this.hideNotification}
              />
            )}

            <Link
              onClick={this.getNotification}
              className="cursor0"
              to="/profilebookatutor"
            >
              <i className="fa fa-bell-o"></i>
              <span className="button__badge">
                {this.state.notificationCount}
              </span>

              {this.state.showNotificatonbox && (
                <div id="notifications">
                  <h3>Notifications</h3>
                  <div
                    className={
                      this.state.notificationList.length > 0
                        ? ' notification-data'
                        : ''
                    }
                  >
                    {this.state.notificationList.length > 0 ? (
                      this.state.notificationList.map(notification => (
                        <div
                          onClick={e => this.redirectToPage(e, notification)}
                          className="main-nf-data"
                        >
                          <img
                            src={
                              api_Url +
                              '/UserProfile/' +
                              notification.user_id.profile_pic
                            }
                          />
                          {notification.role === 'Admin' ? (
                            <p>
                              {''}
                              {notification.notificationdata}
                            </p>
                          ) : (
                            <p>
                              {' '}
                              {notification.user_id.name} {''}
                              {notification.notificationdata}
                            </p>
                          )}
                          <span></span>
                        </div>
                      ))
                    ) : (
                      <div className="notification-none-data">
                        <img src={notificationicon} />
                        <p style={{ color: '#444444' }}>
                          You don't have notification
                        </p>
                      </div>
                    )}
                  </div>
                  {/* <div class="seeAll"><a href="#">See All</a></div> */}
                </div>
              )}
            </Link>
            <Link className="navoption" to="/dashboard">
              My Courses
            </Link>
            <Link className="navoption" to="/profile">
              Profile
            </Link>
            <Link className="navoption" to="/profilebookatutor">
              Book a Tutor<hr className="hr"></hr>
            </Link>
            <Link className="navoption" to="/user-subscription-details">
              Subscription
            </Link>
            <Link className="navoption" to="/login" onClick={this.Logout}>
              Sign Out
            </Link>
            <a href="javascript:void(0);" className="icon" id="hamburger">
              <span></span>
              <span></span>
              <span></span>
            </a>
          </div>
        </header>
        <section className="banner-tutor">
          <div className=""></div>
          <div className="container">
            <div className="row">
              <div className="banner-section-title">
                <h1>Book a Tutor</h1>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-white p-t-60">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 txt-center">
                <p className="tutor-content">
                  Besides Cocoma Academy main courses, our hour challenges, we also
                  offer tutoring. If you want to experience personalized class
                  Names with teachers in order to learn faster or to ask
                  questions, Book a Tutor is the best option for you! Book
                  anytime and choose your class Name size according to your
                  budget. Let's learn Hindi!
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8 col-md-12">
                <form className="tutor-form login100-form validate-form flex-sb flex-w m-t-0">
                  <div className="tutor-input-w m-t-5 select_box-tutor">
                    <p className="">Choose your learning language</p>
                    {/* <select className="input100 .br-w-1">
                                            <option value="Hindi">Hindi</option>
                                            <option value="Panjabi">Panjabi</option>
                                            <option value="Marathi">Marathi</option>
                                        </select>{" "} */}
                    <select
                      className="input100 br-w-1"
                      name="product_id"
                      value={this.state.product_id}
                      onChange={this.handleChange}
                    >
                      <option value={'0'}>-Select language-</option>
                      {this.state.languageList.length > 0
                        ? this.state.languageList.map(language => (
                            <option key={language._id} value={language._id}>
                              {language.product}
                            </option>
                          ))
                        : null}
                    </select>{' '}
                    <span className="focus-input100"></span>
                  </div>
                  <div className="tutor-input-w m-t-5 select_box-tutor">
                    <p className="">Choose your class size</p>
                    <select
                      className="input100 .br-w-1"
                      name="classSize"
                      value={this.state.classSize}
                      onChange={this.handleChange}
                    >
                      <option value="0">-Select class size-</option>
                      <option value="1 to 1">1 to 1</option>
                      <option value="1 to 3">1 to 3</option>
                      <option value="1 to 5">1 to 5</option>
                      <option value="1 to 7">1 to 7</option>
                    </select>
                    <span className="focus-input100"></span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <section className="bx-shadow m-b-20 p-b-100">
          <div className="container">
            {this.state.tutorList &&
              !!this.state.tutorList.length &&
              this.state.tutorList.map(tutor => (
                <div className="row m-t-50 tutor-full-w">
                  <div className="col-lg-2 txt-center">
                    <img
                      className="tutor-userpic"
                      src={api_Url + '/UserProfile/' + tutor.profile_pic}
                    />
                    <div className="tutor-price m-t-10">
                      <h1>{tutor.price}</h1>
                      <h6>
                        US$
                        <br />
                        <span>/ hour</span>
                      </h6>
                    </div>
                  </div>
                  <div className="col-lg-7 m-t-20 padding-0">
                    <div className="tutor-name-bx m-b-20">
                      <div className="col-lg-8">
                        <h1>{tutor.firstname + ' ' + tutor.lastname}</h1>

                        {this.showLanguageName(tutor)}
                      </div>
                      <div className="col-lg-4 txt-right">
                        <ul
                          className={
                            tutor.noofpersonreview < 1
                              ? 'q-ranking0'
                              : 'q-ranking'
                          }
                        >
                          <li>
                            <p>?</p>
                          </li>
                          <li>
                            <p>?</p>
                          </li>
                          <li>
                            <p>?</p>
                          </li>
                          <li>
                            <p>?</p>
                          </li>
                          <li>
                            <p>?</p>
                          </li>
                        </ul>
                        <p>{tutor.review + ' '}review</p>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <p className="txt-justify">{tutor.description}</p>
                    </div>
                  </div>
                  <div className="col-lg-3 m-t-20">
                    <div className="tutor-video-div">
                      <div className="video-bx"></div>
                      <h1
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.openMessageBox(tutor._id)}
                      >
                        Send a Message
                      </h1>
                      <a
                        style={{
                          cursor: 'pointer',
                          color: 'white',
                        }}
                        onClick={() => this.openBookLessonBox(tutor._id)}
                        className="btn-book"
                      >
                        Book a Lesson
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            {/* <div className="row m-t-50 tutor-full-w">
                            <div className="col-lg-2 txt-center">
                                <img className="tutor-userpic" />
                                <div className="tutor-price m-t-10">
                                    <h1>15</h1>
                                    <h6>US$<br/><span>/ hour</span></h6>
                                </div>
                            </div>
                            <div className="col-lg-7 m-t-20 padding-0">
                                <div className="tutor-name-bx m-b-20">
                                    <div className="col-lg-8">
                                        <h1>Jashoda Rathore</h1>
                                        <h2>Speaks ENGLISH, HINDI, PANJABI</h2>
                                    </div>
                                    <div className="col-lg-4 txt-right">
                                        <ul className="q-ranking">
                                            <li><p>?</p></li>
                                            <li><p>?</p></li>
                                            <li><p>?</p></li>
                                            <li><p>?</p></li>
                                            <li><p>?</p></li>
                                        </ul>
                                        <p>85 reviews</p>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <p className="txt-justify">Besides Cocoma Academy main courses, our hour challenges, we also offer tutoring. If you want to experience personalized classNamees with teachers in order to learn faster or to ask questions, Book a Tutor is the best option for you! Book anytime and choose your className size according to your budget. Let's learn Hindi!</p>
                                </div>
                            </div>
                            <div className="col-lg-3 m-t-20">
                                <div className="tutor-video-div">
                                    <div className="video-bx">
                                </div>
                                    <h1>Send a Message</h1>
                                    <a className="btn-book" href="">Book a Lesson</a>
                                </div>
                            </div>
                        </div> */}
            {/* <div className="row m-t-50 tutor-full-w">
                            <div className="col-lg-2 txt-center">
                                <img className="tutor-userpic" />
                                <div className="tutor-price m-t-10">
                                    <h1>11</h1>
                                    <h6>US$<br/><span>/ hour</span></h6>
                                </div>
                            </div>
                            <div className="col-lg-7 m-t-20 padding-0">
                                <div className="tutor-name-bx m-b-20">
                                    <div className="col-lg-8">
                                        <h1>Shivali Kakkad</h1>
                                        <h2>Speaks ENGLISH, HINDI, MARATHI</h2>
                                    </div>
                                    <div className="col-lg-4 txt-right">
                                        <ul className="q-ranking">
                                            <li><p>?</p></li>
                                            <li><p>?</p></li>
                                            <li><p>?</p></li>
                                            <li><p>?</p></li>
                                            <li><p>?</p></li>
                                        </ul>
                                        <p>57 reviews</p>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <p className="txt-justify">Besides Cocoma Academy main courses, our hour challenges, we also offer tutoring. If you want to experience personalized classes with teachers in order to learn faster or to ask questions, Book a Tutor is the best option for you! Book anytime and choose your class size according to your budget. Let's learn Hindi!</p>
                                </div>
                            </div>
                            <div className="col-lg-3 m-t-20">
                                <div className="tutor-video-div">
                                    <div className="video-bx">
                                </div>
                                    <h1>Send a Message</h1>
                                    <a className="btn-book" href="">Book a Lesson</a>
                                </div>
                            </div>
                        </div> */}
          </div>
        </section>
        {this.state.showMessageBox && (
          <div className="static-modal">
            <div>
              <Modal
                show={this.state.showMessageBox}
                onHide={this.CloseMessageBox}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Send a Message </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form horizontal>
                    <FormGroup controlId="formHorizontalEmail">
                      <label>Select Language</label>
                      <select
                        className="input76 br-w-1"
                        name="language_id"
                        value={this.state.language_id}
                        onChange={e => this.handleChangeSend(e, 'message')}
                      >
                        <option value={'0'}>-Select language-</option>
                        {this.state.languageList.length > 0
                          ? this.state.languageList.map(language => (
                              <option key={language._id} value={language._id}>
                                {language.product}
                              </option>
                            ))
                          : null}
                      </select>{' '}
                      <label>Select Class Size</label>
                      <select
                        className="input76 .br-w-1"
                        name="classSizesend"
                        value={this.state.classSizesend}
                        onChange={e => this.handleChangeSend(e, 'message')}
                      >
                        <option value="0">-Select class size-</option>
                        <option value="1 to 1">1 to 1</option>
                        <option value="1 to 3">1 to 3</option>
                        <option value="1 to 5">1 to 5</option>
                        <option value="1 to 7">1 to 7</option>
                      </select>
                      <p>{this.state.usreComment}</p>
                      <div></div>
                      <textarea
                        placeholder="write your message here.."
                        name="message"
                        value={this.state.message}
                        onChange={e => this.handleChangeSend(e, 'message')}
                        style={{
                          overflowX: 'auto',
                          overflowY: 'auto',
                          height: '100px',
                          width: '76%',
                          padding: '5px',
                          borderRadius: '5px',
                        }}
                      ></textarea>
                      <p className={this.state.displaytext + ' text-success'}>
                        {this.state.showPostMessage}
                      </p>
                      {/* <Col sm={9}>
                                  <FormControl
                                    type="text"
                                    placeholder="Email required"
                                    value={this.state.useremail}
                                    onChange={this.handleMainCommentClose}
									name="useremail"
									className= "m-t-13"
                                  />
                                  <p className={this.state.displaytext+" text-danger"}>{this.state.FpErrorMessage}</p>
                                </Col> */}
                    </FormGroup>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    className="reset-pass100-form-btn"
                    onClick={this.CloseMessageBox}
                  >
                    Cancel
                  </button>
                  {this.state.showSendButton === true && (
                    <button
                      type="submit"
                      onClick={this.sendMessage}
                      className="reset-pass100-form-btn"
                    >
                      {' '}
                      Send Message
                    </button>
                  )}
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        )}

        {/* Show Book Lesson Modal */}
        {this.state.showBookLesson && (
          <div className="static-modal">
            <div>
              <Modal
                show={this.state.showBookLesson}
                onHide={this.CloseBookLessonbox}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Book a Lesson </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form horizontal>
                    <FormGroup controlId="formHorizontalEmail">
                      <label>Select Language</label>
                      <select
                        className="input76 br-w-1"
                        name="language_id"
                        value={this.state.language_id}
                        onChange={e => this.handleChangeSend(e, 'lesson')}
                      >
                        <option value="0">-Select language-</option>
                        {this.state.languageList.length > 0
                          ? this.state.languageList.map(language => (
                              <option key={language._id} value={language._id}>
                                {language.product}
                              </option>
                            ))
                          : null}
                      </select>{' '}
                      <label>Select Class Size</label>
                      <select
                        className="input76 .br-w-1"
                        name="classSizesend"
                        value={this.state.classSizesend}
                        onChange={e => this.handleChangeSend(e, 'lesson')}
                      >
                        <option value="0">-Select class size-</option>
                        <option value="1 to 1">1 to 1</option>
                        <option value="1 to 3">1 to 3</option>
                        <option value="1 to 5">1 to 5</option>
                        <option value="1 to 7">1 to 7</option>
                      </select>
                      <br></br>
                      <label>Select Date</label>
                      <br></br>
                      <DatePicker
                        className="input161"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        minDate={new Date()}
                        selected={this.state.date}
                        onChange={this.datehandleChange}
                        placeholderText="-Select Date-"
                      />
                      <div></div>
                      {/* <textarea placeholder ="write your message here.."  name= "message"  value ={this.state.message} onChange ={this.handleChangeSend} style={{overflowX:'auto', overflowY:'auto', height:'100px', width:'76%', padding:'5px', borderRadius:'5px',}}></textarea> */}
                      <p className={this.state.displaytext + ' text-success'}>
                        {this.state.showBookLessonMessage}
                      </p>
                      {/* <Col sm={9}>
                                  <FormControl
                                    type="text"
                                    placeholder="Email required"
                                    value={this.state.useremail}
                                    onChange={this.handleMainCommentClose}
									name="useremail"
									className= "m-t-13"
                                  />
                                  <p className={this.state.displaytext+" text-danger"}>{this.state.FpErrorMessage}</p>
                                </Col> */}
                    </FormGroup>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    className="reset-pass100-form-btn"
                    onClick={this.CloseBookLessonbox}
                  >
                    Cancel
                  </button>
                  {this.state.showBookLessonButton === true && (
                    <button
                      type="submit"
                      onClick={this.bookAlesson}
                      className="reset-pass100-form-btn"
                    >
                      {' '}
                      Book Lesson
                    </button>
                  )}
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default Profilebookatutor
