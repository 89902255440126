import React from 'react'

class TestingAjax extends React.Component {
  constructor() {
    super()
    this.state = {
      isLoading: false,
    }

    // .video-container {
    // 	position: relative;
    // 	padding-bottom: 56.25%; /* 16:9 */
    // 	height: 0;
    // }
    // .video-container iframe {
    // 	position: absolute;
    // 	top: 0;
    // 	left: 0;
    // 	width: 100%;
    // 	height: 100%;
    // }
  }

  testAjax = async () => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
    }

    fetch(
      "http://www.app.connektpos.com/api/WebService/SendMailGetInTouch?Name=abc&email=pradeep.paras@connekt.in&PhoneNo=9584910193&Message='hello",
      requestOptions
    )
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error))
  }

  render() {
    return <button onClick={this.testAjax}>Test</button>
  }
}

export default TestingAjax
