import React from 'react'
import Header from './Header'
import Footer from './Footer'

import '../fonts/fonts/font-awesome-4.7.0/css/font-awesome.min.css'
import '../fonts/work-sans-cufonfonts-webfont/style.css'
import { Link, Redirect } from 'react-router-dom'
import { element } from 'prop-types'
import * as myConstClass from './constants'
const api_Url = myConstClass.api
//const api_Url = 'http://35.244.3.174:4000';
class Prices extends React.Component {
  constructor() {
    super()
    this.state = {
      token: '',
      displaytext: 'hide_block',
      product: [],
      result: [],
      languageList: [],
      product_id: '',
      SearchStr: '',
      isLoading: true,
    }
  }

  handleChange = e => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        SearchStr: e.target.value,
      },
      () => {
        if (this.state.SearchStr == '0') {
          return
        } else {
          this.getProductList()
        }
      }
    )
  }

  componentWillMount() {
    document.body.style.backgroundColor = null
    let token = window.sessionStorage.getItem('token')
    if (token) {
      // return <Redirect to="/dashboard" />
      // return <Link to="/dashboard" />
      // window.history.push('/dashboard');
      // window.history.forward();
      this.props.history.push('/dashboard')
      // window.history.go(1);
      // this.props.context.history.push('/dashboard');
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    this.getLanguageList()
    this.getProductList()
  }

  getProductList() {
    fetch(api_Url + `/product/getproductlist?search=${this.state.SearchStr}`)
      .then(response => response.json())

      .then(data => {
        if (data.status === 200) {
          this.setState({
            product: data.product,
          })
        } else {
          this.setState({
            product: [],
          })
        }
        this.setState({ isLoading: false })
      })
  }

  getLanguageList() {
    fetch(api_Url + `/product/getproductlist`)
      .then(response => response.json())
      .then(data => {
        this.setState(
          {
            languageList: data.product,
          },
          () => {
            if (data.product.length == 0) {
            } else {
              this.setState(
                {
                  product_id: this.state.languageList[0].product,
                  SearchStr: this.state.languageList[0].product,
                },
                () => {
                  this.getProductList()
                }
              )
            } // this.setState({product_id: selected[0].product, SearchStr :selected[0].product},()=>{
            // this.getProductList();
            //  })
          }
        )
      })
  }

  // filterDefaultLanguage(product){
  //     ;
  //      return this.state.languageList.filter(p=>p.product === product)
  // }

  render() {
    return (
      <div>
        {this.state.isLoading && <div className="loader1"></div>}
        <Header />
        <section className="banner-register">
          <div className=""></div>
          <div className="container">
            <div className="row">
              <div className="banner-section-title">
                <h1>Register</h1>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-white">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-12">
                <form className="tutor-form login100-form validate-form flex-sb flex-w m-t-0">
                  <div className="tutor-input-w m-t-5 select_box-tutor">
                    <p className="">Choose your learning language</p>
                    <select
                      className="input100 br-w-1"
                      value={this.state.product_id}
                      name="product_id"
                      onChange={this.handleChange}
                    >
                      <option value={'0'}>-Select language-</option>
                      {this.state.languageList.length > 0
                        ? this.state.languageList.map(language => (
                            <option key={language._id} value={language.product}>
                              {language.product}
                            </option>
                          ))
                        : null}
                    </select>{' '}
                    <span className="focus-input100"></span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="bg-white p-tb-50 bx-shadow m-b-20">
                    <div className="container">
                        <div className="row">
                            {this.state.product && !!this.state.product.length && this.state.product.map(product =>
                                <div className="col-lg-4 col-md-6 txt-center m-b-40" >
                                    <div className="plan-bx">

                                        
                                        <h1>{product.product}</h1>
                                        <p>Plan</p>
                                        <br />
                                        <div>
                                            <h2></h2>
                                            {product.result.map(result =>
                                                <span>
                                                    <hr />
                                                    <br />
                                                    <h3>{result.premiumname}</h3>
                                                    <h4><span>{result.inrprice}</span>/ {result.expired_timename}</h4>
                                                  
                                                </span>
                                            )}
                                            <br />

                                        </div>
                                    </div>
                                 <Link  className="btn-view"  to={`/subscription/${product.product}`}>View Details</Link> 
                                   

                                </div>
                            )}

                            
                        </div>
                    </div>
                </section> */}

        <section className="bg-white p-tb-50 bx-shadow m-b-20">
          <div className="container">
            {this.state.product &&
              !!this.state.product.length &&
              this.state.product.map(product => (
                <div className="row m-t-50">
                  <div className="col-md-12 txt-center m-b-10">
                    <div className="plan-bx1">
                      <ul>
                        <li>
                          <h1>{product.product}</h1>
                          <p>Plan</p>
                        </li>
                        {product.result.map(result => (
                          <span>
                            {result.is_active && (
                              <li>
                                {/* <h2>Free</h2> */}
                                <h3>{result.premiumname}</h3>
                                <h4>
                                  {result.inrprice === 0 ? (
                                    <span
                                      style={{
                                        color: '#ff8800',
                                      }}
                                    >
                                      Free{' '}
                                    </span>
                                  ) : (
                                    <span
                                      style={{
                                        color: '#ff8800',
                                      }}
                                    >
                                      Paid
                                    </span>
                                  )}
                                  /{result.expired_timename}
                                </h4>
                              </li>
                            )}
                          </span>
                        ))}
                      </ul>
                    </div>
                  </div>
                  {/* <a className="new-btn-view" href="">View Details</a> */}
                  <Link
                    className="new-btn-view"
                    to={`/subscription/${product.product}`}
                  >
                    View Details
                  </Link>
                </div>
              ))}
          </div>
        </section>
        <Footer />
      </div>
    )
  }
}

export default Prices
