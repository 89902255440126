import Cookies from 'js-cookie'
import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { Col, Form, FormControl, FormGroup } from 'react-bootstrap'
import { Link, Redirect } from 'react-router-dom'

import logo from '../Images/logo_black.png'
import * as myConstClass from './constants'
const api_Url = myConstClass.api
///const api_Url = 'http://35.244.3.174:4000';

class LoginHaveAccount extends React.Component {
  constructor(props) {
    super(props)
    if (window.performance) {
      if (performance.navigation.type == 1) {
        sessionStorage.removeItem('goHome')
      } else {
      }
    }
    this.state = {
      id: '',
      isRedirect: false,
      email: '',
      password: '',
      rememberMe: '',
      redirect: false,
      show: false,
      displaytext: 'hide_block',
      FpErrorMessage: '',
      message: '',
      emailErrorMessage: '',
      passwordErrormessage: '',
      useremail: '',
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleforgotPasswordChange = e => {
    this.setState({ [e.target.name]: e.target.value, FpErrorMessage: '' })
  }

  componentWillMount() {
    // let token = window.sessionStorage.getItem('token');
    // let isLogout  =window.sessionStorage.getItem('isLogout');
    // let goHome   =window.sessionStorage.getItem('goHome');
    // let isforgot   =window.sessionStorage.getItem('isforgot');
    // if(token) {
    // 	this.props.history.push('/dashboard');
    // }
    // else if(token == null &&  goHome =="Yes"){
    // 	if(isforgot =='yes'){
    // 		window.sessionStorage.removeItem('isforgot')
    // 		this.props.history.push('/login');
    // 	}
    // 	else{
    // 		this.props.history.push('/');
    // 	}
    // }
    // else{
    // 	this.props.history.push('/login');
    // 	let goHome  = "Yes"
    // 	sessionStorage.removeItem('goHome')
    // 	window.sessionStorage.setItem('goHome', goHome);
    // }
  }

  componentDidMount() {
    // document.addEventListener('contextmenu', (e) => {
    // e.preventDefault();
    // });

    let checked = Cookies.get('checked')
    if (checked) {
      let email = Cookies.get('email')
      let password = Cookies.get('password')

      this.setState({
        email: email,
        password: password,
        rememberMe: true,
      })
    }
  }

  // show forgot password modal
  showForgotPassword = () => {
    this.setState({ show: true, FpErrorMessage: '', useremail: '' })
  }

  handleForgotPassowordClose = () => {
    this.setState({ show: false })
  }
  handleSubmit = e => {
    e.preventDefault()
    if (this.state.rememberMe) {
      Cookies.remove('email')
      Cookies.remove('password')
      Cookies.set('email', this.state.email, { expires: 7 })
      Cookies.set('password', this.state.password, { expires: 7 })
      Cookies.set('checked', true, { expires: 7 })
      //this.setState({rememberMe:true})
    }

    if (this.state.email === '' || this.state.email == undefined) {
      this.setState({ displaytext: 'show_block' })
      this.setState({ emailErrorMessage: 'Email is Required' })
      return
    } else {
      this.setState({ displaytext: 'hide_block' })
      this.setState({
        message: '',
        emailErrorMessage: '',
        passwordErrormessage: '',
      })
    }
    var emailValid = this.state.email.match(
      /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
    )
    if (emailValid) {
      if (this.state.password === '' || this.state.password === undefined) {
        this.setState({ displaytext: 'show_block' })
        this.setState({ passwordErrormessage: 'Password is Required' })
        return
      } else {
        this.setState({ displaytext: 'hide_block' })
        this.setState({
          message: '',
          emailErrorMessage: '',
          passwordErrormessage: '',
        })
      }
      var data = {
        email: this.state.email,
        password: this.state.password,
      }
      fetch(api_Url + '/signIn', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          return response.json()
        })
        .then(response => {
          if (response.status === 200) {
            let token = response.token
            window.sessionStorage.setItem('token', token)
            window.sessionStorage.setItem('id', response.user._id)

            //	window.sessionStorage.setItem('id', '1');

            this.setState({ isRedirect: true })
            // swal({
            // 	text: "You have login successfully.",
            // 	icon: "success",
            // 	button : {
            // 		text: "Ok",
            // 		closeModal: true
            // 	}
            // }).then(OK =>{
            // 	this.setState({isRedirect: true})
            // })
          } else if (response.status === 422) {
            this.setState({
              passwordErrormessage: response.error,
            })
            // swal({

            // 	text: "email and password is not correct.",
            // 	icon: "warning",
            // 	dangerMode: true,
            // })
          }
        })
    } else {
      this.setState({ displaytext: 'show_block' })
      this.setState({ emailErrorMessage: 'Email is Not Valid' })
      return
    }
  }

  rememberMe = e => {
    if (e.target.checked) {
      Cookies.remove('email')
      Cookies.remove('password')
      Cookies.set('email', this.state.email, { expires: 7 })
      Cookies.set('password', this.state.password, { expires: 7 })
      Cookies.set('checked', true, { expires: 7 })
      this.setState({ rememberMe: true })
    } else {
      Cookies.remove('email')
      Cookies.remove('password')
      Cookies.remove('checked')
      this.setState({ rememberMe: false })
    }
  }

  // Send Reset Password Link
  sendResetPasswordLink = e => {
    e.preventDefault()
    if (this.state.useremail === '') {
      this.setState({
        FpErrorMessage: 'Email is required',
        displaytext: 'show_block',
      })
      return
    }
    var emailValid = this.state.useremail.match(
      /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
    )
    if (emailValid) {
      var data = {
        email: this.state.useremail,
      }
      fetch(api_Url + '/forgot-password', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          return response.json()
        })
        .then(response => {
          if (response.status === 200) {
            this.setState({
              FpErrorMessage: response.message,
              displaytext: 'show_block',
            })
            setTimeout(this.handleForgotPassowordClose, 5000)
          } else if (response.status === 422) {
            this.setState({ FpErrorMessage: response.error })
          }
        })
    } else {
      this.setState({
        FpErrorMessage: 'Email is not valid',
        displaytext: 'show_block',
      })
    }
  }

  render() {
    if (this.state.isRedirect) {
      return <Redirect to="/dashboard" />
    }
    return (
      <div>
        <div className="limiter">
          <div className="login-bg">
            <div className="login-overlay"></div>
            <div className="wrap-login100 p-l-60 p-r-50 p-t-40 p-b-40 login-blur">
              <img src={logo} alt="logo"></img>
              <form
                onSubmit={this.handleSubmit}
                className="login100-form validate-form flex-sb flex-w"
              >
                <div>
                  <input
                    autoFocus
                    className="input100"
                    type="text"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    placeholder="E-mail"
                  />
                  <span className="focus-input100"></span>
                  <p className={this.state.displaytext + ' text-danger'}>
                    {this.state.emailErrorMessage}
                  </p>
                </div>
                <div className="">
                  <input
                    className="input100"
                    type="password"
                    name="password"
                    value={this.state.password}
                    onChange={this.handleChange}
                    placeholder="Password"
                  />
                  <span className="focus-input100"></span>
                  <p className={this.state.displaytext + ' text-danger'}>
                    {this.state.passwordErrormessage}
                  </p>
                </div>
                <div className="flex-sb-m w-full p-b-48 m-t-25">
                  <div className="contact100-form-checkbox">
                    <input
                      className="input-checkbox100"
                      id="ckb1"
                      type="checkbox"
                      name="remember"
                      checked={this.state.rememberMe}
                      onClick={this.rememberMe}
                    />
                    <label className="label-checkbox100" htmlFor="ckb1">
                      Remember me
                    </label>
                  </div>

                  <div className="container-login100-form-btn">
                    <button className="login100-form-btn">Sign In</button>
                  </div>
                  {/* <Link
                                        to="/register"
                                        style={{ textDecorationLine: "none" }}
                                    >
                                        <div className="container-login100-form-btn">
                                            <button className="login100-form-btn">
                                                Sign Up
                                            </button>
                                        </div>
                                    </Link> */}
                </div>
                {/* <div>
                                    <Link
                                        className="txt3"
                                        onClick={this.showForgotPassword}
                                    >
                                        Forgot Password?
                                    </Link>
                                </div> */}
              </form>
            </div>
          </div>
        </div>
        {this.state.show && (
          <div /*className="static-modal"*/>
            <div>
              <Modal
                dialogClassName="my-modal"
                show={this.state.show}
                onHide={this.handleForgotPassowordClose}
              >
                <Modal.Header closeButton>
                  <Modal.Title className="modal-title">
                    Forgot Password
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form horizontal>
                    <FormGroup controlId="formHorizontalEmail">
                      {/* <Col  sm={4}>
                                  Country Name
                                </Col> */}
                      <Col sm={10}>
                        <FormControl
                          type="text"
                          placeholder="Email required"
                          value={this.state.useremail}
                          onChange={this.handleforgotPasswordChange}
                          name="useremail"
                          className="m-t-13"
                        />
                        <p className={this.state.displaytext + ' text-danger'}>
                          {this.state.FpErrorMessage}
                        </p>
                      </Col>
                    </FormGroup>
                  </Form>
                </Modal.Body>
                <Modal.Footer className="modal-footer">
                  <button
                    className="reset-pass100-form-btn"
                    onClick={this.handleForgotPassowordClose}
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    onClick={this.sendResetPasswordLink}
                    className="reset-pass100-form-btn"
                  >
                    {' '}
                    RESET PASSWORD
                  </button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default LoginHaveAccount
