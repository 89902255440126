import '../fonts/fonts/font-awesome-4.7.0/css/font-awesome.min.css'
import '../fonts/work-sans-cufonfonts-webfont/style.css'

import React from 'react'

import Footer from './Footer'
import Header from './Header'

class Privacypolicy extends React.Component {
  componentWillMount() {
    document.body.style.backgroundColor = null
    let token = window.sessionStorage.getItem('token')
    if (token) {
      // return <Redirect to="/dashboard" />
      // return <Link to="/dashboard" />
      // window.history.push('/dashboard');
      // window.history.forward();
      this.props.history.push('/dashboard')
      // window.history.go(1);
      // this.props.context.history.push('/dashboard');
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <div>
        <Header />
        <section className="banner-privacy">
          <div className=""></div>
          <div className="container">
            <div className="row">
              <div className="banner-section-title">
                <h1>Privacy Policy</h1>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-white p-t-60 p-b-100 bx-shadow m-b-20">
          <div className="container">
            <div className="row">
              <div className="col-md-12 privacy-content">
                <h1>EFFECTIVE DATE: February 24, 2018</h1>
                <p className="m-b-20">
                  This page informs you of our policies regarding the
                  collection, use, and disclosure of personal data when you use
                  our Service and the choices you have associated with that
                  data. Cocoma Academy.com ("us", "we", or "our") operates the
                  www.langistan.com website (the "Service"). We use your data to
                  provide and improve the Service. By using the Service, you
                  agree to the collection and use of information in accordance
                  with this policy. Unless otherwise defined in this Privacy
                  Policy, terms used in this Privacy Policy have the same
                  meanings as in our Terms and Conditions, accessible from
                  www.langistan.com/ termsandconditions.
                </p>
                <br />
                <h1>1. INFORMATION COLLECTED</h1>
                <p className="m-b-20">
                  We collect several different types of information for various
                  purposes to provide and improve our Service to you. Types of
                  Data Collected:
                </p>
                <h2>Personal Data</h2>
                <p className="m-b-20">
                  While using our Service, we may ask you to provide us with
                  certain personally identifiable information that can be used
                  to contact or identify you ("Personal Data"). Personally,
                  identifiable information may include, but is not limited to
                  email address, first name and last name.
                </p>
                <br />
                <h2>Usage Data </h2>
                <p className="m-b-20">
                  We may also collect information about how the Service is
                  accessed and used ("Usage Data"). This Usage Data may include
                  information such as your computer's Internet Protocol address
                  (e.g. IP address), browser type, browser version, the pages of
                  our Service that you visit, the time and date of your visit,
                  the time spent on those pages, unique device identifiers and
                  other diagnostic data.
                </p>
                <br />
                <h2>Tracking & Cookies Data</h2>
                <p className="m-b-20">
                  We use cookies and similar tracking technologies to track the
                  activity on our Service and hold certain information. Cookies
                  are files with small amount of data which may include an
                  anonymous unique identifier. Cookies are sent to your browser
                  from a website and stored on your device. Tracking
                  technologies also used are beacons, tags, and scripts to
                  collect and track information and to improve and analyze our
                  Service. You can instruct your browser to refuse all cookies
                  or to indicate when a cookie is being sent. However, if you do
                  not accept cookies, you may not be able to use some portions
                  of our Service. Examples of Cookies we use:
                  <br />
                  • Session Cookies, used to operate our Service; <br />
                  • Preference Cookies, used to remember your preferences and
                  various settings; <br />• Security Cookies, used for security
                  purposes.
                </p>
                <br />
                <h1>2. USE OF DATA</h1>
                <p className="m-b-20">
                  Cocoma Academy.com uses the collected data for various purposes to:
                  <br />
                  • Provide and maintain the Service; <br />• Notify you about
                  changes to our Service; <br />
                  • Allow you to participate in interactive features of our
                  Service when you choose to do so; <br />
                  • Provide customer care and support; <br />
                  • Provide analysis or valuable information so that we can
                  improve the Service; <br />
                  • Monitor the usage of the Service; <br />• Detect, prevent
                  and address technical issues.
                </p>
                <br />
                <h1>3. TRANSFER OF DATA</h1>
                <p className="m-b-20">
                  Your information, including Personal Data, may be transferred
                  to India and maintained on computers located outside of your
                  state, province, country or other governmental jurisdiction
                  where the data protection laws may differ than those from your
                  jurisdiction. If you are located outside India and choose to
                  provide information to us, please note that we transfer the
                  data, including Personal Data, to India and process it there.
                  Your consent to this Privacy Policy followed by your
                  submission of such information represents your agreement to
                  that transfer. Cocoma Academy.com will take all steps reasonably
                  necessary to ensure that your data is treated securely and in
                  accordance with this Privacy Policy and no transfer of your
                  Personal Data will take place to an organization or a country
                  unless there are adequate controls in place including the
                  security of your data and other personal information.
                </p>
                <br />
                <h1>4. DISCLOSURE OF DATA</h1>
                <p className="m-b-20">
                  Cocoma Academy.com may disclose your Personal Data in the good
                  faith belief that such action is necessary to:
                  <br />
                  • Comply with a legal obligation; <br />
                  • Protect and defend the rights or property of Cocoma Academy.com;{' '}
                  <br />
                  • Prevent or investigate possible wrongdoing in connection
                  with the Service; <br />
                  • Protect the personal safety of users of the Service or the
                  public; <br />• Protect against legal liability.
                </p>
                <br />
                <h1>5. SECURITY OF DATA</h1>
                <p className="m-b-20">
                  The security of your data is important to us, but remember
                  that no method of transmission over the Internet, or method of
                  electronic storage is 100% secure. While we strive to use
                  commercially acceptable means to protect your Personal Data,
                  we cannot guarantee its absolute security.
                </p>
                <br />
                <h1>6. SERVICE PROVIDERS</h1>
                <p className="m-b-20">
                  We may employ third party companies and individuals to
                  facilitate our Service ("Service Providers"), to provide the
                  Service on our behalf, to perform Service-related services or
                  to assist us in analyzing how our Service is used. These third
                  parties have access to your Personal Data only to perform
                  these tasks on our behalf and are obligated not to disclose or
                  use it for any other purpose.
                </p>
                <br />
                <h1>7. ANALYTICS</h1>
                <p className="m-b-20">
                  We may use third-party Service Providers to monitor and
                  analyze the use of our Service.
                </p>
                <br />
                <h1>8. GOOGLE ANALYTICS</h1>
                <p className="m-b-20">
                  Google Analytics is a web analytics service offered by Google
                  that tracks and reports website traffic. Google uses the data
                  collected to track and monitor the use of our Service. This
                  data is shared with other Google services. Google may use the
                  collected data to contextualize and personalize the ads of its
                  own advertising network. You can opt-out of having made your
                  activity on the Service available to Google Analytics by
                  installing the Google Analytics opt-out browser add-on. The
                  add-on prevents the Google Analytics JavaScript (ga.js,
                  analytics.js, and dc.js) from sharing information with Google
                  Analytics about visits activity. For more information on the
                  privacy practices of Google, please visit the Google Privacy &
                  Terms web page: https://policies.google.com/privacy?hl=en
                </p>
                <br />
                <h1>9. LINKS TO OTHER SITES</h1>
                <p className="m-b-20">
                  Our Service may contain links to other sites that are not
                  operated by us. If you click on a third party link, you will
                  be directed to that third party's site. We strongly advise you
                  to review the Privacy Policy of every site you visit. We have
                  no control over and assume no responsibility for the content,
                  privacy policies or practices of any third party sites or
                  services.
                </p>
                <br />
                <h1>10. CHILDREN'S PRIVACY</h1>
                <p className="m-b-20">
                  Our Service does not address anyone under the age of 18
                  ("Children"). We do not knowingly collect personally
                  identifiable information from anyone under the age of 18. If
                  you are a parent or guardian and you are aware that your
                  Children has provided us with Personal Data, please contact
                  us. If we become aware that we have collected Personal Data
                  from children without verification of parental consent, we
                  take steps to remove that information from our servers.
                </p>
                <br />
                <h1>11. CHANGES TO THIS PRIVACY POLICY</h1>
                <p className="m-b-20">
                  We may update our Privacy Policy from time to time. We will
                  notify you of any changes by posting the new Privacy Policy on
                  this page. We will let you know via email and/or a prominent
                  notice on our Service, prior to the change becoming effective
                  and update the "effective date" at the top of this Privacy
                  Policy. You are advised to review this Privacy Policy
                  periodically for any changes. Changes to this Privacy Policy
                  are effective when they are posted on this page.
                </p>
                <br />
                <h1>12. CONTACT US</h1>
                <p className="m-b-20">
                  If you have any questions about this Privacy Policy, please
                  contact us on the page: langistan.com/contact
                </p>
                <br />
                <h3 className="txt-right">Last revised on 1th March, 2019</h3>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    )
  }
}

export default Privacypolicy
