import '../fonts/fonts/font-awesome-4.7.0/css/font-awesome.min.css'
import '../fonts/work-sans-cufonfonts-webfont/style.css'

import React from 'react'

import * as myConstClass from './constants'
import Footer from './Footer'
import Header from './Header'
const api_Url = myConstClass.api
//const api_Url = 'http://35.244.3.174:4000';
class Aboutus extends React.Component {
  constructor() {
    super()
    this.state = {
      teamMembersList: [],
      isLoading: true,
    }
  }
  componentWillMount() {
    document.body.style.backgroundColor = null
    let token = window.sessionStorage.getItem('token')
    if (token) {
      // return <Redirect to="/dashboard" />
      // return <Link to="/dashboard" />
      // window.history.push('/dashboard');
      // window.history.forward();
      this.props.history.push('/dashboard')
      // window.history.go(1);
      // this.props.context.history.push('/dashboard');
    } else {
      this.getTeamMemberprofile()
    }
  }

  //get Team member details
  getTeamMemberprofile() {
    fetch(api_Url + '/teammember/list')
      .then(response => response.json())
      .then(data => {
        if (data.status === 200) {
          this.setState({
            teamMembersList: data.team,
          })
        } else {
          this.setState({
            teamMembersList: [],
          })
        }
        this.setState({ isLoading: false })
      })
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <div>
        {this.state.isLoading && <div class="loader1"></div>}
        <Header />
        <section className="about-banner-section">
          <div className=""></div>
          <div className="container">
            <div className="row">
              <div className="banner-section-title">
                <h1>The Cocoma Academy Story</h1>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-white p-tb-50">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 txt-center">
                <p className="about-p">
                  Hindi & India go together like two peas in a pod, as does the
                  love and passion that we have to share the wonderful language
                  of India with you. At Cocoma Academy, we believe there is a better
                  way to learn languages. Not robotic and book based but more
                  vibrant, alive and natural. Our mission is to help students
                  from all around the world achieve success in speaking Hindi,
                  the 4th most spoken language. We focus on developing more
                  intuitive learning content which is beyond grammar &
                  structures. Learning with people’s life and stories connecting
                  you with the experience of India.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-gray p-tb-50">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <h5 className="about-title">2012. THE SEED</h5>
                <p className="about-content">
                  Cocoma Academy was first idealized by Anil Mahato in 2012. The
                  concept started on YouTube in Learn Hindi with Anil Mahato.
                  Originaly, the channel was created to host videos which were
                  produced to help a friend volunteering in Rajasthan, Anna
                  Marie Miller from New Zealand. The channel received a lot of
                  attention and requests on various topics, starting a community
                  of Hindi learners.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-white p-tb-50">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <h5 className="about-title">2013. FACEBOOK GROUP</h5>
                <p className="about-content">
                  Gradually greater communication between teacher and students
                  was required. The most accessible solution was to create a
                  Facebook group, including teachers, native speakers and
                  learners. After successfully running a 14000-members group we
                  faced a new problem: new members were asking questions that
                  have been asked and answered several times. It was time for a
                  more flexible approach.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-gray p-tb-50">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <h5 className="about-title">2014. HINDI LEARNING BLOG</h5>
                <p className="about-content">
                  We needed somehow to present Hindi educational content in a
                  more organized and visual way. So we integrated a blog –
                  anilmahato.com – along with the Facebook group. In the blog,
                  over 200 articles and videos on learning Hindi and other
                  cultural videos were published. However, blog technological
                  limitations prevented us to reach the students as effectively
                  as we wanted.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-white p-tb-50">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <h5 className="about-title">2015. HINDICHAMP</h5>
                <p className="about-content">
                  It was necessary to improve again, both in technology and
                  presentation. At that point the website project was
                  established under the name Hindichamp, a path to make you a
                  Hindi champion. However lack of fund, skills, and exit of team
                  members ensured the work was not launched to beta testing.
                  Hindichamp stopped, but the languages project not only
                  continued but also increased.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-gray p-tb-50">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <h5 className="about-title">2016. LANGISTAN</h5>
                <p className="about-content">
                  Cocoma Academy is formed by two words – Languages & Stan (ـستان
                  from Persian and later स्थान in Hindi – “place of” or
                  “country”), mixed to create the place of languages, whose aim
                  is to make all Indic languages accessible. With the support of
                  wonderful friends, volunteers, team and our beautiful
                  subscribers, we were ready to launch Cocoma Academy in 2018 with
                  our first language – Hindi.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-dark-color">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="section-dark">
                  <h4>OUR TEAM</h4>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-white p-tb-50 bx-shadow m-b-20 our-team">
          <div className="container">
            <div className="row">
              {this.state.teamMembersList &&
                !!this.state.teamMembersList &&
                this.state.teamMembersList.map(team => (
                  <div className="col-lg-3 col-md-4 txt-center m-b-40 our-team-tt">
                    <span className="new-tooltip">
                      <img
                        className="team-userpic "
                        src={api_Url + '/UserProfile/' + team.profile_pic}
                      />
                      <span class="tooltiptext">
                        <p>{team.description}</p>
                      </span>
                    </span>
                    {/* <h5 >{team.firstname}</h5> */}
                    <h5 class="new-tooltip">
                      {team.firstname}
                      <span class="tooltiptext">
                        <p>{team.description}</p>
                      </span>
                    </h5>
                    <p class="new-tooltip">
                      {team.title}
                      <span class="tooltiptext">{team.description}</span>
                    </p>

                    {/* <div class="new-tooltip">Hover over me
                                      <span class="tooltiptext">fgfgfgfds</span>
                                      </div> */}
                  </div>
                ))}
              {/* <div className="col-lg-3 col-md-4 txt-center our-team-tt">
                                    <img className="team-userpic" />
                                    <h5>HIMANSU NEGI</h5>
                                    <p>Content developer</p>
                                </div>
                                <div className="col-lg-3 col-md-4 txt-center our-team-tt">
                                    <img className="team-userpic" />
                                    <h5>MARCELA NOGUEIRA</h5>
                                    <p>Designer</p>
                                </div>
                                <div className="col-lg-3 col-md-4 txt-center our-team-tt">
                                    <img className="team-userpic" />
                                    <h5>PAMELA T.</h5>
                                    <p>Proofreading</p>
                                </div>
                                <div className="col-lg-3 col-md-4 txt-center m-b-40 our-team-tt">
                                    <img className="team-userpic" />
                                    <h5>JASHODA RATHORE</h5>
                                    <p>Teacher</p>
                                </div>
                                <div className="col-lg-3 col-md-4 txt-center our-team-tt">
                                    <img className="team-userpic" />
                                    <h5>SHIVALI KAKKAD</h5>
                                    <p>Teacher</p>
                                </div>
                                <div className="col-lg-3 col-md-4 txt-center our-team-tt">
                                    <img className="team-userpic" />
                                    <h5>HARDIK RATHORE</h5>
                                    <p>Web Developer</p>
                                </div> */}
            </div>
          </div>
        </section>
        <Footer />
      </div>
    )
  }
}

export default Aboutus
