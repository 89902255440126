import API from 'src/util/api'
import useSWR from 'swr'

import { useToken } from './useToken'

const fetcher = url => API.get(url)

export function useUser() {
  const { token } = useToken()
  const { data, error } = useSWR(token ? 'me' : null, fetcher, {
    revalidateOnFocus: false,
    revalidateIfStale: false,
  })

  return {
    user: data,
    isLoading: !error && !data,
    error,
  }
}
